import styles from "./DetailsModal.module.scss";

type TDetailsModalBodyProps = {
  newsletterBody: string;
};

const DetailsModalBody = ({ newsletterBody }: TDetailsModalBodyProps) => {
  return (
    <div className={styles.detailsBody}>
      <h2>Your newsletter will be like this:</h2>
      <div dangerouslySetInnerHTML={{ __html: newsletterBody }} />
    </div>
  );
};

export default DetailsModalBody;
