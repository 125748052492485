import React from "react";
import { TIconProps } from "../../@types/icon";

const FollowIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3333 37.5H133.333V91.6667H145.833V25H20.8333V125C20.8333 129.42 22.5893 133.659 25.7149 136.785C28.8405 139.911 33.0797 141.667 37.5 141.667H95.8333V129.167H37.5C36.3949 129.167 35.3351 128.728 34.5537 127.946C33.7723 127.165 33.3333 126.105 33.3333 125V37.5ZM120.833 54.1667H45.8333V66.6667H120.833V54.1667ZM79.1666 79.1667H45.8333V91.6667H79.1666V79.1667ZM108.333 91.6667H100V100H108.333V91.6667ZM100 79.1667H87.5V112.5H120.833V79.1667H108.333H100ZM79.1666 100H45.8333V112.5H79.1666V100ZM133.333 110.417H145.833V129.167H164.583V141.667H145.833V160.417H133.333V141.667H114.583V129.167H133.333V110.417Z"
        fill={fill}
      />
    </svg>
  );
};

export default FollowIcon;
