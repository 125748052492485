import React, {
  useState,
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
} from "react";
import styles from "./ContactUs.module.scss";
import InputField from "../../../components/InputField/InputField";
import Uploadicon from "../../../components/icons/admin/Uploadicon";
import AdminButton from "../../../components/AdminButton/AdminButton";
import { AppDispatch, RootState } from "../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import {
  contactUsList,
  createContactUs,
  updateContactUs,
} from "../../../app/api";
import { REACT_APP_AMAZON_S3_URL } from "../../../constants/constant";
import Loading from "../../../components/Loading/Loading";

type ContactFormData = {
  enAddress: string;
  arAddress: string;
  phone: string;
  email: string;
};

type SocialMediaAccount = {
  id?: any;
  name_en: string;
  name_ar: string;
  link: string;
  icon: File | null;
};

const AdminContactUs = () => {
  const dispatch: AppDispatch = useDispatch();
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [isValidate, setIsValidate] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [socialMediaAccounts, setSocialMediaAccounts] = useState<
    SocialMediaAccount[]
  >([]);
  const [contactFormData, setContactFormData] = useState<ContactFormData>({
    enAddress: "",
    arAddress: "",
    phone: "",
    email: "",
  });

  const { isLoading } = useSelector((state: RootState) => state.contactUs);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setContactFormData({ ...contactFormData, [id]: value });
  };

  const handleSocialMediaChange = (
    index: number,
    field: keyof SocialMediaAccount,
    value: string | File | null
  ) => {
    setSocialMediaAccounts(
      socialMediaAccounts.map((account, accountIndex) => {
        if (index === accountIndex) {
          return { ...account, [field]: value };
        } else return account;
      })
    );
  };

  const handleAddSocialMedia = () => {
    setSocialMediaAccounts([
      ...socialMediaAccounts,
      { name_en: "", name_ar: "", link: "", icon: null },
    ]);
  };

  const handleRemoveSocialMedia = (index: number) => {
    const updatedAccounts = [...socialMediaAccounts];
    updatedAccounts.splice(index, 1);
    setSocialMediaAccounts(updatedAccounts);
  };

  const handleUpload = (index: number) => {
    inputRefs.current[index].click();
  };

  const handleCreateClick = async () => {
    const formData: any = new FormData();
    formData.append("address_en", contactFormData.enAddress);
    formData.append("address_ar", contactFormData.arAddress);
    formData.append("phoneNumber", contactFormData.phone);
    formData.append("email", contactFormData.email);

    if (socialMediaAccounts.length) {
      socialMediaAccounts.forEach((account, index) => {
        formData.append(
          `socialMediaAccounts[${index}][name_en]`,
          account.name_en
        );
        formData.append(
          `socialMediaAccounts[${index}][name_ar]`,
          account.name_ar
        );
        formData.append(`socialMediaAccounts[${index}][link]`, account.link);
        formData.append(`socialMediaAccounts[${index}][icon]`, account.icon);
      });
    }

    await dispatch(createContactUs(formData)).then((res: any) => {
      if (res.payload.status === 201) {
        setSocialMediaAccounts([]);
        setContactFormData({
          enAddress: "",
          arAddress: "",
          phone: "",
          email: "",
        });
      }
    });
  };

  const handleEditClick = async () => {
    const formData: any = new FormData();
    formData.append("address_en", contactFormData.enAddress);
    formData.append("address_ar", contactFormData.arAddress);
    formData.append("phoneNumber", contactFormData.phone);
    formData.append("email", contactFormData.email);

    if (socialMediaAccounts.length) {
      socialMediaAccounts.forEach((account, index) => {
        if (account.id) {
          formData.append(`socialMediaAccounts[${index}][id]`, account.id);
        }
        formData.append(
          `socialMediaAccounts[${index}][name_en]`,
          account.name_en
        );
        formData.append(
          `socialMediaAccounts[${index}][name_ar]`,
          account.name_ar
        );
        formData.append(`socialMediaAccounts[${index}][link]`, account.link);
        formData.append(`socialMediaAccounts[${index}][icon]`, account.icon);
      });
    }

    await dispatch(updateContactUs(formData)).then((res: any) => {
      console.log("res.payload", res.payload);
    });
  };

  const handleImageRemoveLick = (item: any) => {
    const foundAccountIndex = socialMediaAccounts.findIndex((i) => i === item);

    if (foundAccountIndex !== -1) {
      const updatedAccounts = [...socialMediaAccounts];
      const currentAccount = { ...updatedAccounts[foundAccountIndex] };

      if (typeof currentAccount.icon === "string") {
        currentAccount.icon = null;
      } else {
        currentAccount.icon = null;
      }

      updatedAccounts[foundAccountIndex] = currentAccount;
      setSocialMediaAccounts(updatedAccounts);
    }
  };

  const contactUs = useCallback(async () => {
    try {
      await dispatch(contactUsList()).then((res: any) => {
        const data = res.payload.data.data;
        if (res.payload.status === 200) {
          setIsEdit(true);
          setContactFormData({
            enAddress: data.address_en,
            arAddress: data.address_ar,
            phone: data.phoneNumber,
            email: data.email,
          });
          setSocialMediaAccounts(data.socialMediaAccounts);
        }
      });
    } catch (error: unknown) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    contactUs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactUs]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, socialMediaAccounts.length);

    if (inputRefs.current.length > 0) {
      inputRefs.current[inputRefs.current.length - 1].focus();
    }
  }, [socialMediaAccounts]);

  useEffect(() => {
    const isFormEmpty = Object.values(contactFormData).some((value) => !value);
    let isSocialMediaFormEmpty = false;

    if (socialMediaAccounts.length) {
      const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

      isSocialMediaFormEmpty = socialMediaAccounts.some((item) => {
        const { link } = item;

        const isValidLink = link && urlRegex.test(link);

        return Object.values(item).some((value) => !value) || !isValidLink;
      });
    }

    setIsValidate(isFormEmpty || isSocialMediaFormEmpty);
  }, [contactFormData, socialMediaAccounts]);

  return (
    <div className={`container ${styles.contactUsSection}`}>
      <h3 className={styles.title}>Contact Us</h3>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.contactUsContent}>
          <div className={styles.mainFields}>
            <div className={styles.inputField}>
              <InputField
                id="enAddress"
                value={contactFormData.enAddress}
                onChange={handleInputChange}
                type="string"
                label="Address (English)"
                required
                placeholder="Enter address"
                forAdmin
              />
            </div>
            <div className={styles.inputField}>
              <InputField
                id="arAddress"
                value={contactFormData.arAddress}
                onChange={handleInputChange}
                type="string"
                label="Address (Arabic)"
                required
                placeholder="Enter address"
                forAdmin
              />
            </div>
            <div className={styles.inputField}>
              <InputField
                id="phone"
                value={contactFormData.phone}
                onChange={handleInputChange}
                type="number"
                label="Phone"
                required
                placeholder="Enter phone"
                forAdmin
              />
            </div>
            <div className={styles.inputField}>
              <InputField
                id="email"
                value={contactFormData.email}
                onChange={handleInputChange}
                type="string"
                label="Email"
                required
                placeholder="Enter email"
                forAdmin
              />
            </div>
          </div>
          <div className={styles.addFields}>
            <div className={styles.addBtn} onClick={handleAddSocialMedia}>
              +
            </div>
            <span className={styles.hoverText}>Add social media account</span>
          </div>
          <div className={styles.additionalFields}>
            {socialMediaAccounts.map((account, index) => (
              <div key={index} className={styles.inputFieldContent}>
                <div
                  className={styles.removeBtn}
                  onClick={() => handleRemoveSocialMedia(index)}
                >
                  x
                </div>
                <div className={styles.inputField}>
                  <div>
                    <InputField
                      id={`socialEnMediaName${index}`}
                      value={account.name_en}
                      onChange={(e) =>
                        handleSocialMediaChange(
                          index,
                          "name_en",
                          e.target.value
                        )
                      }
                      type="text"
                      label="Social media name (English)"
                      required
                      placeholder="Enter social media account name"
                      forAdmin
                    />
                  </div>
                  <div>
                    <InputField
                      id={`sociaArMediaName${index}`}
                      value={account.name_ar}
                      onChange={(e) =>
                        handleSocialMediaChange(
                          index,
                          "name_ar",
                          e.target.value
                        )
                      }
                      type="text"
                      label="Social media name (Arabic)"
                      required
                      placeholder="Enter social media account name"
                      forAdmin
                    />
                  </div>
                  <div>
                    <InputField
                      id={`socialMediaLink${index}`}
                      value={account.link}
                      onChange={(e) =>
                        handleSocialMediaChange(index, "link", e.target.value)
                      }
                      type="text"
                      label="Social media link"
                      required
                      placeholder="Enter social media account link"
                      forAdmin
                    />
                  </div>
                  <div className={styles.upload}>
                    <div
                      className={styles.uploadContent}
                      onClick={() =>
                        typeof account.icon !== "string"
                          ? handleUpload(index)
                          : ""
                      }
                    >
                      {typeof account.icon !== "string" ? (
                        <div className={styles.uploadIcon}>
                          <Uploadicon
                            width="30px"
                            height="30px"
                            fill="#333333"
                          />
                          <p>Upload image</p>
                        </div>
                      ) : null}
                      <div className={styles.socialImage}>
                        {account.icon && (
                          <div className={styles.image}>
                            {typeof account.icon === "string" ? (
                              <div
                                className={styles.removeImg}
                                onClick={() => handleImageRemoveLick(account)}
                              >
                                x
                              </div>
                            ) : null}
                            <img
                              src={
                                typeof account.icon === "string"
                                  ? `${REACT_APP_AMAZON_S3_URL}/${account.icon}`
                                  : URL.createObjectURL(account.icon)
                              }
                              alt="Uploaded"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <input
                      ref={(el) => {
                        inputRefs.current[index] = el!;
                      }}
                      type="file"
                      id={`fileInput${index}`}
                      name={`fileInput${index}`}
                      accept="image/*"
                      onChange={(e) =>
                        handleSocialMediaChange(
                          index,
                          "icon",
                          e.target.files ? e.target.files[0] : null
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.saveBtn}>
            <AdminButton
              disabled={isValidate}
              onClick={() =>
                !isEdit ? handleCreateClick() : handleEditClick()
              }
            >
              {!isEdit ? "Create" : "Update"}
            </AdminButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminContactUs;
