import { ActionReducerMapBuilder, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TMetaProps, TRoleProps } from "../../@types/type";
import { createRole, roleList } from "../api";

type TRoleState = {
    roleAllList: TRoleProps[];
    isError: boolean;
    isLoading: boolean;
    fetchAgain: boolean;
    meta: TMetaProps | null
}

const initialState: TRoleState = {
    roleAllList: [],
    meta: null,
    isError: false,
    isLoading: false,
    fetchAgain: true,
};

const roleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {},
    extraReducers: (builder: ActionReducerMapBuilder<any>) => {
        // create
        builder.addCase(createRole.pending, (state: TRoleState) => {
            state.isLoading = true;
        });
        builder.addCase(createRole.fulfilled, (state: TRoleState, action: PayloadAction<any>) => {
            state.isLoading = false;
            if (action.payload.status === 201) {
                const cloneRoleData = [...state.roleAllList];
                cloneRoleData.push(action.payload.data.data)
                state.roleAllList = cloneRoleData
            }
        });
        builder.addCase(createRole.rejected, (state: TRoleState) => {
            state.isLoading = false;
            state.isError = true;
        });
        // get list
        builder.addCase(roleList.pending, (state: TRoleState) => {
            state.isLoading = true;
        });
        builder.addCase(roleList.fulfilled, (state: TRoleState, action: PayloadAction<any>) => {
            state.isLoading = false;
            if (action.payload.status === 200) {
                state.roleAllList = action.payload.data.data.data
                state.meta = action.payload.data.data.meta
            }
        });
        builder.addCase(roleList.rejected, (state: TRoleState) => {
            state.isLoading = false;
            state.isError = true;
        });
    }
});

export default roleSlice.reducer;