import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type TTabState = {
    selectedType: string;
    isLoading: boolean;
}

const initialState: TTabState = {
    selectedType: localStorage.activeTab || "event",
    isLoading: false,
};

const tabSlice = createSlice({
    name: 'tab',
    initialState,
    reducers: {
        setSelectedTab: (state, action: PayloadAction<string>) => {
            state.selectedType = action.payload;
        }
    },
    extraReducers: (builder: unknown) => { }
});

export const { setSelectedTab } = tabSlice.actions;

export default tabSlice.reducer;