import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../app/store"
import { useEffect } from "react"
import { getLoggedInUser } from "../../app/api"
import { useNavigate } from "react-router-dom"
import styles from "./Profile.module.scss"
import Loading from "../../components/Loading/Loading"
import MainInfo from "./MainInfo/MainInfo"
import { logout } from "../../app/features/authSlice";
import SettingsForm from "./SettingsForm/SettingsForm"

const Profile = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { isLoading, userInfo } = useSelector((state: RootState) => state.auth)

  const getLoggedInUserData = async () => {
    const token = localStorage.getItem("access_token")

    if (token) {
      await dispatch(getLoggedInUser(token))
    } else {
      navigate('/join-us')
    }
  }

  const handleLogoutClick = () => {
    dispatch(logout())
    navigate("/join-us")
  }

  useEffect(() => {
    getLoggedInUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.profilePage}>
      {
        isLoading ? <Loading /> : (
          <>
            {userInfo && (() => {
              return (
                <>
                  <MainInfo handleLogoutClick={handleLogoutClick}/>
                  <SettingsForm />
                </>
              );
            })()}
          </>
        )
      }
    </div>
  )
}

export default Profile