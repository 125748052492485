import React from 'react';
import { TIconProps } from '../../../@types/icon';

const NotificationIcon = ({ fill, width, height }: TIconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 200 200"
            fill="none"
        >
            <path
                d="M84.55 27.0668C85.7951 23.9894 87.9309 21.3539 90.6835 19.4982C93.4362 17.6425 96.6803 16.6511 100 16.6511C103.32 16.6511 106.564 17.6425 109.317 19.4982C112.069 21.3539 114.205 23.9894 115.45 27.0668C127.773 30.4558 138.643 37.7964 146.39 47.9612C154.137 58.126 158.333 70.553 158.333 83.3334V122.475L173.6 145.375C174.437 146.63 174.918 148.089 174.991 149.596C175.065 151.103 174.727 152.601 174.016 153.931C173.304 155.262 172.244 156.374 170.95 157.149C169.656 157.924 168.175 158.334 166.667 158.333H128.875C127.872 165.276 124.401 171.625 119.098 176.216C113.795 180.808 107.015 183.335 100 183.335C92.9854 183.335 86.2055 180.808 80.9025 176.216C75.5995 171.625 72.1284 165.276 71.125 158.333H33.3334C31.8247 158.334 30.3442 157.924 29.05 157.149C27.7557 156.374 26.6962 155.262 25.9844 153.931C25.2727 152.601 24.9354 151.103 25.0086 149.596C25.0818 148.089 25.5627 146.63 26.4 145.375L41.6667 122.475V83.3334C41.6667 56.4668 59.8334 33.8334 84.55 27.0668ZM88.2167 158.333C89.0773 160.772 90.6732 162.884 92.7844 164.378C94.8955 165.871 97.418 166.674 100.004 166.674C102.59 166.674 105.113 165.871 107.224 164.378C109.335 162.884 110.931 160.772 111.792 158.333H88.2084H88.2167ZM100 41.6668C88.9493 41.6668 78.3513 46.0566 70.5372 53.8706C62.7232 61.6847 58.3334 72.2827 58.3334 83.3334V125C58.3337 126.646 57.8466 128.256 56.9334 129.625L48.9084 141.667H151.083L143.058 129.625C142.148 128.255 141.664 126.645 141.667 125V83.3334C141.667 72.2827 137.277 61.6847 129.463 53.8706C121.649 46.0566 111.051 41.6668 100 41.6668Z"
                fill={fill}
            />
        </svg>
    )
}

export default NotificationIcon;
