import React from "react";
import { TIconProps } from "../../../@types/icon";

const SlideIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
    >
      <path
        d="M55 70C53.6739 70 52.4021 70.5268 51.4645 71.4645C50.5268 72.4021 50 73.6739 50 75C50 76.3261 50.5268 77.5979 51.4645 78.5355C52.4021 79.4732 53.6739 80 55 80H95C96.3261 80 97.5979 79.4732 98.5355 78.5355C99.4732 77.5979 100 76.3261 100 75C100 73.6739 99.4732 72.4021 98.5355 71.4645C97.5979 70.5268 96.3261 70 95 70H55ZM55 95C53.6739 95 52.4021 95.5268 51.4645 96.4645C50.5268 97.4021 50 98.6739 50 100C50 101.326 50.5268 102.598 51.4645 103.536C52.4021 104.473 53.6739 105 55 105H125C126.326 105 127.598 104.473 128.536 103.536C129.473 102.598 130 101.326 130 100C130 98.6739 129.473 97.4021 128.536 96.4645C127.598 95.5268 126.326 95 125 95H55ZM50 125C50 123.674 50.5268 122.402 51.4645 121.464C52.4021 120.527 53.6739 120 55 120H105C106.326 120 107.598 120.527 108.536 121.464C109.473 122.402 110 123.674 110 125C110 126.326 109.473 127.598 108.536 128.536C107.598 129.473 106.326 130 105 130H55C53.6739 130 52.4021 129.473 51.4645 128.536C50.5268 127.598 50 126.326 50 125ZM45 40C38.3696 40 32.0107 42.6339 27.3223 47.3223C22.6339 52.0107 20 58.3696 20 65V135C20 141.63 22.6339 147.989 27.3223 152.678C32.0107 157.366 38.3696 160 45 160H155C161.63 160 167.989 157.366 172.678 152.678C177.366 147.989 180 141.63 180 135V65C180 58.3696 177.366 52.0107 172.678 47.3223C167.989 42.6339 161.63 40 155 40H45ZM30 65C30 61.0218 31.5804 57.2064 34.3934 54.3934C37.2064 51.5804 41.0218 50 45 50H155C158.978 50 162.794 51.5804 165.607 54.3934C168.42 57.2064 170 61.0218 170 65V135C170 138.978 168.42 142.794 165.607 145.607C162.794 148.42 158.978 150 155 150H45C41.0218 150 37.2064 148.42 34.3934 145.607C31.5804 142.794 30 138.978 30 135V65Z"
        fill={fill}
      />
    </svg>
  );
};

export default SlideIcon;
