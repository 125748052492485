import React from "react";
import { TIconProps } from "../../../@types/icon";

const MediaIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
    >
      <path
        d="M175 30V170C175 171.326 174.473 172.598 173.536 173.536C172.598 174.473 171.326 175 170 175H30C28.6739 175 27.4021 174.473 26.4645 173.536C25.5268 172.598 25 171.326 25 170V30C25 28.6739 25.5268 27.4021 26.4645 26.4645C27.4021 25.5268 28.6739 25 30 25H170C171.326 25 172.598 25.5268 173.536 26.4645C174.473 27.4021 175 28.6739 175 30Z"
        stroke={fill}
        strokeWidth="12.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.4833 70.9417C81.7234 70.5068 80.8627 70.2791 79.9872 70.2814C79.1117 70.2836 78.2521 70.5157 77.4944 70.9545C76.7368 71.3932 76.1076 72.0232 75.6699 72.7814C75.2321 73.5396 75.0011 74.3995 75 75.275V124.717C74.9997 125.593 75.2296 126.454 75.6667 127.213C76.1039 127.973 76.7329 128.604 77.4908 129.043C78.2487 129.483 79.1089 129.716 79.9851 129.719C80.8613 129.721 81.7228 129.494 82.4833 129.058L125.733 104.342C126.499 103.904 127.135 103.273 127.578 102.51C128.02 101.748 128.253 100.882 128.253 100C128.253 99.1184 128.02 98.2524 127.578 97.49C127.135 96.7275 126.499 96.0956 125.733 95.6583L82.4833 70.9417Z"
        stroke={fill}
        strokeWidth="12.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MediaIcon;
