import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type TLanguageState = {
    value: string;
    label: string;
}

const initialState = {
    currentLanguage: {
        value: localStorage?.i18nextLng || 'en',
        label: localStorage?.i18nextLng === 'ar' ? 'عربي' : 'Eng'
    }
};

const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<TLanguageState>) => {
            state.currentLanguage = action.payload;
            localStorage.i18nextLng = action.payload.value;
        },
    },
    extraReducers: (builder) => { }
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;