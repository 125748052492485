import React, { Fragment, useCallback, useEffect, useState } from "react";
import styles from "./EventsNnews.module.scss";
import { useTranslation } from "react-i18next";
import { TEventsNewsCardProps } from "../../@types/type";
import EventCard from "../../components/MediaCard/MediaCard";
import { AppDispatch, RootState } from "../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { eventsNewsList } from "../../app/api";
import Pagination from "../../components/Pagination/Pagination";
import Loading from "../../components/Loading/Loading";

const EventsNews = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [take, setTake] = useState<number>(10);
  const [eventNewsList, setEventNewsList] = useState<TEventsNewsCardProps[]>([]);
  const { meta, isLoading } = useSelector((state: RootState) => state.event_news);

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  const eventsNewsData = useCallback(async () => {
    try {
      await dispatch(eventsNewsList({ page, take })).then((res: any) => {
        const cloneEventsNewsList = [...eventNewsList];
        res.payload.data.data.data.forEach((item: TEventsNewsCardProps) => {
          cloneEventsNewsList.push(item);
        });
        setEventNewsList(cloneEventsNewsList);
      });
    } catch (error: unknown) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    eventsNewsData();
  }, [eventsNewsData]);

  return (
    <div className={`container ${styles.eventsNewsPage}`}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <h2>{t("events_news.header.title_text")}</h2>
        </div>
      </div>
      <div className={styles.eventsNewsBody}>
        {/* <h3>{t("events_news.header.sub_title_text")}</h3> */}
        <div className={styles.card}>
          {isLoading ? (
            <Loading />
          ) : (
            <Fragment>
              {eventNewsList.reduce(
                (result: JSX.Element[], eventsNews: TEventsNewsCardProps) => {
                  const item = <EventCard key={eventsNews.id} {...eventsNews} />;
                  result.push(item);
                  return result;
                },
                []
              )}
            </Fragment>
          )}
        </div>
      </div>
      <div className={styles.pagination}>
        <Pagination
          pageCount={meta && meta.pageCount}
          itemsPerPage={take}
          currentPage={page}
          isShow={meta?.hasNextPage}
          onLoadMore={handleLoadMore}
        />
      </div>
    </div>
  );
};

export default EventsNews;