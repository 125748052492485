import React from 'react'
import { TIconProps } from '../../@types/icon'

const HamburgerIcon = ({ fill, width, height }: TIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 25" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.1306 7.88635C21.1306 8.08526 21.0516 8.27603 20.9109 8.41668C20.7703 8.55734 20.5795 8.63635 20.3806 8.63635H4.38062C4.1817 8.63635 3.99094 8.55734 3.85029 8.41668C3.70963 8.27603 3.63062 8.08526 3.63062 7.88635C3.63062 7.68744 3.70963 7.49667 3.85029 7.35602C3.99094 7.21537 4.1817 7.13635 4.38062 7.13635H20.3806C20.5795 7.13635 20.7703 7.21537 20.9109 7.35602C21.0516 7.49667 21.1306 7.68744 21.1306 7.88635ZM21.1306 12.8864C21.1306 13.0853 21.0516 13.276 20.9109 13.4167C20.7703 13.5573 20.5795 13.6364 20.3806 13.6364H4.38062C4.1817 13.6364 3.99094 13.5573 3.85029 13.4167C3.70963 13.276 3.63062 13.0853 3.63062 12.8864C3.63062 12.6874 3.70963 12.4967 3.85029 12.356C3.99094 12.2154 4.1817 12.1364 4.38062 12.1364H20.3806C20.5795 12.1364 20.7703 12.2154 20.9109 12.356C21.0516 12.4967 21.1306 12.6874 21.1306 12.8864ZM21.1306 17.8864C21.1306 18.0853 21.0516 18.276 20.9109 18.4167C20.7703 18.5573 20.5795 18.6364 20.3806 18.6364H4.38062C4.1817 18.6364 3.99094 18.5573 3.85029 18.4167C3.70963 18.276 3.63062 18.0853 3.63062 17.8864C3.63062 17.6874 3.70963 17.4967 3.85029 17.356C3.99094 17.2154 4.1817 17.1364 4.38062 17.1364H20.3806C20.5795 17.1364 20.7703 17.2154 20.9109 17.356C21.0516 17.4967 21.1306 17.6874 21.1306 17.8864Z"
                fill={fill}
            />
        </svg>
    )
}

export default HamburgerIcon
