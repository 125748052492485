import React, { useEffect, useState } from "react";
import styles from "./uploadFile.module.scss";

const UploadFile: React.FC<{
  setBase64ValCB?: (image64: string | ArrayBuffer | null) => void;
  setFileDataValCB?: (file: File) => void;
  imageUrlString?: string;
}> = ({ setBase64ValCB, setFileDataValCB, imageUrlString }) => {
  const [imageUrl, setImageUrl] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [base64Val, setBase64Val] = useState<string | ArrayBuffer | null>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fileData, setFileData] = useState(null);

  //HANDEL CHANGE INPUT

  const handleChange = (event: any) => {
    const file = event.target?.files?.[0];
    setFileData(file);

    if (file) {
      // Get base64 representation
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setBase64Val(reader.result);
        setBase64ValCB && setBase64ValCB(reader.result!);
        setFileDataValCB && setFileDataValCB!(file);
      });
      reader.readAsDataURL(file);

      // Get URL representation
      const url = URL.createObjectURL(file);
      setImageUrl(url);
    }
  };

  useEffect(() => {
    if (imageUrlString !== "" && imageUrlString) {
      setImageUrl(imageUrlString);
    }
  }, [imageUrlString]);

  return (
    <div>
      <label htmlFor="upload-button">
        {imageUrl ? (
          <img src={imageUrl} alt="dummy" className={styles.imgPreview} />
        ) : (
          <div className={styles.uploadBtn}>
            <span className="fa-stack fa-2x mt-3 mb-2">
              <i className="fas fa-circle fa-stack-2x" />
              <i className="fas fa-store fa-stack-1x fa-inverse" />
            </span>
            <img
              src={require("../../../assets/images/gallery-add.jpg")}
              alt=""
              title="Upload Image"
            />
          </div>
        )}
      </label>
      <input
        type="file"
        id="upload-button"
        style={{ display: "none" }}
        onChange={handleChange}
      />
    </div>
  );
};

export default UploadFile;
