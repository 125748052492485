import { useEffect, useRef, useState } from "react";
import styles from "./OTPVerificationForrm.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import Modal from "../../../components/Modal/Modal";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import { TContactDetailsFormProps } from "../../../@types/type";
import { setOTPCode } from "../../../app/features/joinUsSlice";
import { sendVerificationCode } from "../../../app/api";
import { toast } from "react-toastify";

type TOTPVerificationFormProps = {
  otp: string[];
  handleSetOtp: (data: string[]) => void;
  numberOfDigits: number;
};

const OTPVerificationForm: React.FC<TOTPVerificationFormProps> = ({
  otp,
  numberOfDigits,
  handleSetOtp,
}) => {
  const otpBoxReference = useRef<any>([]);
  const { userDetails } = useSelector(
    (state: RootState) => state.joinUs
  );
  const [showChangeNumberModal, setShowChangeNumberModal] =
    useState<boolean>(false);
  const [contactErrors, setContactErrors] = useState<
    Partial<TContactDetailsFormProps>
  >({
    phone: "",
    email: "",
  });
  const [changedPhone, setChangedPhone] = useState("");
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const handleOTPChange = (value: string, index: number) => {
    const isNumeric = /^\d+$/.test(value);

    if (isNumeric || value === "") {
      let newArr = [...otp];
      newArr[index] = value;

      handleSetOtp(newArr);

      if (value && index < numberOfDigits - 1) {
        otpBoxReference.current[index + 1].focus();
      }
    }
  };

  const handleBackspaceAndEnter = (e: any, index: number) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  };

  const handleCloseChangeNumberModal = () => {
    setShowChangeNumberModal(false);
  };

  const handleShowChangeNumberModal = () => {
    setShowChangeNumberModal(true);
  };

  const validatePhoneNumber = (
    phoneNumber: string,
    countryCode: CountryCode
  ): boolean => {
    try {
      const parsedNumber = parsePhoneNumber(`+${phoneNumber}`, countryCode);
      return parsedNumber.isValid();
    } catch (error: any) {
      return false;
    }
  };

  const handlePhoneChange = (phone: string, country: CountryData) => {
    setChangedPhone(phone);
    const countryCode: any = country.countryCode as CountryCode;
    const isValid = validatePhoneNumber(phone, countryCode.toUpperCase());

    if (!isValid) {
      setContactErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Invalid phone number",
      }));
    } else {
      setContactErrors((prevErrors) => ({
        ...prevErrors,
        phone: "",
      }));
    }
  };

  const changePhoneNumber = async () => {
    await dispatch(sendVerificationCode({ phoneNumber: userDetails.phone })).then(
      (res: any) => {
        toast.success(t("join_us.verification.resent_code_successfully_text"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleCloseChangeNumberModal()
      },
    );
  };

  useEffect(() => {
    dispatch(setOTPCode(otp.join("")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  return (
    <div className={styles.otpContainer}>
      <div className={styles.otpHeader}>
        <div>
          {`${t("join_us.verification.otp_text")} +${userDetails.phone} ${t(
            "join_us.verification.whatsapp"
          )}`}
          <span onClick={handleShowChangeNumberModal}>
            ({t("join_us.verification.otp_change_number")})
          </span>
        </div>
      </div>
      <div className={styles.otpInputs}>
        <div>
          {otp.map((digit: string, index: number) => (
            <input
              type="text"
              key={index}
              value={digit}
              maxLength={1}
              onChange={(e) => handleOTPChange(e.target.value, index)}
              onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
              ref={(reference) => (otpBoxReference.current[index] = reference)}
            />
          ))}
        </div>
      </div>
      <div className={styles.otpTimer}>
        {t("join_us.verification.time_exparation_text")}
      </div>
      <Modal
        isOpen={showChangeNumberModal}
        disabled={!!contactErrors.phone}
        btnText="Change"
        onClose={handleCloseChangeNumberModal}
        onClick={changePhoneNumber}
      >
        <br />
        <PhoneInput
          country={"ae"}
          placeholder={t("join_us.phone.placeholder_text")}
          enableSearch={true}
          value={userDetails.phone}
          onChange={(phone: string, country: CountryData) =>
            handlePhoneChange(phone, country)
          }
        />
        {contactErrors.phone && (
          <div className={styles.error}>{contactErrors.phone}</div>
        )}
        <br />
      </Modal>
    </div>
  );
};

export default OTPVerificationForm;
