import { ActionReducerMapBuilder, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TMediaProps, TMetaProps } from "../../@types/type";
import { createMedia, mediaDeleteById, mediaFindById, mediaList, mediaUpdateById } from "../api";

type TMediaState = {
    allMediaData: TMediaProps[];
    events: TMediaProps[];
    news: TMediaProps[];
    singleMedia: TMediaProps | null;
    isError: boolean | null;
    isLoading: boolean;
    fetchAgain: boolean;
    meta: TMetaProps | null
}

const initialState: TMediaState = {
    events: [],
    news: [],
    singleMedia: null,
    allMediaData: [],
    isError: null,
    isLoading: false,
    fetchAgain: true,
    meta: null
};

const mediaSlice = createSlice({
    name: 'media',
    initialState,
    reducers: {
        clearMedia: (state, action: PayloadAction<string>) => {
            if (action.payload === "event") {
                state.events = []
            } else {
                state.news = []
            }
        },
        resetSingleMedia: (state) => {
            state.singleMedia = null
        },
        updateMediaFetchAgain: (state) => {
            state.fetchAgain = true
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<any>) => {
        // create
        builder.addCase(createMedia.pending, (state: TMediaState) => {
            state.isLoading = true;
        });
        builder.addCase(createMedia.fulfilled, (state: TMediaState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.fetchAgain = true;
            const {data, status} = action.payload
            if (status === 201) {
                const cloneMediaData = [...state.allMediaData];
                cloneMediaData.unshift(data.data)
                state.allMediaData = cloneMediaData
            }
        });
        builder.addCase(createMedia.rejected, (state: TMediaState) => {
            state.isLoading = false;
            state.isError = true;
        });
        // get list
        builder.addCase(mediaList.pending, (state: TMediaState) => {
            state.isLoading = true;
        });
        builder.addCase(mediaList.fulfilled, (state: TMediaState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.fetchAgain = false;
            if (action.payload?.response.status === 200) {
                const responseData = action.payload.response.data;

                if (responseData && responseData.data && responseData.data.data) {
                    
                    const data = responseData.data.data;

                    if (action.payload.type === 'event') {
                        state.events = data;
                    } else if (action.payload.type === 'news') {
                        state.news = data;
                    } else {
                        state.allMediaData = data;
                    }

                    state.meta = responseData.data.meta;
                    state.isError = false;
                } else {
                    state.isError = true;
                }
            } else {
                state.isError = true;
            }
        });
        builder.addCase(mediaList.rejected, (state: TMediaState) => {
            state.isLoading = false;
            state.fetchAgain = false;
            state.isError = true;
        });
        // find by id
        builder.addCase(mediaFindById.pending, (state: TMediaState) => {
            state.isLoading = true;
        });
        builder.addCase(mediaFindById.fulfilled, (state: TMediaState, action: PayloadAction<any>) => {
            state.isLoading = false;
            if (action.payload?.status === 200) {
                state.isError = null;
                state.singleMedia = action.payload.data.data
            } else {
                state.isError = true;
            }
        });
        builder.addCase(mediaFindById.rejected, (state: TMediaState) => {
            state.isLoading = false;
            state.isError = true;
        });
        // update
        builder.addCase(mediaUpdateById.pending, (state: TMediaState) => {
            state.isLoading = true;
        });
        builder.addCase(mediaUpdateById.fulfilled, (state: TMediaState, action: PayloadAction<any>) => {
            state.isLoading = false;
            if (action.payload.status === 200) {
                let cloneMediaData = [...state.allMediaData];
                let updatedMediaDataIndex = cloneMediaData.findIndex(media => media.id === action.payload.data.data.id)
                cloneMediaData[updatedMediaDataIndex] = action.payload.data.data;
                state.allMediaData = cloneMediaData
            }
        });
        builder.addCase(mediaUpdateById.rejected, (state: TMediaState) => {
            state.isLoading = false;
            state.isError = true;
        });
        // delete
        builder.addCase(mediaDeleteById.pending, (state: TMediaState) => {
            state.isLoading = true;
        });
        builder.addCase(mediaDeleteById.fulfilled, (state: TMediaState) => {
            state.isLoading = false;
            state.fetchAgain = true;
        });
        builder.addCase(mediaDeleteById.rejected, (state: TMediaState) => {
            state.isLoading = false;
            state.isError = true;
        });
    }
});

export const { clearMedia, resetSingleMedia, updateMediaFetchAgain } = mediaSlice.actions;

export default mediaSlice.reducer;