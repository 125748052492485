import { useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { confirmEmail, resendVerificationLink } from "../../app/api";
import Loading from "../../components/Loading/Loading";
import styles from "./EmailVerification.module.scss";
import SuccessIcon from "../../components/icons/SuccessIcon";
import { useTranslation } from "react-i18next";
import WarningIcon from "../../components/icons/WarningIcon";
import Button from "../../components/Button/Button";

const EmailVerification = () => {
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const verificationToken = new URLSearchParams(location.search).get("t");
  const {
    isLoading,
    isEmailConfirmed,
    isLinkTokenExpired,
    isEmailSuccessfullyVerified,
    isLinkSent,
  } = useSelector((state: RootState) => state.joinUs);

  const verifyEmail = useCallback(async () => {
    try {
      if (verificationToken) {
        return await dispatch(confirmEmail({ verificationToken, userId: id }));
      }
    } catch (error: unknown) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationToken]);

  const resendLink = async (event: any) => {
    try {
      if (id) {
        return await dispatch(resendVerificationLink(id));
      }
    } catch (error: unknown) {
      return {
        message: "Error fetching data:",
      };
    }
  };

  useEffect(() => {
    verifyEmail();
  }, [verifyEmail]);

  return (
    <div className={`container`}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {isEmailSuccessfullyVerified && (
            <div className={styles.message}>
              <SuccessIcon width="80px" height="80px" fill="#31a113" />
              <p className={styles.successMessage}>
                {t("join_us.verification.email_successfully_verified_text")}
              </p>
            </div>
          )}
          {isEmailConfirmed && (
            <div className={styles.message}>
              <SuccessIcon width="80px" height="80px" fill="#31a113" />
              <p className={styles.successMessage}>
                {t("join_us.verification.email_already_verified_text")}
              </p>
            </div>
          )}
          {isLinkTokenExpired && (
            <div className={styles.message}>
              <WarningIcon width="80px" height="80px" fill="#e52020" />
              <p className={styles.warningMessage}>
                {t("join_us.verification.email_link_expired_text")}
              </p>
              <Button onClick={(e) => resendLink(e)}>
                {t("join_us.verification.resend")}
              </Button>
            </div>
          )}
          {isLinkSent && (
            <div className={styles.message}>
              <SuccessIcon width="80px" height="80px" fill="#31a113" />
              <p className={styles.successMessage}>
                {t("join_us.verification.email_link_successfully_sent")}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EmailVerification;
