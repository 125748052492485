import React from 'react';
import { TIconProps } from '../../../@types/icon';

const FeedIcon = ({ fill, width, height }: TIconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 200 200"
            fill="none">
            <path
                d="M41.6667 175C37.0833 175 33.1583 173.367 29.8917 170.1C26.625 166.833 24.9945 162.911 25 158.333V41.6667C25 37.0833 26.6333 33.1583 29.9 29.8917C33.1667 26.625 37.0889 24.9945 41.6667 25H133.333L175 66.6667V158.333C175 162.917 173.367 166.842 170.1 170.108C166.833 173.375 162.911 175.006 158.333 175H41.6667ZM41.6667 158.333H158.333V75H125V41.6667H41.6667V158.333ZM58.3333 141.667H141.667V125H58.3333V141.667ZM58.3333 75H100V58.3333H58.3333V75ZM58.3333 108.333H141.667V91.6667H58.3333V108.333Z"
                fill={fill}
            />
        </svg>
    )
}

export default FeedIcon;
