import React, { useState } from "react";
import chroma from "chroma-js";
import { useEffect } from "react";
import { getdashboardStatistics } from "./apis/dashboard";
import { IDashboardData, IPieChartData } from "../../../@types/dashboard";
import Loading from "../../../components/Loading/Loading";
import PieChart from "../../components/PieChart/PieChart";
import styles from "./DashboardApp.module.scss";

const DashboardApp: React.FC = () => {
  const [industriesData, setIndustriesData] = useState<IPieChartData | null>(
    null
  );
  const [statisticsData, setStatisticsData] = useState<IPieChartData | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const generateRandomColor = () => {
    const colorScale = chroma.scale([
      "#394E92",
      "#E5A895",
      "#D5D5D5",
      "#667B9E",
      "#F2D3CA",
      "#6171A8",
      "#00235D",
      "#99CCCD",
      "#8A9484",
      "#5B274E",
      "#297BB4",
      "#4F5C9E",
      "#1E6768",
      "#789B9C",
      "#F67F19",
      "#A2400E",
    ]);

    const randomColor = colorScale(Math.random()).hex();

    return randomColor;
  };

  useEffect(() => {
    setIsLoading(true);
    getdashboardStatistics().then((res: any) => {
      const tempIndustriesData: IPieChartData = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      };
      const tempStatisticsData: IPieChartData = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      };

      if (res.industries.length) {
        res.industries.forEach((elm: IDashboardData) => {
          tempIndustriesData.labels.push(elm.label);
          tempIndustriesData.datasets[0].data.push(elm.count);
          tempIndustriesData.datasets[0].backgroundColor.push(
            generateRandomColor()
          );
        });
      }

      if (res.statistics.length) {
        res.statistics.forEach((elm: IDashboardData) => {
          tempStatisticsData.labels.push(elm.label);
          tempStatisticsData.datasets[0].data.push(elm.count);
          tempStatisticsData.datasets[0].backgroundColor.push(
            generateRandomColor()
          );
        });
      }

      setStatisticsData(tempStatisticsData);
      setIndustriesData(tempIndustriesData);

      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.chartsContainer}>
      {industriesData?.labels.length ? (
        <div>
          <h2>Industries</h2>
          <PieChart data={industriesData} />
        </div>
      ) : null}
      {statisticsData?.labels.length && (
        <div>
          <h2>Statistics</h2>
          <PieChart data={statisticsData} />
        </div>
      )}
    </div>
  );
};

export default DashboardApp;
