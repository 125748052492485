import { t } from "i18next";
import Button from "../../../components/Button/Button";
import { REACT_APP_AMAZON_S3_URL } from "../../../constants/constant";
import styles from "../Profile.module.scss"
import InputField from "../../../components/InputField/InputField";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { setProfileDetails, setShowOTP } from "../../../app/features/authSlice";
import { attachmentsFilesFormat, fDayTime, fYearTime } from "../../../utils/formats";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Uploadicon from "../../../components/icons/admin/Uploadicon";
import { useRef, useState } from "react";
import RadioButton from "../../../components/RadioButton/RadioButton";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { sendVerificationCode, updateUserData } from "../../../app/api";
import { toast } from "react-toastify";
import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import OTPVerificationForm from "../../JoinUs/Crads/OTPVerificationForm";

const SettingsForm: React.FC = () => {
    const { userInfo, showOTP } = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch<AppDispatch>()
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [newPassword, setNewPassword] = useState("")
    const [newEmail, setNewEmail] = useState(userInfo.email)
    const [isPhoneValid, setIsPhoneValid] = useState(true)
    const [confirmPassword, setConfirmPassword] = useState("")
    const numberOfDigits = 6;
    const [otp, setOtp] = useState<string[]>(new Array(numberOfDigits).fill(""));
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const strFile: any = userInfo.selectedFile;
    const monthTranslations: string[] = t("date.months", { returnObjects: true });
    const countries = t("countries", { returnObjects: true });
    const nationalities = t("nationalities", { returnObjects: true });
    const gender = t("gender", { returnObjects: true });

    const months = monthTranslations.map((month, index) => {
        const value = (index + 1 < 10 ? "0" : "") + (index + 1).toString();
        return { value, label: month };
    });

    const nationalityOptions = Object.entries(nationalities).map(
        ([value, label]) => ({
            value,
            label,
        })
    );
    const countryOptions = Object.entries(countries)?.map(([index, value]) => {
        return { value: value.code, label: value.name };
    });

    const genderOptions = Object.entries(gender).map(([value, label]) => ({
        value,
        label,
    }));

    const securityTranslations: string[] = t("security.data", {
        returnObjects: true,
    });
    const security = securityTranslations?.map((sec, index) => {
        const value = (index + 1).toString();
        return { value, label: sec };
    });

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewEmail(e.target.value)
    }

    const handlePhoneChange = (phone: string, country: CountryData) => {
        const countryCode: any = country.countryCode as CountryCode;
        const isValid = validatePhoneNumber(phone, countryCode.toUpperCase());
        setIsPhoneValid(isValid)
        dispatch(
            setProfileDetails({
                key: "phone",
                value: phone,
            })
        );
    }

    const validatePhoneNumber = (
        phoneNumber: string,
        countryCode: CountryCode
    ): boolean => {
        try {
            const parsedNumber = parsePhoneNumber(`+${phoneNumber}`, countryCode);
            return parsedNumber.isValid();
        } catch (error: any) {
            return false;
        }
    };

    const handlePofileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;

        if (id === "notConfirm" || id === "confirm") {
            dispatch(
                setProfileDetails({
                    key: "contribute",
                    value,
                })
            );
        } else if (id === "workshops" || id === "researchPapers") {
            dispatch(
                setProfileDetails({
                    key: "multipleContribution",
                    value: value,
                })
            );
        } else {
            dispatch(setProfileDetails({ key: id, value }));
        }
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;

        if (id === "password") {
            setNewPassword(value)
        } else {
            setConfirmPassword(value)
        }
    }

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;

        if (id === "day") {
            const modifiedValue = value ? fDayTime(value) : value;
            dispatch(setProfileDetails({ key: id, value: modifiedValue }));
        } else {
            if (value.length <= 4) {
                const yearValue = fYearTime(value);
                dispatch(setProfileDetails({ key: id, value: yearValue }));
            }
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            setSelectedFile(file);
            dispatch(
                setProfileDetails({
                    key: "file",
                    value: event.target.files?.[0],
                })
            );
        }
    };

    const handleResetFile = () => {
        setSelectedFile(null);
        dispatch(
            setProfileDetails({
                key: "file",
                value: null,
            })
        );
        const fileInput = inputRefs.current[0];
        if (fileInput) {
            fileInput.value = "";
        }
    };

    const handleResetLicenseFile = () => {
        dispatch(
            setProfileDetails({
                key: "resetLicenseFile",
                value: "",
            })
        );
    }

    const handleSetOtp = (data: string[]) => {
        setOtp([...data]);
    };

    const validateOtp = () => {
        return otp.every(digit => digit !== "");
    };

    const handleSaveProfile = async () => {
        const query: any = {
            ...userInfo,
            newPassword,
            newEmail,
            otp
        }

        if (!isPhoneValid) {
            return toast.error(t("Invalid phone number"), {
                position: toast.POSITION.TOP_RIGHT,
            });
        }

        if (!(newPassword === confirmPassword)) {
            return toast.error(t("join_us.errors.confirm_password_text"), {
                position: toast.POSITION.TOP_RIGHT,
            });
        }

        const formData: any = new FormData();

        formData.append("id", query.id);
        formData.append("firstName", query.firstName);
        formData.append("lastName", query.lastName);
        formData.append("birthdate", query.birthdate);
        formData.append("gender", query.gender);
        formData.append("nationality", query.nationality);
        formData.append("residency", query.residency);
        formData.append("email", query.newEmail);
        formData.append("phone", query.phone);
        formData.append("password", query.newPassword)
        formData.append("role", "Member");

        formData.append("security", query.security);
        formData.append("jobTitle", query.jobTitle);
        formData.append("companyName", query.companyName);
        formData.append("country", query.country);
        formData.append("licenseIssuingCountry", query.licenseIssuingCountry);
        formData.append("licenseIdNumber", query.licenseIdNumber);

        if (query.otp[0]) {
            formData.append("otp", query.otp.join(""))
        }

        if (query.multipleContribution.length) {
            query.multipleContribution.forEach((item: any) => {
                formData.append("contribution", item);
            });
        } else {
            formData.append("contribution", "");
        }

        if (query.selectedFile !== null) {
            formData.append("licenseFile", query.selectedFile);
        }

        if (query.residency === "United Arab Emirates") {
            formData.append("emiratesIdNumber", query.emiratesIdNumber)
            formData.append("emiratesIdExpiredDate", query.emiratesIdExpiredDate)
            formData.append("martialStatus", query.martialStatus)
        }

        await dispatch(updateUserData(formData)).then((res: any) => {
            if (res.payload?.data?.status === 409) {
                return toast.error("User with this email already exists", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else if (res.payload?.data?.status === 400 || res.payload?.data?.status === 404) {
                return toast.error(t("join-us.errors.error_text"), {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else if (res.payload?.data?.isCodeSent) {
                dispatch(setShowOTP(true))
            } else if (res.payload?.data?.wrongOTP) {
                return toast.error("Wrong OTP", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                dispatch(setShowOTP(false))
                return toast.success("Profile updated successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
    }

    const handleResendClick = async () => {
        const query: any = {
            ...userInfo,
        };
        await dispatch(sendVerificationCode({ phoneNumber: query.phone })).then(
            (res: any) => {
                toast.success(t("join_us.verification.resent_code_successfully_text"), {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        );
    };


    return <div className={styles.profileSettings}>
        <h1>{t("profile.profile_settings")}</h1>
        {
            showOTP ? <div className={styles.otpContainer}>
                <OTPVerificationForm otp={otp}
                    handleSetOtp={handleSetOtp}
                    numberOfDigits={numberOfDigits} />
                <div
                    className={`${styles.nextBtn} ${styles.btnStyle}`}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleResendClick}
                    >
                        {t("join_us.verification.resend")}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!validateOtp()}
                        onClick={handleSaveProfile}
                    >
                        {t("join_us.verification.verify")}
                    </Button>
                </div>
            </div> : <div className={styles.profileSettingsForm}>
                <div className={styles.sectionContent}>
                    <div className={styles.inputField}>
                        <InputField
                            id="firstName"
                            value={userInfo?.firstName}
                            onChange={handlePofileInputChange}
                            type="string"
                            label={t("join_us.first_name.label_text")}
                            required
                            placeholder={t("join_us.first_name.placeholder_text")}
                        />
                    </div>
                    <div className={styles.inputField}>
                        <InputField
                            id="lastName"
                            value={userInfo?.lastName}
                            onChange={handlePofileInputChange}
                            type="string"
                            label={t("join_us.last_name.label_text")}
                            required
                            placeholder={t("join_us.last_name.placeholder_text")}
                        />
                    </div>
                    <div className={styles.inputField}>
                        <InputField
                            id="email"
                            value={newEmail}
                            onChange={handleEmailChange}
                            type="string"
                            label={t("join_us.email.label_text")}
                            required
                            placeholder={t("join_us.email.placeholder_text")}
                        />
                    </div>
                    <div className={styles.inputField}>
                        <div className={`${styles.checkboxTitle} oye`}>
                            <span>{t("join_us.phone.label_text")}</span>
                            <span className={styles.required}>*</span>
                        </div>
                        <PhoneInput
                            country={"ae"}
                            placeholder={t("join_us.phone.placeholder_text")}
                            enableSearch={true}
                            value={userInfo?.phone}
                            onChange={(phone: string, country: CountryData) =>
                                handlePhoneChange(phone, country)
                            }
                        />
                    </div>
                    <div className={styles.inputField}>
                        <InputField
                            id="password"
                            value={newPassword}
                            onChange={handlePasswordChange}
                            type={"password"}
                            label={t("sign_in.password.label_text")}
                            required
                            placeholder={t("sign_in.password.description_text")}
                        />
                    </div>
                    <div className={styles.inputField}>
                        <InputField
                            id="confirm-password"
                            value={confirmPassword}
                            onChange={handlePasswordChange}
                            type={"password"}
                            label={t("sign_in.confirm_password.label_text")}
                            required
                            placeholder={t("sign_in.confirm_password.description_text")}
                        />
                    </div>
                    <div className={`${styles.inputField} ${styles.fullWidth}`}>
                        <p className={styles.dateTitle}>
                            {t("join_us.date_Of_Birth.label_text")}{" "}
                        </p>
                        <div className={styles.birthDate}>
                            <InputField
                                id="day"
                                value={userInfo?.dateValue?.day}
                                onChange={handleDateChange}
                                type="number"
                                required
                                placeholder={t("join_us.date_Of_Birth.day_text")}
                            />
                            <Dropdown
                                defaultValue={t("join_us.date_Of_Birth.month_text")}
                                options={months}
                                selectedValue={
                                    userInfo.selectedMonth
                                        ? userInfo.selectedMonth?.label
                                        : ""
                                }
                                onValueChange={(selectedValue) =>
                                    dispatch(
                                        setProfileDetails({
                                            key: "selectedMonth",
                                            value: selectedValue,
                                        })
                                    )
                                }
                            />
                            <InputField
                                id="year"
                                value={userInfo.dateValue?.year}
                                onChange={handleDateChange}
                                type="number"
                                required
                                placeholder={t("join_us.date_Of_Birth.year_text")}
                            />
                        </div>
                    </div>
                    <div className={styles.inputField}>
                        <p className={styles.dateTitle}>
                            {t("join_us.nationality.label_text")}{" "}
                        </p>
                        <div>
                            <Dropdown
                                defaultValue={t("join_us.nationality.placeholder_text")}
                                options={nationalityOptions}
                                search
                                inputPlaceholder={t("join_us.nationality.placeholder_text")}
                                selectedValue={
                                    userInfo.selectedNationality
                                        ? userInfo.selectedNationality.label
                                        : ""
                                }
                                onValueChange={(selectedValue) =>
                                    dispatch(
                                        setProfileDetails({
                                            key: "selectedNationality",
                                            value: selectedValue,
                                        })
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.inputField}>
                        <p className={styles.dateTitle}>
                            {t("join_us.residency.label_text")}{" "}
                        </p>
                        <div>
                            <Dropdown
                                defaultValue={t("join_us.residency.placeholder_text")}
                                search
                                inputPlaceholder={t("join_us.residency.placeholder_text")}
                                options={countryOptions}
                                selectedValue={
                                    userInfo.selectedResidency
                                        ? userInfo.selectedResidency.label
                                        : ""
                                }
                                onValueChange={(selectedValue) => {
                                    dispatch(
                                        setProfileDetails({
                                            key: "selectedResidency",
                                            value: selectedValue,
                                        })
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className={`${styles.inputField}`}>
                        <div className={styles.checkboxTitle}>
                            <span>{t("join_us.gender.title_text")}</span>
                        </div>
                        <div>
                            <Dropdown
                                defaultValue={t("join_us.gender.placeholder_text")}
                                options={genderOptions}
                                inputPlaceholder={t("join_us.nationality.placeholder_text")}
                                selectedValue={
                                    userInfo.gender ? userInfo.gender : ""
                                }
                                onValueChange={(selectedValue) => {
                                    dispatch(
                                        setProfileDetails({
                                            key: "gender",
                                            value: selectedValue.value,
                                        })
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <h2 className={styles.fullWidth}>{t("join_us.company_details_text")}</h2>
                    <div className={styles.inputField}>
                        <p className={styles.dateTitle}>
                            {t("join_us.security.label_text")}{" "}
                        </p>
                        <div>
                            <Dropdown
                                defaultValue={t("join_us.security.placeholder_text")}
                                options={security}
                                selectedValue={
                                    userInfo.selectedSecurity
                                        ? userInfo.selectedSecurity.label
                                        : ""
                                }
                                onValueChange={(selectedValue) =>
                                    dispatch(
                                        setProfileDetails({
                                            key: "selectedSecurity",
                                            value: selectedValue,
                                        })
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.inputField}>
                        <InputField
                            id="jobTitle"
                            value={userInfo?.jobTitle}
                            onChange={handlePofileInputChange}
                            type="string"
                            label={t("join_us.job_title.label_text")}
                            placeholder={t("join_us.job_title.placeholder_text")}
                        />
                    </div>
                    <div className={styles.inputField}>
                        <InputField
                            id="companyName"
                            value={userInfo?.companyName}
                            onChange={handlePofileInputChange}
                            type="string"
                            label={t("join_us.company_name.label_text")}
                            placeholder={t("join_us.company_name.placeholder_text")}
                        />
                    </div>
                    <div className={styles.inputField}>
                        <InputField
                            id="country"
                            value={userInfo?.country}
                            onChange={handlePofileInputChange}
                            type="string"
                            label={t("join_us.company_address.label_text")}
                            placeholder={t("join_us.company_address.placeholder_text")}
                        />
                    </div>
                    <div className={styles.inputField}>
                        <p className={styles.dateTitle}>
                            {t("join_us.license.label_text")}{" "}
                        </p>
                        <div>
                            <Dropdown
                                defaultValue={t("join_us.license.placeholder_text")}
                                search
                                inputPlaceholder={t("join_us.license.placeholder_text")}
                                options={countryOptions}
                                selectedValue={
                                    userInfo.selectedLicenseCountry
                                        ? userInfo.selectedLicenseCountry.label
                                        : ""
                                }
                                onValueChange={(selectedValue) => {
                                    dispatch(
                                        setProfileDetails({
                                            key: "selectedLicenseCountry",
                                            value: selectedValue,
                                        })
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.inputField}>
                        <InputField
                            id="licenseIdNumber"
                            value={userInfo?.licenseIdNumber}
                            onChange={handlePofileInputChange}
                            type="string"
                            label={t("join_us.licenseIDNumber.label_text")}
                            placeholder={t("join_us.licenseIDNumber.placeholder_text")}
                        />
                    </div>
                    <div className={`${styles.upload} ${styles.fullWidth}`}>
                        <div className={styles.upladedFiles}>
                            {userInfo.selectedFile && !selectedFile ? (
                                <div className={styles.file}>
                                    <div className={styles.removeFile} onClick={handleResetFile}>
                                        x
                                    </div>
                                    {attachmentsFilesFormat(strFile)?.fileName}
                                </div>
                            ) : selectedFile ? (
                                <div className={styles.file}>
                                    <div className={styles.removeFile} onClick={handleResetFile}>
                                        x
                                    </div>
                                    {selectedFile.type.startsWith("image/") ? (
                                        <img src={URL.createObjectURL(selectedFile)} alt="Uploaded" />
                                    ) : (
                                        <p>{selectedFile.name}</p>
                                    )}
                                </div>
                            ) : userInfo.licenseFile ?
                                <div className={styles.file}>
                                    <div className={styles.removeFile} onClick={handleResetLicenseFile}>
                                        x
                                    </div>
                                    <img
                                        src={
                                            typeof userInfo.licenseFile === "string"
                                                ? `${REACT_APP_AMAZON_S3_URL}/${userInfo.licenseFile}`
                                                : URL.createObjectURL(userInfo.licenseFile)
                                        }
                                        alt="Uploaded"
                                    />
                                </div>
                                : (
                                    <div
                                        className={styles.uploadFile}
                                        onClick={() => inputRefs.current[0].click()}
                                    >
                                        <Uploadicon width="30px" height="30px" fill="#333333" />
                                        <p>Upload licenses file</p>
                                    </div>
                                )}
                        </div>
                        <input
                            ref={(el) => {
                                inputRefs.current[0] = el!;
                            }}
                            type="file"
                            id="fileInput"
                            name="fileInput"
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className={`${styles.inputRadioField} ${styles.fullWidth}`}>
                        <div className={styles.radioTitle}>
                            <span>{t("join_us.contribute.title_text")}</span>
                        </div>
                        <div className={styles.radioField}>
                            <RadioButton
                                id="confirm"
                                label={t("join_us.contribute.label_yes_text")}
                                value="Yes"
                                name="contribute"
                                checked={userInfo.contribute === "Yes"}
                                onChange={handlePofileInputChange}
                            />
                            <RadioButton
                                id="notConfirm"
                                label={t("join_us.contribute.label_no_text")}
                                value="No"
                                name="contribute"
                                checked={userInfo.contribute === "No"}
                                onChange={handlePofileInputChange}
                            />
                        </div>
                    </div>
                    {userInfo.contribute === "Yes" ? (
                        <div className={`${styles.multipleContribution} ${styles.fullWidth}`}>
                            <div>
                                <Checkbox
                                    id="workshops"
                                    checked={userInfo.multipleContribution?.includes(
                                        t("join_us.multiple_contribution_text_workshops")
                                    )}
                                    value={t("join_us.multiple_contribution_text_workshops")}
                                    onChange={handlePofileInputChange}
                                />
                                <div className={styles.text}>
                                    {t("join_us.multiple_contribution_text_workshops")}
                                </div>
                            </div>
                            <div>
                                <Checkbox
                                    id="researchPapers"
                                    checked={userInfo.multipleContribution?.includes(
                                        t("join_us.multiple_contribution_text_research_papers")
                                    )}
                                    value={t("join_us.multiple_contribution_text_research_papers")}
                                    onChange={handlePofileInputChange}
                                />
                                <div className={styles.text}>
                                    {t("join_us.multiple_contribution_text_research_papers")}
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <div className={`${styles.cardDetails} ${styles.fullWidth}`}>
                        {
                            userInfo?.selectedResidency?.label === "United Arab Emirates" ? <p>
                                {t("join_us.card_details_text")}
                            </p> : null
                        }
                        {
                            userInfo?.selectedResidency?.label === "United Arab Emirates" ? <div>
                                <div className={styles.sectionContent}>
                                    <div className={styles.inputField}>
                                        <InputField
                                            id="emiratesIdNumber"
                                            value={userInfo.emiratesIdNumber ? userInfo.emiratesIdNumber : ""}
                                            onChange={handlePofileInputChange}
                                            type="string"
                                            label={t("join_us.emiratesIdNumber.label_text")}
                                            placeholder={t("join_us.emiratesIdNumber.placeholder_text")}
                                        />
                                    </div>
                                    <div className={styles.inputField}>
                                        <InputField
                                            id="emiratesIdExpiredDate"
                                            value={userInfo.emiratesIdExpiredDate ? userInfo.emiratesIdExpiredDate : ""}
                                            onChange={handlePofileInputChange}
                                            type="date"
                                            label={t("join_us.emiratesIdExpiredDate.label_text")}
                                            placeholder={t("join_us.emiratesIdExpiredDate.placeholder_text")}
                                        />
                                    </div>
                                </div>

                                <div className={styles.martialStatus}>
                                    <div className={styles.inputRadioField}>
                                        <div className={styles.radioTitle}>
                                            <span>{t("join_us.martialStatus.title_text")}</span>
                                        </div>
                                        <div className={styles.radioField}>
                                            <RadioButton
                                                id="martialStatus"
                                                label={t("join_us.martialStatus.label_married_text")}
                                                value="Married"
                                                name="martialStatus"
                                                checked={userInfo.martialStatus === "Married"}
                                                onChange={handlePofileInputChange}
                                            />
                                            <RadioButton
                                                id="martialStatus"
                                                label={t("join_us.martialStatus.label_single_text")}
                                                value="Single"
                                                name="martialStatus"
                                                checked={userInfo.martialStatus === "Single"}
                                                onChange={handlePofileInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> : null
                        }
                    </div>
                </div>
                <Button onClick={handleSaveProfile}>Save Profile</Button>
            </div>
        }
    </div>
}

export default SettingsForm