import React, { useCallback, useEffect, useState } from "react";
import styles from "./ContactUs.module.scss";
import {
  TContactUsProps,
  TContactUsSendEmailProps,
  TSocialMediaProps,
} from "../../@types/type";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import AddressIcon from "../../components/icons/AddressIcon";
import InputField from "../../components/InputField/InputField";
import Button from "../../components/Button/Button";
import { useTranslation } from "react-i18next";
import EmailIcon from "../../components/icons/EmailIcon";
import Dropdown from "../../components/Dropdown/Dropdown";
import { TFunction } from "i18next";
import FollowIcon from "../../components/icons/FollowIcon";
import { AppDispatch, RootState } from "../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { contactUsList, contactUsSendEmail } from "../../app/api";
import { REACT_APP_AMAZON_S3_URL } from "../../constants/constant";
import Loading from "../../components/Loading/Loading";

type TStateProps = {
  label: string;
  value: string;
};

const validateEmail = (value: string, t: TFunction) => {
  if (value === "") {
    return t("contact_us.errors.required_text");
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value) ? "" : t("contact_us.errors.email_text");
};

const validateName = (value: string, t: TFunction) => {
  if (value === "") {
    return t("contact_us.errors.required_text");
  }

  return /^[A-Za-z\s]+$/.test(value) ? "" : t("contact_us.errors.name_text");
};

const ContactUs = () => {
  const { t, i18n } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const types = t("contact_us.form_data.type", { returnObjects: true });
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const { contactUsData, isLoading } = useSelector(
    (state: RootState) => state.contactUs
  );
  const languageHeaderClass =
    i18n.language === "ar" ? "arabicHeader" : "englishHeader";
  const [formData, setFormData] = useState<TContactUsSendEmailProps>({
    fullname: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    type: "",
  });
  const [errors, setErrors] = useState<Partial<TContactUsSendEmailProps>>({
    fullname: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    type: "",
  });
  const [selectedType, setSelectedType] = useState<TStateProps>({
    label: "",
    value: "inquiry",
  });

  const formTypeOptions = Object.entries(types).map(([value, label]) => ({
    value,
    label,
  }));

  const validateField = (id: string, value: string) => {
    switch (id) {
      case "email":
        return validateEmail(value, t);
      case "name":
        return validateName(value, t);
      case "subject":
        return value === "" ? t("contact_us.errors.required_text") : "";
      case "message":
        return value === "" ? t("contact_us.errors.required_text") : "";
      default:
        return "";
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    const error = validateField(id, value);

    setErrors((prevErrors) => ({ ...prevErrors, [id]: error }));
    setFormData((prevFormData) => ({ ...prevFormData, [id]: value }));
  };

  const handleSendClick = async () => {
    await dispatch(contactUsSendEmail(formData)).then((res: any) => {
      if (res.payload.status === 201) {
        setFormData({
          fullname: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
          type: "",
        });
      }
    });
  };

  const contactUs = useCallback(async () => {
    try {
      await dispatch(contactUsList());
    } catch (error: unknown) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    contactUs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactUs]);

  useEffect(() => {
    setSelectedType((prevSelectedType) => ({
      ...prevSelectedType,
      label: t("contact_us.form_data.type.inquiry"),
    }));
  }, [t]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      type: selectedType.value,
    }));
  }, [selectedType]);

  useEffect(() => {
    const hasAnyError = Object.values(errors).some((value) => value);

    const isFormEmpty = Object.entries(formData).some(([key, value]) => {
      return value === "";
    });

    const isFormValid = !isFormEmpty && !hasAnyError;

    setIsValidForm(isFormValid);
  }, [errors, formData]);

  return (
    <div className={`container ${styles.contactUsPage}`}>
      <h2 className={styles.title}>{t("contact_us.title_text")}</h2>
      <p className={styles.subTitle}>{t("contact_us.sub_title_text")}</p>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.contactSection}>
          <div className={styles.contactChannels}>
            <div className={styles.channelOptions}>
              <div className={styles.icon}>
                <AddressIcon width="35px" height="35px" fill="#FFFFFF" />
              </div>
              <div className={styles.channelDesc}>
                <p>{t("contact_us.channel.location_text")}</p>
                <div>
                  {contactUsData &&
                    (contactUsData[
                      `address_${i18n.language}` as keyof TContactUsProps
                    ] as string)}
                </div>
              </div>
            </div>
            <div className={styles.channelOptions}>
              <div className={styles.icon}>
                <EmailIcon width="35px" height="35px" fill="#FFFFFF" />
              </div>
              <div className={styles.channelDesc}>
                <p>{t("contact_us.channel.email_phone_text")}</p>
                <div>
                  <div>
                    <span className={styles.staticTitle}>{`${t(
                      "contact_us.channel.email_text"
                    )} \u00A0`}</span>
                    <Link
                      to={`mailto:${contactUsData?.email}`}
                      onClick={() => (window.location.href = "")}
                    >
                      {contactUsData && contactUsData.email}
                    </Link>
                  </div>
                  <div>
                    <span className={styles.staticTitle}>{`${t(
                      "contact_us.channel.phone_text"
                    )} \u00A0`}</span>
                    <Link to={`tel:${contactUsData?.phoneNumber}`}>
                      {contactUsData && contactUsData.phoneNumber}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.channelFolowOptions}>
              <div className={styles.icon}>
                <FollowIcon width="35px" height="35px" fill="#FFFFFF" />
              </div>
              <div className={styles.channelTitle}>
                {t("contact_us.channel.follow_us_text")}
              </div>
              <div
                className={`${
                  contactUsData && contactUsData.socialMediaAccounts.length > 5
                    ? styles.socialMediaGrid
                    : styles.socialMediaFlex
                }`}
              >
                {contactUsData &&
                  contactUsData.socialMediaAccounts.reduce(
                    (result: JSX.Element[], media: TSocialMediaProps) => {
                      const item = (
                        <div key={uuidv4()} className={styles.itemMedia}>
                          <Link to={media.link} target="_blank">
                            <img
                              src={`${REACT_APP_AMAZON_S3_URL}/${media.icon}`}
                              alt="icon"
                            />
                          </Link>
                        </div>
                      );
                      result.push(item);
                      return result;
                    },
                    []
                  )}
              </div>
            </div>
          </div>
          <div className={styles.formData}>
            <h3>{t("contact_us.form_data.title_text")}</h3>
            <div className={styles.form}>
              <div className={styles.inputField}>
                <p>
                  {t("contact_us.form_data.type_text")}
                  <span className={styles.required}>&nbsp;*</span>
                </p>
                <Dropdown
                  options={formTypeOptions}
                  selectedValue={selectedType ? selectedType.label : ""}
                  onValueChange={setSelectedType}
                />
              </div>
              <div className={styles.inputField}>
                <InputField
                  id="fullname"
                  value={formData.fullname}
                  onChange={handleInputChange}
                  type="string"
                  label={t("contact_us.form_data.name.label_text")}
                  required
                  placeholder={t("contact_us.form_data.name.placeholder_text")}
                />
                {errors.fullname && (
                  <div className={styles.error}>{errors.fullname}</div>
                )}
              </div>
              <div className={styles.inputField}>
                <InputField
                  id="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  type="email"
                  label={t("contact_us.form_data.email.label_text")}
                  required
                  placeholder={t("contact_us.form_data.email.placeholder_text")}
                />
                {errors.email && (
                  <div className={styles.error}>{errors.email}</div>
                )}
              </div>
              <div className={styles.inputField}>
                <InputField
                  id="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  type="number"
                  label={t("contact_us.form_data.mobile.label_text")}
                  placeholder={t(
                    "contact_us.form_data.mobile.placeholder_text"
                  )}
                />
              </div>
              <div className={`${styles.inputField} ${styles.fullWidth}`}>
                <InputField
                  id="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  type="text"
                  label={t("contact_us.form_data.subject.label_text")}
                  required
                  placeholder={t(
                    "contact_us.form_data.subject.placeholder_text"
                  )}
                />
                {errors.subject && (
                  <div className={styles.error}>{errors.subject}</div>
                )}
              </div>
              <div className={`${styles.inputField} ${styles.fullWidth}`}>
                <div className={styles.textareaLabel}>
                  {t("contact_us.form_data.message.label_text")} <span>*</span>
                </div>
                <textarea
                  id="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  placeholder={t(
                    "contact_us.form_data.message.placeholder_text"
                  )}
                  className={languageHeaderClass}
                />
                {errors.message && (
                  <div className={styles.error}>{errors.message}</div>
                )}
              </div>
            </div>
            <div className={styles.sendBtn}>
              <Button
                variant="outlined"
                disabled={!isValidForm}
                onClick={handleSendClick}
              >
                {t("contact_us.btn_text")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUs;
