import React from 'react'
import { TIconProps } from '../../../@types/icon'

const ContactUsIcon = ({ fill, width, height }: TIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 200" fill="none">
            <g clipPath="url(#clip0_180_26)">
                <path
                    d="M16.6667 25H0V175H200V25H16.6667ZM183.333 41.6667V158.333H16.6667V41.6667H183.333ZM83.3333 58.3333H50V91.6667H83.3333V58.3333ZM33.3333 108.333H100V141.667H33.3333V108.333ZM166.667 58.3333H116.667V75H166.667V58.3333ZM116.667 91.6667H166.667V108.333H116.667V91.6667ZM166.667 125H116.667V141.667H166.667V125Z"
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_180_26">
                    <rect width="200" height="200" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ContactUsIcon
