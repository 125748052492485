import React from "react";
import styles from "./ConfirmationModal.module.scss";
import AdminButton from "../../../components/AdminButton/AdminButton";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";

type TConfirmationModalProps = {
  isOpen: boolean;
  text: string;
  onClose: () => void;
  onClick: () => void;
  onOutside?: boolean;
  disabled?: boolean;
};

const ConfirmationModal: React.FC<TConfirmationModalProps> = ({
  isOpen,
  text,
  onClose,
  onClick,
  onOutside,
  disabled,
}) => {
  const ref = useOutsideClick(() => {
    onClose();
  });

  const { disabledBtn } = useSelector((state: RootState) => state.disabled);

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div ref={onOutside ? ref : null} className={styles.modalContent}>
        <div className={styles.text}>{text}</div>
        <div className={styles.confirmActions}>
          <AdminButton color="error" onClick={onClose}>
            No
          </AdminButton>
          <AdminButton onClick={onClick} disabled={disabledBtn || disabled}>
            Yes
          </AdminButton>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
