import React, { Dispatch, useState } from "react";
import styles from "./TableBody.module.scss";
import AdminButton from "../../../../components/AdminButton/AdminButton";
import PlusIcon from "../../../../components/icons/PlusIcon";
import { v4 as uuidv4 } from "uuid";
import EditIcon from "../../../../components/icons/admin/EditIcon";
import DeleteIcon from "../../../../components/icons/admin/DeleteIcon";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import { REACT_APP_AMAZON_S3_URL } from "../../../../constants/constant";
import { AppDispatch, RootState } from "../../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { publicationDeleteById } from "../../../../app/api";

type TableBodyProps = {
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
  rowsData: Array<unknown>;
  headerColumns: Array<unknown>;
  handleEditRowClick: (id: string) => void;
};

const TableBody = ({
  setShowModal,
  rowsData,
  headerColumns,
  handleEditRowClick,
}: TableBodyProps) => {
  const dispatch: AppDispatch = useDispatch();
  const [deletingPublicationId, setDeletingPublicationId] = useState<
    null | number | string
  >(null);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const { isLoading } = useSelector((state: RootState) => state.publication);

  const handleDeleteRowClick = (id: string) => {
    setDeletingPublicationId(id);
    setShowConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    setDeletingPublicationId(null);
  };

  const handleConfirmClick = async () => {
    await dispatch(publicationDeleteById({ id: deletingPublicationId })).then(
      (res: any) => {
        if (res.payload.status === 200) {
          handleCloseConfirmationModal();
          toast.success(res.payload.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableTopActions}>
        <AdminButton onClick={() => setShowModal(true)}>
          <PlusIcon width="22px" height="22px" fill="#FFFFFF" />
          Create
        </AdminButton>
      </div>
      <div className={styles.tableBody}>
        {rowsData.length ? (
          <table>
            <thead>
              <tr>
                {headerColumns.map((col: any) => {
                  return <th key={uuidv4()}>{col}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {rowsData.map((row: any) => {
                const {
                  id,
                  title_en,
                  title_ar,
                  thumbnail_image,
                  content_en,
                  content_ar,
                  type,
                } = row;
                return (
                  <tr key={uuidv4()}>
                    <td>{id}</td>
                    <td>
                      <p>{title_en}</p>
                      <p>{title_ar}</p>
                    </td>
                    <td>
                      <img
                        src={`${REACT_APP_AMAZON_S3_URL}/${thumbnail_image}`}
                        alt="thumbnail_image"
                        width={60}
                      />
                    </td>
                    <td>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            content_en.length > 30
                              ? `${content_en.substring(0, 30)}...`
                              : content_en,
                        }}
                      />
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            content_ar.length > 30
                              ? `${content_ar.substring(0, 30)}...`
                              : content_ar,
                        }}
                      />
                    </td>
                    <td>{type}</td>
                    <td>
                      <div className={styles.actions}>
                        <div onClick={() => handleEditRowClick(row)}>
                          <EditIcon width="20px" height="20px" fill="#333333" />
                        </div>
                        <div onClick={() => handleDeleteRowClick(id)}>
                          <DeleteIcon
                            width="20px"
                            height="20px"
                            fill="#e52020"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}
      </div>
      <ConfirmationModal
        isOpen={showConfirmationModal}
        text="Are you sure you want to continue ?"
        onClose={handleCloseConfirmationModal}
        onClick={handleConfirmClick}
        onOutside
        disabled={isLoading}
      />
    </div>
  );
};

export default TableBody;
