import React from "react";
import { TIconProps } from "../../@types/icon";

const PlusIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
    >
      <path
        d="M100 50C101.658 50 103.247 50.6585 104.419 51.8306C105.592 53.0027 106.25 54.5924 106.25 56.25V93.75H143.75C145.408 93.75 146.997 94.4085 148.169 95.5806C149.342 96.7527 150 98.3424 150 100C150 101.658 149.342 103.247 148.169 104.419C146.997 105.592 145.408 106.25 143.75 106.25H106.25V143.75C106.25 145.408 105.592 146.997 104.419 148.169C103.247 149.342 101.658 150 100 150C98.3424 150 96.7527 149.342 95.5806 148.169C94.4085 146.997 93.75 145.408 93.75 143.75V106.25H56.25C54.5924 106.25 53.0027 105.592 51.8306 104.419C50.6585 103.247 50 101.658 50 100C50 98.3424 50.6585 96.7527 51.8306 95.5806C53.0027 94.4085 54.5924 93.75 56.25 93.75H93.75V56.25C93.75 54.5924 94.4085 53.0027 95.5806 51.8306C96.7527 50.6585 98.3424 50 100 50Z"
        fill={fill}
      />
    </svg>
  );
};

export default PlusIcon;
