import React from "react";
import styles from "./Pagination.module.scss";
import Button from "../Button/Button";

type TPaginationProps = {
  pageCount?: number | null;
  itemsPerPage: number;
  currentPage: number;
  showPageCount?: boolean;
  isShow?: boolean | null;
  disabled?: boolean;
  onLoadMore: () => void;
};

const Pagination: React.FC<TPaginationProps> = ({
  pageCount,
  itemsPerPage,
  currentPage,
  showPageCount,
  isShow,
  disabled,
  onLoadMore,
}) => {
  return (
    <div className={styles.pagination}>
      {showPageCount ? (
        <p>
          Page {currentPage} of {pageCount}
        </p>
      ) : null}
      {isShow ? (
        <Button disabled={disabled} onClick={onLoadMore}>
          Load More
        </Button>
      ) : null}
    </div>
  );
};

export default Pagination;
