import React, { useCallback, useEffect, useState } from "react";
import styles from "./Media.module.scss";
import Tabs from "../../components/Tab/Tabs";
import { TMediaProps } from "../../@types/type";
import EventCard from "../../components/EventCard/EventCard";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import NewsCard from "../../components/NewsCard/NewsCard";
import { t } from "i18next";
import Pagination from "../../components/Pagination/Pagination";
import { mediaList } from "../../app/api";
import Loading from "../../components/Loading/Loading";

type TabItem = {
  label: string;
  value: string;
};

const Media = () => {
  const dispatch: AppDispatch = useDispatch();
  const { selectedType } = useSelector((state: RootState) => state.tab);
  const [eventsData, setEventsData] = useState<TMediaProps[]>([]);
  const [newsData, setNewsData] = useState<TMediaProps[]>([]);
  const [eventsPage, setEventsPage] = useState(1);
  const [newsPage, setNewsPage] = useState(1);
  const { meta, isLoading } = useSelector(
    (state: RootState) => state.media
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [take, setTake] = useState(10);

  const tabs: TabItem[] = t("media_page.tab", { returnObjects: true });

  const handleLoadMore = () => {
    if (selectedType === "event") {
      setEventsPage(eventsPage + 1);
    } else {
      setNewsPage(newsPage + 1);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      await dispatch(
        mediaList({
          type: selectedType,
          page: selectedType === "event" ? eventsPage : newsPage,
          take,
        })
      ).then((res: any) => {
        if (selectedType === "event") {
          let cloneEventsData = [...eventsData];
          res.payload.response.data.data.data.forEach(
            (item: TMediaProps) => {
              cloneEventsData.push(item);
            }
          );
          cloneEventsData = cloneEventsData.filter(
            (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
          );
          setEventsData(cloneEventsData);
        } else {
          let cloneNewsData = [...newsData];
          res.payload.response.data.data.data.forEach(
            (item: TMediaProps) => {
              cloneNewsData.push(item);
            }
          );
          cloneNewsData = cloneNewsData.filter(
            (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
          );
          setNewsData(cloneNewsData);
        }
      });
    } catch (error: unknown) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType, eventsPage, newsPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={styles.mediaPage}>
      <div className={styles.tabs}>
        <Tabs tabs={tabs} save />
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.tabContent}>
          <div className="container">
            {selectedType === "event" ? (
              <div className={styles.events}>
                {eventsData
                  .flat(Infinity)
                  .map((itemEvent: TMediaProps) => {
                    return (
                      <EventCard
                        key={itemEvent.id}
                        {...itemEvent}
                        show={true}
                      />
                    );
                  })}
              </div>
            ) : (
              <div className={styles.news}>
                {newsData.flat(Infinity).map((itemNews: TMediaProps) => {
                  return (
                    <NewsCard key={itemNews.id} {...itemNews} show={true} />
                  );
                })}
              </div>
            )}
            <div className={styles.pagination}>
              <Pagination
                pageCount={meta && meta.pageCount}
                itemsPerPage={take}
                isShow={meta && meta.hasNextPage}
                currentPage={selectedType === "event" ? eventsPage : newsPage}
                onLoadMore={handleLoadMore}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Media;
