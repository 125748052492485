import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AdminSidebar from '../Admin/Layouts/Sidebar/Sidebar';
import AdminHeader from '../Admin/Layouts/Header/Header';

const DashboardLayout = () => {
    const [openSidebar, setOpenSidebar] = useState<boolean>(false);

    return (
        <div dir='ltr'>
            <AdminHeader isOpen={openSidebar} setOpenSidebar={setOpenSidebar} />
            <AdminSidebar isOpen={openSidebar} setOpenSidebar={setOpenSidebar} />
            <div className='dashboard-layout'>
                <Outlet />
            </div>
        </div>
    )
}

export default DashboardLayout;
