import React, { ChangeEvent, Dispatch, useEffect, useRef, useState } from "react";
import styles from "../JoinUs.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../components/InputField/InputField";
import { RootState } from "../../../app/store";
import {
  setError,
  setUserDetails,
} from "../../../app/features/joinUsSlice";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import { TContactDetailsFormProps } from "../../../@types/type";
import Uploadicon from "../../../components/icons/admin/Uploadicon";
import { attachmentsFilesFormat } from "../../../utils/formats";
import Button from "../../../components/Button/Button";

type TConatactDetailsProps = {
  setIsCardsValid: Dispatch<React.SetStateAction<boolean>>;
};

const ContactDetailsCard: React.FC<TConatactDetailsProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setIsCardsValid } = props;
  const { userDetails } = useSelector(
    (state: RootState) => state.joinUs
  );
  const [contactErrors, setContactErrors] = useState<
    Partial<TContactDetailsFormProps>
  >({
    phone: "",
    email: "",
    confirmEmail: "",
  });
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const strFile: any = userDetails.profilePicture;

  const handleContactInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    const updatedUserDetails = { ...userDetails, [id]: value };

    // Validate email
    if (id === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = value.match(emailRegex);
      if (updatedUserDetails.confirmEmail && value !== updatedUserDetails.confirmEmail) {
        setContactErrors((prevErrors) => ({
          ...prevErrors,
          confirmEmail: t("join_us.errors.confirm_email_text"),
        }));
      } else {
        setContactErrors((prevErrors) => ({
          ...prevErrors,
          confirmEmail: "",
        }));
      }
      setContactErrors((prevErrors) => ({
        ...prevErrors,
        email: isValidEmail ? "" : t("join_us.errors.email_text"),
      }));
    } else if (id === "confirmEmail") {
      const isValidEmail = updatedUserDetails.email === value
      setContactErrors((prevErrors) => ({
        ...prevErrors,
        confirmEmail: isValidEmail ? "" : t("join_us.errors.confirm_email_text"),
      }));
    }

    dispatch(setUserDetails({ key: id, value }));
  };

  const validatePhoneNumber = (
    phoneNumber: string,
    countryCode: CountryCode
  ): boolean => {
    try {
      const parsedNumber = parsePhoneNumber(`+${phoneNumber}`, countryCode);
      return parsedNumber.isValid();
    } catch (error: any) {
      return false;
    }
  };

  const handlePhoneChange = (phone: string, country: CountryData) => {
    dispatch(setUserDetails({ key: "phone", value: phone }));
    const countryCode: any = country.countryCode as CountryCode;
    const isValid = validatePhoneNumber(phone, countryCode.toUpperCase());

    if (!isValid) {
      setContactErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Invalid phone number",
      }));
    } else {
      setContactErrors((prevErrors) => ({
        ...prevErrors,
        phone: "",
      }));
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setSelectedFile(file);
      dispatch(
        setUserDetails({
          key: "profilePicture",
          value: event.target.files?.[0],
        })
      );
    }
  };

  const handleResetFile = () => {
    setSelectedFile(null);
    dispatch(
      setUserDetails({
        key: "profilePicture",
        value: null,
      })
    );
    const fileInput = inputRefs.current[0];
    if (fileInput) {
      fileInput.value = "";
    }
  };

  useEffect(() => {
    const hasAnyError = Object.values(contactErrors).every((value) => {
      return !value;
    });

    const isFormEmpty = Object.values(userDetails).every((value) => {
      if (typeof value !== "boolean") {
        return value;
      }
      return true;
    });

    if (!isFormEmpty) {
      dispatch(setError(hasAnyError));
    } else {
      dispatch(setError(hasAnyError));
    }

    const isFormValid = isFormEmpty && hasAnyError;

    setIsCardsValid(isFormValid);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactErrors, userDetails]);

  return (
    <div>
      <div className={styles.constactDetails}>
        <div className={styles.sectionContent}>
          <div className={styles.inputField}>
            <InputField
              id="firstName"
              value={userDetails?.firstName}
              onChange={handleContactInputChange}
              type="string"
              label={t("join_us.first_name.label_text")}
              required
              placeholder={t("join_us.first_name.placeholder_text")}
            />
          </div>
          <div className={styles.inputField}>
            <InputField
              id="lastName"
              value={userDetails?.lastName!}
              onChange={handleContactInputChange}
              type="string"
              label={t("join_us.last_name.label_text")}
              required
              placeholder={t("join_us.last_name.placeholder_text")}
            />
          </div>
          <div className={styles.inputField}>
            <InputField
              id="email"
              value={userDetails.email}
              onChange={handleContactInputChange}
              type="email"
              label={t("join_us.email.label_text")}
              required
              placeholder={t("join_us.email.placeholder_text")}
            />
            {contactErrors.email && (
              <div className={styles.error}>{contactErrors.email}</div>
            )}
          </div>
          <div className={styles.inputField}>
            <InputField
              id="confirmEmail"
              value={userDetails.confirmEmail}
              onChange={handleContactInputChange}
              type="email"
              label={t("join_us.confirmEmail.label_text")}
              required
              placeholder={t("join_us.confirmEmail.placeholder_text")}
            />
            {contactErrors.confirmEmail && (
              <div className={styles.error}>{contactErrors.confirmEmail}</div>
            )}
          </div>
          <div className={styles.inputField}>
            <div className={styles.checkboxTitle}>
              <span>{t("join_us.phone.label_text")}</span>
              <span className={styles.required}>*</span>
            </div>
            <PhoneInput
              country={"ae"}
              placeholder={t("join_us.phone.placeholder_text")}
              enableSearch={true}
              value={userDetails.phone}
              onChange={(phone: string, country: CountryData) =>
                handlePhoneChange(phone, country)
              }
            />
            {contactErrors.phone && (
              <div className={styles.error}>{contactErrors.phone}</div>
            )}
          </div>
        </div>
        <div className={`${styles.upload} ${styles.fullWidth}`}>
          <div className={styles.upladedFiles}>
            {userDetails.profilePicture && !selectedFile ? (
              <div className={styles.file}>
                <div className={styles.removeFile} onClick={handleResetFile}>
                  x
                </div>
                {attachmentsFilesFormat(strFile)?.fileName}
              </div>
            ) : selectedFile ? (
              <div className={styles.file}>
                <div className={styles.removeFile} onClick={handleResetFile}>
                  x
                </div>
                {selectedFile.type.startsWith("image/") ? (
                  <img src={URL.createObjectURL(selectedFile)} alt="Uploaded" />
                ) : (
                  <p>{selectedFile.name}</p>
                )}
              </div>
            ) : (
              <div
                className={styles.uploadFile}
                onClick={() => inputRefs.current[0].click()}
              >
                <Uploadicon width="30px" height="30px" fill="#333333" />
                <p>{t("join_us.upload-profile")}</p>
              </div>
            )}
          </div>
          <input
            ref={(el) => {
              inputRefs.current[0] = el!;
            }}
            type="file"
            id="fileInput"
            accept="image/*"
            name="fileInput"
            onChange={handleFileChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactDetailsCard;
