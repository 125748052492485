import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import { TMetaProps, TNewsletterProps } from "../../@types/type";
import {
  createNewsletter,
  deleteNewsletterById,
  newsLetterList,
  updateNewsletterById,
} from "../api";

type TNewsletterState = {
  newslettersList: TNewsletterProps[];
  meta: TMetaProps | null;
  isError: boolean;
  isLoading: boolean;
  fetchAgain: boolean;
};

const initialState: TNewsletterState = {
  newslettersList: [],
  meta: null,
  isError: false,
  isLoading: false,
  fetchAgain: true,
};

const newsletterSlice = createSlice({
  name: "newsletter",
  initialState,
  reducers: {
    updateNewsletterFetchAgain: (state) => {
      state.fetchAgain = true;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    // create
    builder
      .addCase(createNewsletter.pending, (state: TNewsletterState) => {
        state.isLoading = true;
      })
      .addCase(
        createNewsletter.fulfilled,
        (state: TNewsletterState, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.fetchAgain = true;
          if (action.payload.status === 201) {
            let cloneNewsletterData = [...state.newslettersList];
            cloneNewsletterData.unshift(action.payload.data.newsletter);
            state.newslettersList = cloneNewsletterData;
          }
        }
      )
      .addCase(createNewsletter.rejected, (state: TNewsletterState) => {
        state.isLoading = false;
        state.isError = true;
      });

    // delete
    builder
      .addCase(deleteNewsletterById.pending, (state: TNewsletterState) => {
        state.isLoading = true;
      })
      .addCase(
        deleteNewsletterById.fulfilled,
        (state: TNewsletterState, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.fetchAgain = true;
        }
      )
      .addCase(deleteNewsletterById.rejected, (state: TNewsletterState) => {
        state.isLoading = false;
        state.isError = true;
      });

    // list
    builder
      .addCase(newsLetterList.pending, (state: TNewsletterState) => {
        state.isLoading = true;
      })
      .addCase(
        newsLetterList.fulfilled,
        (state: TNewsletterState, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.fetchAgain = false;
          const { data, status } = action.payload;
          if (status === 200) {
            state.newslettersList = data.data.data;
            state.meta = data.data.meta;
          }
        }
      )
      .addCase(newsLetterList.rejected, (state: TNewsletterState) => {
        state.isLoading = false;
        state.isError = true;
      });

    //update
    builder
      .addCase(updateNewsletterById.pending, (state: TNewsletterState) => {
        state.isLoading = true;
      })
      .addCase(
        updateNewsletterById.fulfilled,
        (state: TNewsletterState, action: PayloadAction<any>) => {
          state.isLoading = false;
          const { status, data } = action.payload;
          if (status === 200) {
            let cloneNewsletterData = [...state.newslettersList];
            let updatedNewsletterDataIndex = cloneNewsletterData.findIndex(
              (elm) => elm.id === data.data.id
            );
            cloneNewsletterData[updatedNewsletterDataIndex] = data.data;
            state.newslettersList = cloneNewsletterData;
          }
        }
      )
      .addCase(updateNewsletterById.rejected, (state: TNewsletterState) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { updateNewsletterFetchAgain } = newsletterSlice.actions;
export default newsletterSlice.reducer;
