import React from "react";
import { TMediaProps, TTabProps } from "../../@types/type";
import styles from "./NewsCard.module.scss";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumb } from "../../app/features/breadcrumbSlice";
import { RootState } from "../../app/store";
import { capitalizeFirstLetter, fDateTime } from "../../utils/formats";
import { REACT_APP_AMAZON_S3_URL } from "../../constants/constant";

const NewsCard = (props: TMediaProps) => {
  const { id } = props;
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedType } = useSelector((state: RootState) => state.tab);
  const tabs: TTabProps[] = t("media_page.tab", { returnObjects: true });

  const truncatedBody =
    props[`body_${i18n.language}` as keyof TMediaProps];

  const contentToDisplay =
    typeof truncatedBody === "string" ? truncatedBody : String(truncatedBody);

  const handleNewsDetailsClick = (id: number) => {
    let name: string = "";
    tabs.forEach((value) => {
      if (value.value === selectedType) {
        name = value.label;
      }
    });
    dispatch(
      setBreadcrumb({
        name: capitalizeFirstLetter(name),
        key: selectedType,
      })
    );
    navigate(`/media/${id}`);
  };

  return (
    <div
      className={`${styles.newsCardBody} ${
        props.show ? styles.isShowCard : ""
      }`}
    >
      <div className={styles.cardImg}>
        <img src={`${REACT_APP_AMAZON_S3_URL}/${props.image}`} alt="news_img" />
        {props.show ? (
          <div className={styles.imgDate}>
            {fDateTime({
              date: new Date(props.date_time),
              newFormat: "MMMM d, yyyy",
            })}
          </div>
        ) : null}
      </div>
      <div className={styles.cardInfo}>
        {props.show ? (
          <div className={styles.cardTitle}>
            {props[`title_${i18n.language}` as keyof TMediaProps]}
          </div>
        ) : null}
        {!props.show ? (
          <div className={styles.cardDate}>
            {fDateTime({
              date: new Date(props.date_time),
              newFormat: "MMMM d, yyyy",
            })}
          </div>
        ) : null}
        <div
          className={styles.cardBody}
          dangerouslySetInnerHTML={{
            __html:
              contentToDisplay.length > 100
                ? `${contentToDisplay.substring(0, 100)}...`
                : contentToDisplay,
          }}
        />
        {props.show ? (
          <div className={styles.btnMore}>
            <span onClick={() => id !== null && handleNewsDetailsClick(id)}>
              {t("more_btn_text")}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default NewsCard;
