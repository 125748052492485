import React from "react";
import { TIconProps } from "../../@types/icon";

const SuccessIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 256 256"
      fill="none"
    >
      <g id="Black">
        <path
          id="Vector"
          d="M114 0.600045C93.3 3.20005 73.3 10.7 56 22.3C46.4 28.7 28.7 46.4 22.3 56C12.8 70.2 6 86.2 2.4 103C-0.200003 114.9 -0.200003 141.1 2.4 153C8 178.9 19.1 199.3 37.9 218.1C56.7 236.9 77.1 248 103 253.6C114.9 256.2 141.1 256.2 153 253.6C178.9 248 199.3 236.9 218.1 218.1C236.6 199.6 247.5 180 253.1 155C258.2 132.2 255.4 95.2 248 87.8C242.4 82.3 232.3 85 230.4 92.5C230 94.1 230.5 98.3 231.5 102.4C238.6 131.9 233 163 216 188C194.5 219.6 154.6 238.3 117.9 234C99.1 231.9 82.5 225.8 68 216C36.6 194.6 17.9 155 21.9 118.2C24.8 92.1 35.9 68.9 53.9 51.7C80.6 26.2 115.1 16.4 151.2 24C162 26.3 171.1 29.9 182.1 36.1C186.9 38.8 191.9 41 193.4 41C202.7 41 207.2 30.8 201.1 23.7C195.1 17 171 6.30005 153 2.40005C145.7 0.900045 121 -0.299955 114 0.600045Z"
          fill={fill}
        />
        <path
          id="Vector_2"
          d="M242 21.7001C241.2 22.0001 215.2 47.5001 184.3 78.3001L128 134.5L108.7 115.2C91.8 98.4001 89 96.0001 86.2 96.0001C79.2 96.0001 75 100.4 75 107.7C75 111.3 76.1 112.6 98.8 135.2C122.9 159.3 125.1 160.9 130.8 159.5C133.8 158.8 252 41.6001 254.6 36.8001C255.4 35.3001 256 33.2001 256 32.0001C256 25.3001 248.1 19.5001 242 21.7001Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default SuccessIcon;
