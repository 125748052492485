import React from 'react'
import { TIconProps } from '../../@types/icon'

const EmailIcon = ({ fill, width, height }: TIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 18" fill="none">
            <path
                d="M22 2.79907C22 1.63032 21.01 0.674072 19.8 0.674072H2.2C0.99 0.674072 0 1.63032 0 2.79907V15.5491C0 16.7178 0.99 17.6741 2.2 17.6741H19.8C21.01 17.6741 22 16.7178 22 15.5491V2.79907ZM19.8 2.79907L11 8.11157L2.2 2.79907H19.8ZM19.8 15.5491H2.2V4.92407L11 10.2366L19.8 4.92407V15.5491Z"
                fill={fill}
            />
        </svg>
    )
}

export default EmailIcon
