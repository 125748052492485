import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./Publication.module.scss";
import ModalBody from "./ModalBody/ModalBody";
import TableBody from "./TableBody/TableBody";
import Modal from "../../../components/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import {
  createPublication,
  publicationList,
  publicationUpdateById,
} from "../../../app/api";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import ArrowRightIcon from "../../../components/icons/ArrowRightIcon";
import ArrowLeftIcon from "../../../components/icons/ArrowLeftIcon";
import { updatePublicationFetchAgain } from "../../../app/features/publicationSlice";
import Loading from "../../../components/Loading/Loading";

type TPublicationFormDataProps = {
  engTitle: string;
  arTitle: string;
  engContent: string;
  arContent: string;
  type: string;
};

const headerColumns = [
  "ID",
  "Title",
  "Thumbnail Image",
  "Content",
  "Type",
  "Actions",
];

const Publication = () => {
  const dispatch: AppDispatch = useDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const inputPubFilesRefs = useRef<HTMLInputElement[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [take, setTake] = useState<number>(4);
  const [isPublicationFieldValid, setisPublicationFieldValid] =
    useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [multiples, setMultiples] = useState<File[]>([]);
  const [attachments, setAttachments] = useState<string[]>([]);
  const [isActiveId, setIsActiveId] = useState<number | null>(null);
  const [publicationForm, setPublicationForm] =
    useState<TPublicationFormDataProps>({
      engTitle: "",
      arTitle: "",
      engContent: "",
      arContent: "",
      type: "article",
    });
  const { publicationsList, isLoading, fetchAgain, meta } = useSelector(
    (state: RootState) => state.publication
  );

  const { pageCount }: any = meta ?? {};

  const handlePageClick = (newPage: any) => {
    setPage(newPage.selected + 1);
    dispatch(updatePublicationFetchAgain());
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    if (id === "articleId" || id === "studyId") {
      setPublicationForm({ ...publicationForm, type: value });
    } else {
      setPublicationForm({ ...publicationForm, [id]: value });
    }
  };

  const handleCKEditorChange = (id: string, data: string) => {
    setPublicationForm({ ...publicationForm, [id]: data });
  };

  const handleUpload = () => {
    inputRefs.current[0].click();
  };

  const handleMediaChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setSelectedImage(file);
    }
  };

  const handleMultipleMediaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles: File[] = [];

    if (inputPubFilesRefs.current.length > 0) {
      inputPubFilesRefs.current.forEach((inputRef) => {
        const files = inputRef.files;
        if (files) {
          const newFiles = Array.from(files);
          const uniqueNewFiles = newFiles.filter(
            (file) =>
              !selectedFiles.some(
                (selectedFile) => selectedFile.name === file.name
              )
          );
          selectedFiles.push(...uniqueNewFiles);
        }
      });

      setMultiples(selectedFiles);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setIsEdit(false);
    setSelectedImage(null);
    setMultiples([]);
    setPublicationForm({
      engTitle: "",
      arTitle: "",
      engContent: "",
      arContent: "",
      type: "article",
    });
  };

  const handelRemoveAttachment = (index: number, type: any) => {
    if (type === "file") {
      const updatedatFiletAchment = [...multiples];
      updatedatFiletAchment.splice(index, 1);
      setMultiples(updatedatFiletAchment);
    } else {
      const updatedatStringtAchment = [...attachments];
      updatedatStringtAchment.splice(index, 1);
      setAttachments(updatedatStringtAchment);
    }
  };

  const handleEditRowClick = (row: any) => {
    setIsEdit(true);
    setShowModal(true);
    setSelectedImage(row.thumbnail_image);
    setAttachments(row.attachments);
    setIsActiveId(row.id);
    setPublicationForm({
      engTitle: row.title_en,
      arTitle: row.title_ar,
      engContent: row.content_en,
      arContent: row.content_ar,
      type: row.type,
    });
  };

  const handleEditClick = async () => {
    const formData: any = new FormData();
    const thumbnail_image: File | null = selectedImage;
    const files: File[] = multiples;
    const data = {
      title_en: publicationForm.engTitle,
      title_ar: publicationForm.arTitle,
      content_en: publicationForm.engContent,
      content_ar: publicationForm.arContent,
      type: publicationForm.type,
    };

    if (thumbnail_image !== null) {
      formData.append("thumbnail_image", thumbnail_image);
    }
    if (files.length) {
      files.forEach((file) => formData.append("files", file));
    }
    if (attachments.length) {
      attachments.forEach((item) => formData.append("attachments[]", item));
    }
    formData.append("title_en", data.title_en);
    formData.append("title_ar", data.title_ar);
    formData.append("content_en", data.content_en);
    formData.append("content_ar", data.content_ar);
    formData.append("type", data.type);

    const query = {
      id: isActiveId,
      data: formData,
    };

    await dispatch(publicationUpdateById(query)).then((res: any) => {
      if (res.payload.status === 200) {
        setShowModal(false);
        setMultiples([]);
        toast.success(res.payload.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleCreateClick = async () => {
    const formData: any = new FormData();
    const thumbnail_image: File | null = selectedImage;
    const attachments: File[] = multiples;
    const data = {
      title_en: publicationForm.engTitle,
      title_ar: publicationForm.arTitle,
      content_en: publicationForm.engContent,
      content_ar: publicationForm.arContent,
      type: publicationForm.type,
    };
    if (thumbnail_image !== null) {
      formData.append("thumbnail_image", thumbnail_image);
    }
    if (attachments.length) {
      attachments.forEach((file) => formData.append("files", file));
    }
    formData.append("title_en", data.title_en);
    formData.append("title_ar", data.title_ar);
    formData.append("content_en", data.content_en);
    formData.append("content_ar", data.content_ar);
    formData.append("type", data.type);

    await dispatch(createPublication(formData)).then((res: any) => {
      if (res.payload.status === 201) {
        setShowModal(false);
        setSelectedImage(null);
        setMultiples([]);
        setPublicationForm({
          engTitle: "",
          arTitle: "",
          engContent: "",
          arContent: "",
          type: "article",
        });
        toast.success(res.payload.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const fetchPublicationData = useCallback(async () => {
    try {
      await dispatch(publicationList({ page, take })).then((res: any) => {
        if (res.payload.status === 200) {
          setIsFetching(true);
        }
      });
    } catch (error: unknown) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (fetchAgain) {
      fetchPublicationData();
    }
  }, [fetchPublicationData, fetchAgain]);

  useEffect(() => {
    if (publicationsList.length) {
      setIsFetching(true);
    }
  }, [publicationsList]);

  useEffect(() => {
    const isFormEmpty = Object.values(publicationForm).some((value) => !value);
    const isImageValid = selectedImage !== null && selectedImage;

    setisPublicationFieldValid(isFormEmpty || !isImageValid);
  }, [publicationForm, selectedImage]);

  return (
    <div className={`container ${styles.publication}`}>
      <h2>Publication</h2>
      <TableBody
        setShowModal={setShowModal}
        rowsData={publicationsList}
        headerColumns={headerColumns}
        handleEditRowClick={handleEditRowClick}
      />
      {!isFetching ? (
        <Loading />
      ) : (
        <Modal
          isOpen={showModal}
          btnText={isEdit ? "Edit" : "Create"}
          onClose={handleModalClose}
          onClick={!isEdit ? handleCreateClick : handleEditClick}
          disabled={isPublicationFieldValid || isLoading}
          onOutside
          type="admin"
        >
          <ModalBody
            publicationForm={publicationForm}
            handleInputChange={handleInputChange}
            handleUpload={handleUpload}
            selectedImage={selectedImage}
            inputRefs={inputRefs}
            handleMediaChange={handleMediaChange}
            handleCKEditorChange={handleCKEditorChange}
            inputPubFilesRefs={inputPubFilesRefs}
            multiples={multiples}
            attachments={attachments}
            handelRemoveAttachment={handelRemoveAttachment}
            handleMultipleMediaChange={handleMultipleMediaChange}
          />
        </Modal>
      )}
      {meta ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <ArrowRightIcon fill="#2065D1" width="30px" height="30px" />
          }
          onPageChange={(page) => handlePageClick(page)}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={
            <ArrowLeftIcon fill="#2065D1" width="30px" height="30px" />
          }
          renderOnZeroPageCount={null}
          className="pagination"
          previousClassName="prev"
        />
      ) : null}
    </div>
  );
};

export default Publication;
