import React from "react";
import styles from "./MenuOption.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { TMenuDataProps, TMenuProps } from "../../../@types/type";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { logout } from "../../../app/features/authSlice";

const MENU_OPTIONS: TMenuDataProps[] = [
  {
    title: "Home",
    path: "/dashboard/app",
  },
];

const MenuOption: React.FC<TMenuProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useOutsideClick(() => {
    setOpen(false);
  });

  const handleLogoutClick = () => {
    dispatch(logout());
    navigate("sign-in");
  };

  return (
    <div ref={ref} className={styles.menuOption}>
      {open && (
        <div className={styles.optionContent}>
          <div className={styles.header} />
          <div className={styles.options}>
            {MENU_OPTIONS.map((item: TMenuDataProps) => {
              return (
                <Link key={uuidv4()} to={item.path}>
                  {item.title}
                </Link>
              );
            })}
          </div>
          <div className={styles.footer} onClick={handleLogoutClick}>
            <span>Logout</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuOption;
