import React, { useCallback, useEffect, useState } from "react";
import styles from "./Role.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { createRole, roleList } from "../../../app/api";
import TableBody from "./TableBody/TableBody";
import Modal from "../../../components/Modal/Modal";
import ModalBody from "./ModalBody/ModalBody";
import Loading from "../../../components/Loading/Loading";

const headerColumns = ["ID", "Name"];

const Role = () => {
  const dispatch: AppDispatch = useDispatch();
  const [roleName, setRoleName] = useState<string>("");
  const [isFieldValid, setIsFieldValid] = useState<boolean>(false);
  const [isRoleFetching, setIsRoleFetching] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [take, setTake] = useState(10);
  const { roleAllList, isLoading } = useSelector(
    (state: RootState) => state.role
  );

  const handleCreateClick = async () => {
    await dispatch(createRole({ name: roleName })).then((res: any) => {
      if (res.payload.status === 201) {
        setRoleName("");
        setShowModal(false);
      }
    });
  };

  const allRoleFetchingData = useCallback(async () => {
    try {
      await dispatch(roleList({ take, page })).then((res: any) => {
        if (res.payload.status === 200) {
          setIsRoleFetching(true);
        }
      });
    } catch (error: unknown) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    allRoleFetchingData();
  }, [allRoleFetchingData]);

  useEffect(() => {
    if (roleAllList.length) {
      setIsRoleFetching(true);
    }
  }, [roleAllList]);

  useEffect(() => {
    setIsFieldValid(roleName !== "");
  }, [roleName]);

  return (
    <div className={`container ${styles.roleList}`}>
      <h2>Roles</h2>
      <TableBody
        setShowModal={setShowModal}
        rowsData={roleAllList}
        headerColumns={headerColumns}
      />
      {!isRoleFetching ? (
        <Loading />
      ) : (
        <Modal
          isOpen={showModal}
          btnText="Create"
          onClose={() => setShowModal(false)}
          onClick={handleCreateClick}
          disabled={!isFieldValid || isLoading}
          onOutside
          type="admin"
        >
          <ModalBody roleName={roleName} setRoleName={setRoleName} />
        </Modal>
      )}
    </div>
  );
};

export default Role;
