import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type TTabState = {
  item: any;
};

const initialState: TTabState = {
  item: null,
};

const itemEditSlice = createSlice({
  name: "itemEdit",
  initialState,
  reducers: {
    setItemEdit: (state, action: PayloadAction<any>) => {
      state.item = action.payload;
    },
  },
  extraReducers: (builder: unknown) => {},
});

export const { setItemEdit } = itemEditSlice.actions;

export default itemEditSlice.reducer;
