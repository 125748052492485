import React, { Fragment, useCallback, useEffect, useState } from "react";
import styles from "./AboutUs.module.scss";
import { useTranslation } from "react-i18next";
import VisionIcon from "../../components/icons/VisionIcon";
import MissionIcon from "../../components/icons/MissionIcon";
import useScreenSize from "../../hooks/useScreenSize";
import Accordion from "../../components/Accordion/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { aboutUsList } from "../../app/api";
import { TAboutUsApiProps } from "../../@types/type";
import Loading from "../../components/Loading/Loading";

const AboutUsPage = () => {
  const { t, i18n } = useTranslation();
  const { width } = useScreenSize();
  const dispatch: AppDispatch = useDispatch();
  const [active, setActive] = useState<number>(0);
  const [openAccardion, setopenAccardion] = useState<number | null>(null);
  const { aboutUsData, isLoading } = useSelector(
    (state: RootState) => state.aboutUs
  );

  const aboutDesc =
    aboutUsData &&
    typeof aboutUsData[
      `establishment_${i18n.language}` as keyof TAboutUsApiProps
    ] === "string"
      ? (
          aboutUsData[
            `establishment_${i18n.language}` as keyof TAboutUsApiProps
          ] as string
        ).split(". ")
      : [];

  const handleClick = (index: number) => {
    setActive(index);
  };

  const handleItemClick = (index: number) => {
    setopenAccardion((prevIndex) => (prevIndex === index ? null : index));
  };

  const aboutUs = useCallback(async () => {
    try {
      await dispatch(aboutUsList());
    } catch (error: unknown) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    aboutUs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aboutUs]);

  return (
    <div className={`container ${styles.aboutUsPage}`}>
      <h2 className={styles.header}>{t("about_us.title_text")}</h2>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.body}>
          {width > 768 ? (
            <Fragment>
              <div className={styles.aboutUsLinks}>
                <div>
                  <p
                    onClick={() => handleClick(0)}
                    className={active === 0 ? styles.active : ""}
                  >
                    {t("about_us.about.title_text")}
                  </p>
                  <p
                    onClick={() => handleClick(1)}
                    className={active === 1 ? styles.active : ""}
                  >
                    {t("about_us.vision_mission.title_text")}
                  </p>
                </div>
              </div>
              <div className={styles.linksDescription}>
                {active === 0 && (
                  <div className={styles.itemDesc}>
                    <p
                      className={styles.about}
                      dangerouslySetInnerHTML={{
                        __html: aboutDesc[0],
                      }}
                    />
                    <p
                      className={styles.about}
                      dangerouslySetInnerHTML={{
                        __html: aboutDesc[1],
                      }}
                    />
                  </div>
                )}
                {active === 1 && (
                  <div className={styles.visionMission}>
                    <div>
                      <div className={styles.icon}>
                        <VisionIcon fill="#EC1C2B" width="60px" height="60px" />
                      </div>
                      <div className={styles.ietmVision}>
                        <p>{t("about_us.vision_mission.vision.title_text")}</p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              (aboutUsData &&
                                aboutUsData[
                                  `vision_${i18n.language}` as keyof TAboutUsApiProps
                                ]?.toString()) ??
                              "",
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className={styles.icon}>
                        <MissionIcon
                          fill="#EC1C2B"
                          width="60px"
                          height="60px"
                        />
                      </div>
                      <div className={styles.ietmVision}>
                        <p>{t("about_us.vision_mission.mission.title_text")}</p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              (aboutUsData &&
                                aboutUsData[
                                  `mission_${i18n.language}` as keyof TAboutUsApiProps
                                ]?.toString()) ??
                              "",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <Accordion
                question={t("about_us.about.title_text")}
                answer={
                  (aboutUsData &&
                    aboutUsData[
                      `establishment_${i18n.language}` as keyof TAboutUsApiProps
                    ]?.toString()) ??
                  ""
                }
                split
                isOpen={openAccardion === 0}
                onClick={() => handleItemClick(0)}
              />
              <Accordion
                question={t("about_us.vision_mission.title_text")}
                answer={[
                  {
                    title: t("about_us.vision_mission.vision.title_text"),
                    icon: (
                      <VisionIcon fill="#EC1C2B" width="40px" height="40px" />
                    ),
                    desc:
                      (aboutUsData &&
                        aboutUsData[
                          `vision_${i18n.language}` as keyof TAboutUsApiProps
                        ]?.toString()) ??
                      "",
                  },
                  {
                    title: t("about_us.vision_mission.mission.title_text"),
                    icon: (
                      <MissionIcon fill="#EC1C2B" width="40px" height="40px" />
                    ),
                    desc:
                      (aboutUsData &&
                        aboutUsData[
                          `mission_${i18n.language}` as keyof TAboutUsApiProps
                        ]?.toString()) ??
                      "",
                  },
                ]}
                isOpen={openAccardion === 1}
                onClick={() => handleItemClick(1)}
              />
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default AboutUsPage;
