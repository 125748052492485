import React from 'react';
import { TIconProps } from '../../@types/icon';

const HrefIcon = ({ fill, width, height }: TIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 13" fill="none">
            <path
                d="M1.29231 12.5L0 11.2077L8.86154 2.34615H0.923077V0.5H12V11.5769H10.1538V3.63846L1.29231 12.5Z"
                fill={fill}
            />
        </svg>
    )
}

export default HrefIcon;
