import InputField from "../../../../components/InputField/InputField";
import styles from "./ModalBody.module.scss";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DateTimePicker from "react-datetime-picker";

const ModalBody = (props: any) => {
  const {
    newsletterForm,
    handleInputChange,
    setShipDate,
    shipDate,
    tomorrow,
    handleCKEditorChange,
  } = props;

  return (
    <div className={styles.newsletterContent}>
      <div className={styles.title}>
        <InputField
          id="title"
          value={newsletterForm.title}
          onChange={handleInputChange}
          type="string"
          label="Title (English)"
          required
          placeholder="Enter title"
          forAdmin
        />
      </div>
      <div>
        <DateTimePicker
          onChange={(val) => setShipDate(val)}
          value={shipDate}
          minDate={tomorrow}
          format={"y-MM-dd h:mm a"}
          className={styles.dateTimePicker}
          // calendarClassName={styles.dateTimePicker}
        />
      </div>
      <div className={styles.content}>
        <p className={styles.editorTitle}>
          Content (English)<span>*</span>
        </p>
        <div>
          <CKEditor
            id="body"
            editor={ClassicEditor}
            config={{
              toolbar: [
                "heading",
                "|",
                "bold",
                "italic",
                "|",
                "bulletedList",
                "numberedList",
                "|",
                "|",
                "|",
                "|",
                "undo",
                "redo",
              ],
            }}
            data={newsletterForm?.body || ""}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleCKEditorChange("body", data);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalBody;
