import React, { Dispatch } from "react";
import styles from "./TableBody.module.scss";
import AdminButton from "../../../../components/AdminButton/AdminButton";
import PlusIcon from "../../../../components/icons/PlusIcon";
import { v4 as uuidv4 } from "uuid";

type TableBodyProps = {
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
  rowsData: Array<unknown>;
  headerColumns: Array<unknown>;
};

const TableBody = ({
  setShowModal,
  rowsData,
  headerColumns,
}: TableBodyProps) => {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableTopActions}>
        <AdminButton onClick={() => setShowModal(true)}>
          <PlusIcon width="22px" height="22px" fill="#FFFFFF" />
          Create
        </AdminButton>
      </div>
      <div className={styles.tableBody}>
        {rowsData.length ? (
          <table>
            <thead>
              <tr>
                {headerColumns.map((col: any) => {
                  return <th key={uuidv4()}>{col}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {rowsData.map((row: any) => {
                const { id, name } = row;
                return (
                  <tr key={uuidv4()}>
                    <td>{id}</td>
                    <td>{name}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
};

export default TableBody;
