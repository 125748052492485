import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import NotificationIcon from "../../../components/icons/admin/NotificationIcon";
import Logo from "../../../assets/images/avatars/avatar_1.jpg";
import MenuOption from "../../components/MenuOption/MenuOption";
import HamburgerIcon from "../../../components/icons/HamburgerIcon";
import { TSidebarProps } from "../../../@types/type";
import useScreenSize from "../../../hooks/useScreenSize";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { useLocation } from "react-router-dom";
import { getCurrentUser } from "../../../app/api";

const AdminHeader: React.FC<TSidebarProps> = ({ isOpen, setOpenSidebar }) => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const { width } = useScreenSize();
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getCurrentUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div
      className={`${styles.adminHeader} ${width < 768 ? styles.closed : ""}`}
    >
      <div className={styles.headerBody}>
        <div className={styles.searchContent}>
          <div className={styles.hamburgerMenu}>
            <div
              className={styles.hamburgerIcon}
              onClick={() => setOpenSidebar(!isOpen)}
            >
              <HamburgerIcon width="24px" height="24px" fill="#333333" />
            </div>
          </div>
        </div>
        <div className={styles.accountContent}>
          <div className={styles.notification}>
            <NotificationIcon width="25px" height="25px" fill="#333333" />
            <div className={styles.count}>2</div>
          </div>
          <div className={styles.avatar} onClick={() => setOpenMenu(true)}>
            <img src={Logo} alt="logo" />
          </div>
          <MenuOption open={openMenu} setOpen={setOpenMenu} />
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
