import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { IPieChartData } from "../../../@types/dashboard";
import styles from "./PieChart.module.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

type PieChartProps = {
  data: IPieChartData;
};

const PieChart = ({ data }: PieChartProps) => {
  return (
    <div className={styles.chartContainer}>
      <Pie data={data}></Pie>
    </div>
  );
};

export default PieChart;
