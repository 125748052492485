import { ActionReducerMapBuilder, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { createEventNews, eventNewsDeleteById, eventNewsFindById, eventsNewsList, eventNewsUpdateById } from "../api";
import { TEventsNewsCardProps, TMetaProps } from "../../@types/type";

type TEventsNewsState = {
    eventsNewsData: TEventsNewsCardProps[];
    eventNewsDetails: TEventsNewsCardProps | null,
    isError: boolean;
    isLoading: boolean;
    fetchAgain: boolean;
    meta: TMetaProps | null
}

const initialState: TEventsNewsState = {
    eventsNewsData: [],
    eventNewsDetails: null,
    isError: false,
    isLoading: false,
    fetchAgain: true,
    meta: null
};

const eventNewsSlice = createSlice({
    name: 'event_news',
    initialState,
    reducers: {
        resetSingleEventNews: (state: TEventsNewsState) => {
            state.eventNewsDetails = null
        },
        updateEventNewsFetchAgain: (state) => {
            state.fetchAgain = true
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<any>) => {
        // get list
        builder.addCase(eventsNewsList.pending, (state: TEventsNewsState) => {
            state.isLoading = true;
        });
        builder.addCase(eventsNewsList.fulfilled, (state: TEventsNewsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.fetchAgain = false;
            if (action.payload.status === 200) {
                state.eventsNewsData = action.payload.data.data?.data
                state.meta = action.payload.data.data.meta
            }

        });
        builder.addCase(eventsNewsList.rejected, (state: TEventsNewsState) => {
            state.isLoading = false;
            state.fetchAgain = false;
            state.isError = true;
        });
        // create
        builder.addCase(createEventNews.pending, (state: TEventsNewsState) => {
            state.isLoading = true;
        });
        builder.addCase(createEventNews.fulfilled, (state: TEventsNewsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.fetchAgain = true;
            const {data, status} = action.payload
            
            if (status === 201) {
                const cloneEventsNewsData = [...state.eventsNewsData];
                cloneEventsNewsData.unshift(data.eventNews)
                state.eventsNewsData = cloneEventsNewsData
            }
        });
        builder.addCase(createEventNews.rejected, (state: TEventsNewsState) => {
            state.isLoading = false;
            state.isError = true;
        });
        // get by id
        builder.addCase(eventNewsFindById.pending, (state: TEventsNewsState) => {
            state.isLoading = true;
        });
        builder.addCase(eventNewsFindById.fulfilled, (state: TEventsNewsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            if (action.payload.status === 200) {
                state.eventNewsDetails = action.payload.data.data
            }
        });
        builder.addCase(eventNewsFindById.rejected, (state: TEventsNewsState) => {
            state.isLoading = false;
            state.isError = true;
        });
        // update
        builder.addCase(eventNewsUpdateById.pending, (state: TEventsNewsState) => {
            state.isLoading = true;
        });
        builder.addCase(eventNewsUpdateById.fulfilled, (state: TEventsNewsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            if (action.payload.status === 200) {
                let cloneEventsNewsData = [...state.eventsNewsData];
                let updatedEventNewsDataIndex = cloneEventsNewsData.findIndex(eventNews => eventNews.id === action.payload.data.data.id)
                cloneEventsNewsData[updatedEventNewsDataIndex] = action.payload.data.data;
                state.eventsNewsData = cloneEventsNewsData
            }
        });
        builder.addCase(eventNewsUpdateById.rejected, (state: TEventsNewsState) => {
            state.isLoading = false;
            state.isError = true;
        });
        // delete
        builder.addCase(eventNewsDeleteById.pending, (state: TEventsNewsState) => {
            state.isLoading = true;
        });
        builder.addCase(eventNewsDeleteById.fulfilled, (state: TEventsNewsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.fetchAgain = true;
        });
        builder.addCase(eventNewsDeleteById.rejected, (state: TEventsNewsState) => {
            state.isLoading = false;
            state.isError = true;
        });
    }
});

export const { resetSingleEventNews, updateEventNewsFetchAgain } = eventNewsSlice.actions;

export default eventNewsSlice.reducer;