import React, { useEffect, useState } from "react";
import styles from "./SignIn.module.scss";
import bgImg from "../../../assets/images/signInBg.png";
import InputField from "../../../components/InputField/InputField";
import { t } from "i18next";
import EyeIcon from "../../../components/icons/EyeIcon";
import CloseEyeIcon from "../../../components/icons/CloseEyeIcon";
import Button from "../../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { signIn } from "../../../app/api";

const SignIn = () => {
  const dispatch: AppDispatch = useDispatch();
  const [showPassowrd, setShowPassowrd] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    roleId: 1
  });
  const { isLoading } = useSelector((state: RootState) => state.auth);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSignInClick = async () => {
    await dispatch(signIn(formData)).then((res: any) => {
      if (res.payload.status === 200) {
        setFormData({
          email: "",
          password: "",
          roleId: 1
        });
      }
    });
  };

  useEffect(() => {
    const isFormEmpty = Object.values(formData).some((value) => !value);
    const isEmailValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
      formData.email
    );
    setIsFormValid(isFormEmpty || !isEmailValid);
  }, [formData]);

  return (
    <div className={styles.signInPageGrid}>
      <div className={styles.signInSection}>
        <div className={styles.logo}>
          <img src={bgImg} alt="sign-in" />
        </div>
        <div className={styles.loginFormContainer}>
          <h3 className={styles.title}>{t("sign_in.title_text")}</h3>
          <div className={styles.form}>
            <div className={styles.inputField}>
              <InputField
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                type="email"
                label={t("sign_in.email.label_text")}
                required
                placeholder={t("sign_in.email.description_text")}
              />
            </div>
            <div className={styles.inputField}>
              <InputField
                id="password"
                value={formData.password}
                onChange={handleInputChange}
                type={!showPassowrd ? "password" : "text"}
                label={t("sign_in.password.label_text")}
                required
                placeholder={t("sign_in.password.description_text")}
                inputIcon={
                  !showPassowrd ? (
                    <EyeIcon fill="#333333" width="18px" height="18px" />
                  ) : (
                    <CloseEyeIcon fill="#333333" width="18px" height="18px" />
                  )
                }
                handleInputIconClick={() => setShowPassowrd(!showPassowrd)}
              />
            </div>
            <div className={styles.signInBtn}>
              <Button
                variant="contained"
                color="primary"
                disabled={isFormValid || isLoading}
                onClick={handleSignInClick}
              >
                {t("sign_in.btn_text")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
