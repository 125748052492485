import React from "react";
import styles from "./RadioButton.module.scss";

type TRadioProps = {
  id: string;
  checked: boolean | undefined;
  value?: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  forAdmin?: boolean;
};

const RadioButton = ({
  id,
  checked,
  value,
  onChange,
  name,
  label,
  forAdmin,
}: TRadioProps) => {
  return (
    <div className={styles.radioContainer}>
      <div className={styles.radioButton}>
        <input
          id={id}
          name={name}
          className={`${styles.radioButtonInput} ${
            forAdmin ? styles.adminRadioButton : ""
          }`}
          type="radio"
          value={value}
          checked={checked || undefined}
          onChange={onChange}
        />
        <label htmlFor={id} className={styles.radioButtonLabel}>
          <span className={styles.radioButtonCustom} />
          {label}
        </label>
      </div>
    </div>
  );
};

export default RadioButton;
