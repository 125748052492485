import React from "react";
import styles from "./ModalBody.module.scss";
import InputField from "../../../../components/InputField/InputField";

const ModalBody = (props: any) => {
  const { roleName, setRoleName } = props;

  return (
    <div className={styles.roleContent}>
      <div className={styles.inputField}>
        <InputField
          id="name"
          value={roleName}
          onChange={(e) => setRoleName(e.target.value)}
          type="string"
          label="Name"
          required
          placeholder="Enter role name"
          forAdmin
        />
      </div>
    </div>
  );
};

export default ModalBody;
