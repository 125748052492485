import React from "react";
import labelDictionaryRaw from "../../../common/description.json";

interface Label {
  [key: string]: {
    Full: string;
    Description: string;
  };
}
const labelDictionary: Label = labelDictionaryRaw;

const TableHeader: React.FC<{ headerRows: any[] }> = ({ headerRows }) => {
  return (
    <tr>
      {headerRows &&
        labelDictionary &&
        headerRows?.map((item: string, index: number) => (
          <th key={index}>
            {labelDictionary[item]?.hasOwnProperty("Full") &&
              labelDictionary[item].Full}
          </th>
        ))}
      <th>Actions</th>
    </tr>
  );
};

export default TableHeader;
