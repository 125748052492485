import { TIconProps } from "../../@types/icon";

const WarningIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 259 247"
      fill={fill}
    >
      <path
        d="M123 7.59998C121.6 7.79998 117.1 8.49998 113 9.09998C90.7 12.4 67.3 24.6 49.9 41.9C9.60005 82.2 3.90005 143.9 36 191.6C59.1 225.8 103.2 246.6 143.8 242.2C172.2 239.1 196 227.9 215.5 208.5C235.8 188.2 247.2 164.3 250.1 135.3C256 77.4 215.2 22.2 157.5 9.89998C148.8 8.09998 128.2 6.69998 123 7.59998ZM145.1 18.4C160.4 19.5 181.5 27.5 195.5 37.5C217.4 53.2 233 77.5 238.5 104.9C241.1 117.7 240.2 141.3 236.6 153.5C224.9 193.4 193.9 222.1 153.5 230.5C143 232.6 123 232.6 112.5 230.5C69 221.5 36.3 188.7 27.5 145.1C25.1 133.5 25.9 108.9 29 97.5C31.4 88.6 39.1 71.6 43.9 64.6C58.7 43.1 81.9 27.1 108 20.5C114.6 18.8 130.1 17.2 135.5 17.7C137.2 17.8 141.5 18.1 145.1 18.4Z"
        fill={fill}
      />
      <path
        d="M125.7 70.6C125.3 71 125 87.5 125 107.2V143.1L132.8 142.8L140.5 142.5V106.5V70.5L133.5 70.2C129.6 70.1 126.1 70.2 125.7 70.6Z"
        fill={fill}
      />
      <path
        d="M125.4 163.3C125.1 164.1 125 168 125.2 172.1L125.5 179.5H133H140.5V171V162.5L133.2 162.2C127.8 162 125.8 162.3 125.4 163.3Z"
        fill={fill}
      />
    </svg>
  );
};

export default WarningIcon
