import { ActionReducerMapBuilder, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { searchList } from "../api";

type TSearchState = {
    searchData: any[]
    isOpen: boolean;
    isError: boolean | null;
    isLoading: boolean;
    hasNextPage: boolean | null;
    totalCount: number | null;
}

const initialState: TSearchState = {
    searchData: [],
    isOpen: false,
    isError: null,
    isLoading: false,
    hasNextPage: null,
    totalCount: null
};

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        clearSearchData: (state) => {
            state.searchData = []
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<any>) => {
        builder.addCase(searchList.pending, (state: TSearchState) => {
            state.isLoading = true;
        });
        builder.addCase(searchList.fulfilled, (state: TSearchState, action: PayloadAction<any>) => {
            state.isLoading = false;
            if (action.payload.status === 200) {
                const cloneData = [...state.searchData];
                action.payload.data.data?.data.forEach((item: any) => {
                    cloneData.push(item)
                })
                state.searchData = cloneData
                state.hasNextPage = action.payload.data.data?.hasNextPage
                state.totalCount = action.payload.data.data?.totalCount
            }
        });
        builder.addCase(searchList.rejected, (state: TSearchState) => {
            state.isLoading = false;
            state.isError = true;
        });
    }
});

export const { setOpen, clearSearchData } = searchSlice.actions;

export default searchSlice.reducer;