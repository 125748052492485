import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./JoinUs.module.scss";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import ContactDetailsCard from "./Crads/ContactDetailsCard";
import {
  sendVerificationCode,
  registerUser,
  verifyUserEmail,
  signIn,
} from "../../app/api";
import OTPVerificationForm from "./Crads/OTPVerificationForm";
import Loading from "../../components/Loading/Loading";
import { clearJoinUsState, clearMemberLoginDetails } from "../../app/features/joinUsSlice";
import { toast } from "react-toastify";
import SuccessMessage from "./Crads/SuccessMessage";
import MemberLogin from "./Crads/MemberLogin";
import { useNavigate } from "react-router-dom";

const JoinUs = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [isCardsValid, setIsCardsValid] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoginFormValid, setIsLoginFormValid] = useState<boolean>(false)
  const [isFormSuccess, setIsFormSuccess] = useState<boolean>(false);
  const numberOfDigits = 6;
  const [otp, setOtp] = useState<string[]>(new Array(numberOfDigits).fill(""));
  const [verifyOtp, setVerifyOtp] = useState<boolean>(false)
  const [componentName, setComponentName] = useState<string>("join-us")
  const { memberLoginDetails } = useSelector((state: RootState) => state.joinUs)

  const {
    userDetails,
    isError,
    isLoading,
    otpCode,
  } = useSelector((state: RootState) => state.joinUs);

  const handleJoinClick = async () => {
    const query: any = {
      ...userDetails,
    };

    await dispatch(verifyUserEmail({ email: query.email })).then((res: any) => {
      if (res.payload.status === 201) {
        handleSendVerificationCode()
      } else {
        toast.warning(t("join_us.verification.email_already_exists"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
  }

  const handleSendVerificationCode = async () => {
    await dispatch(sendVerificationCode({ phoneNumber: userDetails.phone })).then(
      () => setVerifyOtp(true)
    );
  }

  const handleResendClick = async () => {
    const query: any = {
      ...userDetails,
    };
    await dispatch(sendVerificationCode({ phoneNumber: query.phone })).then(
      (res: any) => {
        toast.success(t("join_us.verification.resent_code_successfully_text"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  const handleVerifyClick = async () => {
    const query: any = {
      ...userDetails,
      otpCode,
    };
    const formData: any = new FormData();

    formData.append("firstName", query.firstName);
    formData.append("lastName", query.lastName);
    formData.append("email", query.email);
    formData.append("phone", query.phone);
    formData.append("profilePicture", query.profilePicture);
    formData.append("otpCode", query.otpCode);
    formData.append("role", "Member");

    await dispatch(registerUser(formData)).then((res: any) => {
      const { status } = res.payload
      if (status === 201) {
        setIsFormSuccess(true)
        dispatch(clearJoinUsState())
      } else if (status === 409) {
        toast.warning(t("join_us.verification.wrong_code_text"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(t("join_us.errors.error_text"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
  };

  const handleSetOtp = (data: string[]) => {
    setOtp([...data]);
  };

  const validateOtp = () => {
    return otp.every(digit => digit !== "");
  };

  const handleShowComponent = (name: string) => {
    setComponentName(name)
  }

  const handleLoginClick = async () => {
    await dispatch(signIn(memberLoginDetails)).then((res: any) => {
      if (res.payload.status === 201) {
        dispatch(clearMemberLoginDetails());
        navigate("/profile")
      }
    });
  }

  useEffect(() => {
    const isValidUserDetails =
      Object.entries(userDetails).every(([key, value]: any) => {

        if (key === "profilePicture") {
          return true
        } else if (key === "email" || key === "confirmEmail") {
          if (userDetails.email !== userDetails.confirmEmail) {
            return false;
          } else {
            return value;
          }
        } else {
          return value;
        }
      })

    const isValidLoginDetails =
      Object.entries(memberLoginDetails).every(([key, value]: any) => {
        return value
      })

    const isFormValid =
      isValidUserDetails &&
      isError;

    setIsFormValid(isFormValid);
    setIsLoginFormValid(isValidLoginDetails)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails, isError, memberLoginDetails]);

  return (
    <div className={`container ${styles.joinUsPageGrid}`}>
      {isFormSuccess ? (
        <div className={styles.successMessage}>
          <SuccessMessage />
        </div>
      ) : (
        <div className={styles.joinUsFormContainer}>
          <h2>
            {
              componentName === "join-us" ? <>{t("join_us.title_text")}</> : <>{t("join_us.login_title_text")} </>
            }
          </h2>
          <div className={styles.form}>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {
                  componentName === "join-us" ?
                    <>
                      {
                        !verifyOtp ? <ContactDetailsCard setIsCardsValid={setIsCardsValid} /> : <OTPVerificationForm otp={otp}
                          handleSetOtp={handleSetOtp}
                          numberOfDigits={numberOfDigits} />
                      }
                    </>
                    :
                    <>
                      <MemberLogin />
                    </>
                }
              </>
            )}
          </div>
          <div
            className={`${styles.nextBtn} ${styles.btnStyle}`}
          >
            {
              componentName === "join-us" ?
                <>
                  {
                    !verifyOtp ?
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          // disabled={}
                          onClick={() => handleShowComponent("login")}
                        >
                          {t("join_us.login_btn_text")}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={!isFormValid || isLoading}
                          onClick={handleJoinClick}
                        >
                          {t("join_us.btn_text")}
                        </Button>
                      </>
                      :
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleResendClick}
                        >
                          {t("join_us.verification.resend")}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={!validateOtp()}
                          onClick={handleVerifyClick}
                        >
                          {t("join_us.verification.verify")}
                        </Button>
                      </>
                  }
                </>
                :
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleShowComponent("join-us")}
                  >
                    {t("join_us.btn_text")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!isLoginFormValid || isLoading}
                    onClick={handleLoginClick}
                  >
                    {t("join_us.login_btn_text")}
                  </Button>
                </>
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default JoinUs;
