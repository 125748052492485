import React, { useEffect, useState } from "react";
import Table from "../../../components/Table/table";
import { Partner } from "../../../../@types/partner";
import { DeletePartner, GetPartnerList } from "../api/partnerApi";
import PartnerForm from "../PartnerForm/partnerForm";
import { REACT_APP_AMAZON_S3_URL } from "../../../../constants/constant";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import { toast } from "react-toastify";
import { setdDisabledBtn } from "../../../../app/features/disabledBtnSlice";
import { ListParams, Meta } from "../../../../@types/table";
import Loading from "../../../../components/Loading/Loading";
const tableHeader = ["website", "logo"];

const PartnerList = () => {
  const [partnerList, setPartnerList] = useState<Partner[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<Partner | null>(null);
  const [metaData, setMetaData] = useState<Meta>();
  const [isLodaing, setIsLoading] = useState<boolean>(false);

  const { item } = useSelector((state: RootState) => state.itemEdit);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  const onCreatePartner = () => {
    setShowModal(true);
    setIsEdit(false);
    setEditItem(null);
  };

  const onEditItem = (type: string, item: Partner) => {
    setEditItem(item);
    if (type === "edit") {
      setIsEdit(true);
      setShowModal(true);
    } else {
      setShowConfirmationModal(true);
    }
  };

  const onHandelClose = (type: boolean, item: Partner) => {
    setShowModal(type);
  };

  const onHandelPageClick = (page: number): void => {
    getPartnerList({ page: page, take: 10 });
  };

  const handleConfirmClick = () => {
    dispatch(setdDisabledBtn(true));

    DeletePartner(editItem?.id!).then((res) => {
      toast.success("Deleted Successfully !");
      setShowConfirmationModal(false);

      const updatedPartnerList = [...partnerList].filter(
        (item) => item?.id !== editItem?.id
      );

      setPartnerList(updatedPartnerList);
      dispatch(setdDisabledBtn(false));
    });
  };

  const getPartnerList = (params: ListParams) => {
    setIsLoading(true);
    GetPartnerList(params).then((res) => {
      const data = res?.data?.map((item) => ({
        ...item,
        logo: `${REACT_APP_AMAZON_S3_URL}/${item?.logo}`,
      }));
      setPartnerList(data);
      setMetaData(res?.meta);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getPartnerList({ page: 1, take: 10 });
  }, []);

  useEffect(() => {
    if (item && isEdit) {
      const indexItem = partnerList.findIndex(
        (partner) => partner?.id === item?.id
      );
      if (indexItem !== -1) {
        let updatedPartnerList: Partner[] = [...partnerList];
        updatedPartnerList[indexItem] = {
          ...item,
          logo: `${REACT_APP_AMAZON_S3_URL}/${item?.logo}`,
        };

        setPartnerList(updatedPartnerList);
      }
    }

    if (item && !isEdit) {
      let updatedPartnerList: Partner[] = [...partnerList];
      updatedPartnerList.push({
        ...item,
        logo: `${REACT_APP_AMAZON_S3_URL}/${item?.logo}`,
      });

      setPartnerList(updatedPartnerList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return isLodaing ? (
    <Loading />
  ) : (
    <>
      <Table
        tableHeader={
          partnerList?.length ? Object.keys(partnerList[0]) : tableHeader
        }
        tableBody={partnerList}
        createRecord={onCreatePartner}
        getEditItem={onEditItem}
        metaData={metaData!}
        handlePageClick={onHandelPageClick}
      />
      {showModal && (
        <PartnerForm
          isEdit={isEdit}
          showModal={showModal}
          editRowData={editItem!}
          closeModal={(type: boolean, addedItem?: Partner) =>
            onHandelClose(type, addedItem!)
          }
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          isOpen={showConfirmationModal}
          text="Are you sure you want to continue ?"
          onClose={() => setShowConfirmationModal(false)}
          onClick={handleConfirmClick}
          onOutside
        />
      )}
    </>
  );
};

export default PartnerList;
