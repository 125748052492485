import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import Logo from "../../../assets/images/avatars/avatar_1.jpg";
import { TMenuDataProps, TSidebarProps } from "../../../@types/type";
import DashboardIcon from "../../../components/icons/admin/DashboardIcon";
import { v4 as uuidv4 } from "uuid";
import AboutUsIcon from "../../../components/icons/admin/AboutUsIcon";
import ContactUsIcon from "../../../components/icons/admin/ContactUsIcon";
import useScreenSize from "../../../hooks/useScreenSize";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import FeedIcon from "../../../components/icons/admin/FeedIcon";
import MediaIcon from "../../../components/icons/admin/MediaIcon";
import SlideIcon from "../../../components/icons/admin/SlideIcon";
import EventsNewsIcon from "../../../components/icons/admin/EventsNewsIcon";
import RoleIcon from "../../../components/icons/admin/RoleIcon";
import PartnerIcon from "../../../components/icons/admin/PartnerIcon";
import NewsletterIcon from "../../../components/icons/admin/NewsletterIcon";
import UsersIcon from "../../../components/icons/UsersIcon";

const sidebarConfig: TMenuDataProps[] = [
  {
    title: "Dashboard",
    path: "/dashboard/app",
    icon: <DashboardIcon width="20px" height="20px" fill="#333333" />,
  },
  {
    title: "About Us",
    path: "/dashboard/about-us",
    icon: <AboutUsIcon width="20px" height="20px" fill="#333333" />,
  },
  {
    title: "Contact Us",
    path: "/dashboard/contact-us",
    icon: <ContactUsIcon width="20px" height="20px" fill="#333333" />,
  },
  {
    title: "Publication",
    path: "/dashboard/publication",
    icon: <FeedIcon width="20px" height="20px" fill="#333333" />,
  },
  {
    title: "Media",
    path: "/dashboard/media",
    icon: <MediaIcon width="20px" height="20px" fill="#333333" />,
  },
  {
    title: "Newsletter",
    path: "/dashboard/newsletter",
    icon: <NewsletterIcon width="20px" height="20px" fill="#333333" />,
  },
  {
    title: "Slide",
    path: "/dashboard/slide",
    icon: <SlideIcon width="20px" height="20px" fill="#333333" />,
  },
  {
    title: "Events & News",
    path: "/dashboard/events-news",
    icon: <EventsNewsIcon width="20px" height="20px" fill="#333333" />,
  },
  {
    title: "Role",
    path: "/dashboard/role",
    icon: <RoleIcon width="20px" height="20px" fill="#333333" />,
  },
  {
    title: "Users",
    path: "/dashboard/users",
    icon: <UsersIcon width="20px" height="20px" fill="#333333" />
  },
  {
    title: "Partner",
    path: "/dashboard/partner",
    icon: <PartnerIcon width="20px" height="20px" fill="#333333" />,
  },
];

const AdminSidebar: React.FC<TSidebarProps> = ({ isOpen, setOpenSidebar }) => {
  const location = useLocation();
  const { width } = useScreenSize();
  const [currentPage, setCurrentPage] = useState("/dashboard/app");

  const ref = useOutsideClick(() => {
    setOpenSidebar(false);
  });

  useEffect(() => {
    setCurrentPage(location.pathname);
    setOpenSidebar(false);
  }, [location.pathname, setOpenSidebar]);

  return (
    <div
      className={`${styles.adminSidebar} ${width < 768 ? styles.closed : ""} ${
        isOpen ? styles.opened : ""
      }`}
    >
      <div
        ref={ref}
        className={`${styles.sidebar} ${
          isOpen ? styles.opened : styles.closed
        }`}
      >
        <div className={styles.sidebarContent}>
          <div className={styles.header}>
            <div className={styles.avatar}>
              <img src={Logo} alt="logo" />
            </div>
            <div className={styles.userInfo}>Admin</div>
          </div>
          <div className={styles.sidebarBody}>
            {sidebarConfig.map((item: TMenuDataProps) => {
              return (
                <Link
                  to={item.path || ""}
                  key={uuidv4()}
                  className={`${styles.links} ${
                    item.path === currentPage ? styles.active : ""
                  }`}
                >
                  <span className={styles.icon}>{item.icon}</span>
                  <span>{item.title}</span>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
