import React from "react";
import { TTabProps } from "../../@types/type";
import styles from "./Tabs.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTab } from "../../app/features/tabSlice";
import { RootState } from "../../app/store";

type TTabsProps = {
  tabs: TTabProps[];
  save?: boolean;
};

const Tabs: React.FC<TTabsProps> = ({ tabs, save }) => {
  const dispatch = useDispatch();
  const { selectedType } = useSelector((state: RootState) => state.tab);

  const handleTabClick = (value: string) => {
    if (save) {
      localStorage.activeTab = value;
      dispatch(setSelectedTab(value));
    } else {
      dispatch(setSelectedTab(value));
    }
  };

  return (
    <div className={styles.tabs}>
      {tabs.map((tab, index) => (
        <div
          key={index}
          onClick={() => handleTabClick(tab.value)}
          className={tab.value === selectedType ? styles.active : ""}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
