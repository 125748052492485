import React from "react";
import styles from "./ComingSoon.module.scss";
import Logo from "../../assets/images/coming_soon.jpg";

const ComingSoon = () => {
  // return <div className={styles.emptyData}>COMING SOON</div>;
  return <div className={styles.emptyData}>
    <img src={Logo} alt="coming-soon" />
  </div>;
};

export default ComingSoon;
