import React, { ChangeEvent, useState } from "react";
import InputField from "../../../../components/InputField/InputField";
import UploadFile from "../../../components/UploadFile/uploadFile";
import styles from "../../Media/ModalBody/ModalBody.module.scss";
import { Slide } from "../../../../@types/slide";
import Modal from "../../../../components/Modal/Modal";
import { validateFormObjetc } from "../../../common/common";
import { toast } from "react-toastify";
import { createSlide, editSlide } from "../apis/adminApi";

import { useDispatch } from "react-redux";
import { setdDisabledBtn } from "../../../../app/features/disabledBtnSlice";

const SlideForm: React.FC<{
  isEdit: boolean;
  showModal: boolean;
  editRowData: Slide;
  closeModal: (type: boolean, addedItem?: Slide) => void;
}> = ({ isEdit, showModal, closeModal, editRowData }) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [formModel, setFormModel] = useState<Slide>({
    title_ar: editRowData?.title_ar ?? "",
    title_en: editRowData?.title_en ?? "",
    subtitle_ar: editRowData?.subtitle_ar ?? "",
    subtitle_en: editRowData?.subtitle_en ?? "",
    button_title_ar: editRowData?.button_title_ar ?? "",
    button_title_en: editRowData?.button_title_en ?? "",
    button_url: editRowData?.button_url ?? "",
    active_from: editRowData?.active_from ?? "",
    active_to: editRowData?.active_to ?? "",
  });

  const [validation, setValidation] = useState<string[]>([]);

  const dispatch = useDispatch();

  const onSetFile = (file: File): void => {
    setImageFile(file);
  };

  const handleModalClose = () => {
    closeModal(false);
  };

  const onHandelChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { id, value } = e?.target;
    if (value && value !== "") {
      const updatedValidation = validation?.filter((item) => item !== id);
      setValidation(updatedValidation);
    }

    setFormModel((oldState) => ({
      ...oldState,
      [id]: value,
    }));
  };

  const onSubmitForm = () => {
    dispatch(setdDisabledBtn(true));

    if (validateFormObjetc(formModel)?.length) {
      setValidation(validateFormObjetc(formModel));
      dispatch(setdDisabledBtn(false));
      return;
    }

    if (!imageFile && !isEdit) {
      toast.warning("add image !");
      dispatch(setdDisabledBtn(false));
      return;
    }

    const formData: any = new FormData();
    formData.append("title_ar", formModel.title_ar!);
    formData.append("title_en", formModel.title_en!);
    formData.append("subtitle_ar", formModel.subtitle_ar!);
    formData.append("subtitle_en", formModel.subtitle_en!);
    formData.append("button_title_ar", formModel.button_title_ar!);
    formData.append("button_title_en", formModel.button_title_en!);
    formData.append("button_url", formModel.button_url!);
    formData.append("active_from", formModel.active_from!);
    formData.append("active_to", formModel.active_to!);
    if (isEdit && !imageFile) {
      formData.append("image_url", editRowData?.image_url);
    } else {
      imageFile && formData.append("image_url", imageFile);
    }

    if (isEdit) {
      editSlide(formData, editRowData?.id!).then((res) => {
        toast.success("Edit successfully !");
        dispatch(setdDisabledBtn(false));
        closeModal(false, res);
      });
    } else {
      createSlide(formData).then((res) => {
        dispatch(setdDisabledBtn(false));
        toast.success("created successfully !");
        setFormModel({
          title_ar: "",
          title_en: "",
          subtitle_ar: "",
          subtitle_en: "",
          button_title_ar: "",
          button_title_en: "",
          button_url: "",
          active_from: "",
          active_to: "",
        });
        closeModal(false, res);
      });
    }
  };

  return (
    <Modal
      isOpen={showModal}
      btnText={!isEdit ? "Create" : "Edit"}
      onClose={handleModalClose}
      onClick={onSubmitForm}
      onOutside
      type="admin"
    >
      <div className={styles.mediaModalContent}>
        <div className={styles.fieldForm}>
          <div className={styles.inputField}>
            <InputField
              id="title_en"
              value={formModel?.title_en!}
              onChange={onHandelChange}
              type="string"
              label="Title (English)"
              required
              placeholder="Enter english title"
              forAdmin
            />
            {validation?.includes("title_en") && (
              <span style={{ color: "red" }}>this field is required !</span>
            )}
          </div>
          <div className={styles.inputField}>
            <InputField
              id="title_ar"
              value={formModel?.title_ar!}
              onChange={onHandelChange}
              type="string"
              label="Title (Arabic)"
              required
              placeholder="Enter arabic title"
              forAdmin
            />
            {validation?.includes("title_ar") && (
              <span style={{ color: "red" }}>this field is required !</span>
            )}
          </div>
        </div>

        <div className={styles.fieldForm}>
          <div className={styles.inputField}>
            <InputField
              id="subtitle_en"
              value={formModel?.subtitle_en!}
              onChange={onHandelChange}
              type="string"
              label="Subtitle (English)"
              required
              placeholder="Enter english subtitle"
              forAdmin
            />
            {validation?.includes("subtitle_en") && (
              <span style={{ color: "red" }}>this field is required !</span>
            )}
          </div>
          <div className={styles.inputField}>
            <InputField
              id="subtitle_ar"
              value={formModel?.subtitle_ar!}
              onChange={onHandelChange}
              type="string"
              label="Subtitle (Arabic)"
              required
              placeholder="Enter arabic subtitle"
              forAdmin
            />
            {validation?.includes("subtitle_ar") && (
              <span style={{ color: "red" }}>this field is required !</span>
            )}
          </div>
        </div>

        <div className={styles.fieldForm}>
          <div className={styles.inputField}>
            <InputField
              id="button_title_en"
              value={formModel?.button_title_en!}
              onChange={onHandelChange}
              type="string"
              label="Button Title (English)"
              required
              placeholder="Enter english button title"
              forAdmin
            />
            {validation?.includes("button_title_en") && (
              <span style={{ color: "red" }}>this field is required !</span>
            )}
          </div>
          <div className={styles.inputField}>
            <InputField
              id="button_title_ar"
              value={formModel?.button_title_ar!}
              onChange={onHandelChange}
              type="string"
              label="Button Title (Arabic)"
              required
              placeholder="Enter arabic button title"
              forAdmin
            />
            {validation?.includes("button_title_ar") && (
              <span style={{ color: "red" }}>this field is required !</span>
            )}
          </div>
          <div className={styles.inputField}>
            <InputField
              id="button_url"
              value={formModel?.button_url!}
              onChange={onHandelChange}
              type="string"
              label="Button URL"
              required
              placeholder="Enter URL"
              forAdmin
            />
            {validation?.includes("button_url") && (
              <span style={{ color: "red" }}>this field is required !</span>
            )}
          </div>
        </div>

        <div className={styles.fieldForm}>
          <div className={styles.inputField}>
            <InputField
              id="active_from"
              value={formModel?.active_from!}
              onChange={onHandelChange}
              type="date"
              label="Active From"
              required
              placeholder="Enter active from"
              forAdmin
            />
            {validation?.includes("active_from") && (
              <span style={{ color: "red" }}>this field is required !</span>
            )}
          </div>
          <div className={styles.inputField}>
            <InputField
              id="active_to"
              value={formModel?.active_to!}
              onChange={onHandelChange}
              type="date"
              label="Active To"
              required
              placeholder="Enter active to"
              forAdmin
            />
            {validation?.includes("active_to") && (
              <span style={{ color: "red" }}>this field is required !</span>
            )}
          </div>
        </div>

        <div className={styles.attachment}>
          <div className={styles.uploadFilesContent}>
            <UploadFile
              setFileDataValCB={onSetFile}
              imageUrlString={editRowData?.image_url}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SlideForm;
