import React from "react";
import { TIconProps } from "../../../@types/icon";

const EditIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
    >
      <path
        d="M25 175V139.583L135 29.7917C136.667 28.2639 138.508 27.0833 140.525 26.25C142.542 25.4167 144.658 25 146.875 25C149.097 25 151.25 25.4167 153.333 26.25C155.417 27.0833 157.222 28.3333 158.75 30L170.208 41.6667C171.875 43.1944 173.092 45 173.858 47.0833C174.625 49.1667 175.006 51.25 175 53.3333C175 55.5556 174.617 57.675 173.85 59.6917C173.083 61.7083 171.869 63.5472 170.208 65.2083L60.4167 175H25ZM146.667 65L158.333 53.3333L146.667 41.6667L135 53.3333L146.667 65Z"
        fill={fill}
      />
    </svg>
  );
};

export default EditIcon;
