import React from "react";
import { TIconProps } from "../../@types/icon";

const ArrowRightIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
    >
      <path
        d="M83.3334 141.667L125 100L83.3334 58.3333"
        stroke={fill}
        strokeWidth="8.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRightIcon;
