import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  Fragment,
} from "react";
import styles from "./SingleEventNews.module.scss";
import { useParams } from "react-router-dom";
import {
  TBreadcrumbItemState,
  TFindByIdApiProps,
  TEventNewsApiProps,
} from "../../@types/type";
import { useTranslation } from "react-i18next";
import NewsIcon from "../../components/icons/NewsIcon";
import ShareIcon from "../../components/icons/ShareIcon";
import CalendarIcon from "../../components/icons/CalendarIcon";
import { Swiper, SwiperSlide } from "swiper/react";
import { v4 as uuidv4 } from "uuid";
import SwiperCore from "swiper";
import {
  A11y,
  EffectFade,
  Navigation,
  Scrollbar,
  FreeMode,
  Thumbs,
} from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumb } from "../../app/features/breadcrumbSlice";
import { fDateTime } from "../../utils/formats";
import { REACT_APP_AMAZON_S3_URL } from "../../constants/constant";
import { AppDispatch, RootState } from "../../app/store";
import { eventNewsFindById } from "../../app/api";
import { resetSingleEventNews } from "../../app/features/eventNewsSlice";
import Loading from "../../components/Loading/Loading";

SwiperCore.use([Navigation, Scrollbar, A11y, EffectFade]);

const EventNewsDetails = () => {
  const dispatch: AppDispatch = useDispatch();
  const { i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);
  const { currentLanguage } = useSelector((state: RootState) => state.language);
  const { eventNewsDetails, isLoading } = useSelector(
    (state: RootState) => state.event_news
  );
  const { breadcrumbItems } = useSelector(
    (state: RootState) => state.breadcrumb
  );

  const duplicatedSlides =
    eventNewsDetails &&
    eventNewsDetails.eventsNewsUrls?.map((eventNews) =>
      eventNewsDetails.type === "image" ? (
        <SwiperSlide key={uuidv4()}>
          <img src={`${REACT_APP_AMAZON_S3_URL}/${eventNews}`} alt="carusel_img" />
        </SwiperSlide>
      ) : null
    );

  useEffect(() => {
    if (eventNewsDetails) {
      const nameValue =
        eventNewsDetails[`title_${currentLanguage.value}` as keyof TEventNewsApiProps];
      if (
        !breadcrumbItems.some((e: TBreadcrumbItemState) => e.name === nameValue)
      ) {
        if (nameValue && typeof nameValue === "string") {
          dispatch(setBreadcrumb({ name: nameValue }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventNewsDetails, currentLanguage, breadcrumbItems]);

  const singleEventNewsData = useCallback(async () => {
    try {
      if (id) {
        const apiProps: TFindByIdApiProps = {
          id,
        };
        await dispatch(eventNewsFindById(apiProps));
      }
    } catch (error: unknown) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    singleEventNewsData();
    return () => {
      dispatch(resetSingleEventNews());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleEventNewsData]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  }, []);

  return (
    <div className={`container ${styles.singleEventNews}`}>
      {isLoading || !eventNewsDetails ? (
        <Loading />
      ) : (
        <Fragment>
          <h3 className={styles.title}>
            <NewsIcon width="40px" height="40px" fill="#FFFFFF" />
            {eventNewsDetails &&
              eventNewsDetails[
                `short_description_${i18n.language}` as keyof TEventNewsApiProps
              ]}
          </h3>
          <div className={styles.subTitle}>
            <div>
              <ShareIcon width="16px" height="16px" fill="#333333" />
              <span>shareing</span>
            </div>
            <div>
              <CalendarIcon width="16px" height="16px" fill="#333333" />
              <span>
                {eventNewsDetails?.date &&
                  fDateTime({
                    date: new Date(eventNewsDetails?.date),
                    newFormat: "dd/MM/yyyy",
                  })}
              </span>
            </div>
          </div>
          <div className={styles.thumbnailImage}>
            <img
              src={`${REACT_APP_AMAZON_S3_URL}/${eventNewsDetails?.thumbnail_image}`}
              alt="thumbnail_image"
            />
          </div>
          <div className={styles.desc}>
            {eventNewsDetails &&
              eventNewsDetails[
                `long_description_${i18n.language}` as keyof TEventNewsApiProps
              ]}
          </div>
          <div className={`${styles.swiperContainer} media-swiper-container`}>
            {eventNewsDetails?.type === "video" ? (
              <div className={styles.videoContent}>
                <video ref={videoRef} controls muted playsInline autoPlay>
                  <source
                    src={`${REACT_APP_AMAZON_S3_URL}/${eventNewsDetails?.eventsNewsUrls[0]}`}
                    type="video/mp4"
                  />
                </video>
              </div>
            ) : (
              <div className={styles.imageContent}>
                <Swiper
                  loop={true}
                  spaceBetween={10}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  {eventNewsDetails?.eventsNewsUrls && eventNewsDetails.eventsNewsUrls.length > 0
                    ? eventNewsDetails.eventsNewsUrls.reduce(
                        (
                          result: JSX.Element[],
                          eventsNews: string,
                          index: number
                        ) => {
                          const item = (
                            <SwiperSlide key={uuidv4()}>
                              <img
                                src={`${REACT_APP_AMAZON_S3_URL}/${eventsNews}`}
                                alt="carusel_img"
                              />
                            </SwiperSlide>
                          );
                          result.push(item);
                          return result;
                        },
                        []
                      )
                    : null}
                </Swiper>
                <Swiper
                  onSwiper={setThumbsSwiper}
                  loop={true}
                  spaceBetween={10}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper"
                >
                  {duplicatedSlides}
                </Swiper>
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default EventNewsDetails;