import React, { Dispatch, Fragment, useState } from "react";
import styles from "./TableBody.module.scss";
import AdminButton from "../../../../components/AdminButton/AdminButton";
import { v4 as uuidv4 } from "uuid";
import { TEventsNewsCardProps } from "../../../../@types/type";
import PlusIcon from "../../../../components/icons/PlusIcon";
import { REACT_APP_AMAZON_S3_URL } from "../../../../constants/constant";
import { fDateTime } from "../../../../utils/formats";
import EditIcon from "../../../../components/icons/admin/EditIcon";
import DeleteIcon from "../../../../components/icons/admin/DeleteIcon";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/store";
import { eventNewsDeleteById } from "../../../../app/api";
import { toast } from "react-toastify";

type TEventsNewsTeableProps = {
  rowsData: TEventsNewsCardProps[];
  headerColumns: string[];
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
  handleEditClick: (e: TEventsNewsCardProps) => void;
};

const TableBody: React.FC<TEventsNewsTeableProps> = (props) => {
  const { rowsData, headerColumns, setShowModal, handleEditClick } = props;
  const dispatch: AppDispatch = useDispatch();
  const [deletingEventNewsId, setDeletingEventNewsId] = useState<
    null | number | string
  >(null);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const { isLoading } = useSelector((state: RootState) => state.event_news);

  const handleDeleteClick = (idx: number) => {
    setDeletingEventNewsId(idx);
    setShowConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    setDeletingEventNewsId(null);
  };

  const handleConfirmClick = async () => {
    await dispatch(eventNewsDeleteById({ id: deletingEventNewsId })).then(
      (res: any) => {
        if (res.payload.status === 200) {
          handleCloseConfirmationModal();
          toast.success(res.payload.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  return (
    <Fragment>
      <div className={styles.tableContainer}>
        <div className={styles.topActions}>
          <AdminButton onClick={() => setShowModal(true)}>
            <PlusIcon width="22px" height="22px" fill="#FFFFFF" />
            Create
          </AdminButton>
        </div>
        <div className={styles.body}>
          {rowsData?.length ? (
            <table>
              <thead>
                <tr>
                  {headerColumns.map((col: any) => {
                    return <th key={uuidv4()}>{col}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {rowsData.map((row: TEventsNewsCardProps) => {
                  const {
                    id,
                    title_en,
                    title_ar,
                    short_description_en,
                    short_description_ar,
                    long_description_en,
                    long_description_ar,
                    thumbnail_image,
                    type,
                    date,
                  } = row;
                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>
                        <p>{title_en}</p>
                        <p>{title_ar}</p>
                      </td>
                      <td>
                        <p>
                          {short_description_en.length > 30
                            ? `${short_description_en.substring(0, 30)}...`
                            : short_description_en}
                        </p>
                        <p>
                          {short_description_ar.length > 30
                            ? `${short_description_ar.substring(0, 30)}...`
                            : short_description_ar}
                        </p>
                      </td>
                      <td>
                        <p>
                          {long_description_en.length > 30
                            ? `${long_description_en.substring(0, 30)}...`
                            : long_description_en}
                        </p>
                        <p>
                          {long_description_ar.length > 30
                            ? `${long_description_ar.substring(0, 30)}...`
                            : long_description_ar}
                        </p>
                      </td>
                      <td>
                        <img
                          src={`${REACT_APP_AMAZON_S3_URL}/${thumbnail_image}`}
                          alt="thumbnail_image"
                          width={50}
                        />
                      </td>
                      <td>{type}</td>
                      <td>
                        {fDateTime({
                          date: new Date(date),
                          newFormat: "dd/MM/yyyy",
                        })}
                      </td>
                      <td>
                        <div className={styles.actions}>
                          <div onClick={() => handleEditClick(row)}>
                            <EditIcon
                              width="20px"
                              height="20px"
                              fill="#333333"
                            />
                          </div>
                          <div onClick={() => handleDeleteClick(id)}>
                            <DeleteIcon
                              width="20px"
                              height="20px"
                              fill="#e52020"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : null}
        </div>
      </div>
      <ConfirmationModal
        isOpen={showConfirmationModal}
        text="Are you sure you want to continue ?"
        onClose={handleCloseConfirmationModal}
        onClick={handleConfirmClick}
        onOutside
        disabled={isLoading}
      />
    </Fragment>
  );
};

export default TableBody;
