import React from "react";
import { TIconProps } from "../../@types/icon";

const EyeIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 21"
      fill="none"
    >
      <path
        d="M15.3307 6.47656C16.3916 6.47656 17.409 6.89799 18.1592 7.64814C18.9093 8.39828 19.3307 9.4157 19.3307 10.4766C19.3307 11.5374 18.9093 12.5548 18.1592 13.305C17.409 14.0551 16.3916 14.4766 15.3307 14.4766C14.2699 14.4766 13.2524 14.0551 12.5023 13.305C11.7522 12.5548 11.3307 11.5374 11.3307 10.4766C11.3307 9.4157 11.7522 8.39828 12.5023 7.64814C13.2524 6.89799 14.2699 6.47656 15.3307 6.47656ZM15.3307 0.476562C21.9974 0.476562 27.6907 4.62323 29.9974 10.4766C27.6907 16.3299 21.9974 20.4766 15.3307 20.4766C8.66406 20.4766 2.97073 16.3299 0.664062 10.4766C2.97073 4.62323 8.66406 0.476562 15.3307 0.476562ZM3.57073 10.4766C4.6484 12.677 6.3218 14.5309 8.4007 15.8276C10.4796 17.1242 12.8806 17.8117 15.3307 17.8117C17.7809 17.8117 20.1819 17.1242 22.2608 15.8276C24.3397 14.5309 26.0131 12.677 27.0907 10.4766C26.0131 8.27615 24.3397 6.42223 22.2608 5.12556C20.1819 3.82888 17.7809 3.14147 15.3307 3.14147C12.8806 3.14147 10.4796 3.82888 8.4007 5.12556C6.3218 6.42223 4.6484 8.27615 3.57073 10.4766Z"
        fill={fill}
      />
    </svg>
  );
};

export default EyeIcon;
