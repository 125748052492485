import React, { ChangeEvent, RefObject, useMemo } from "react";
import styles from "./ModalBody.module.scss";
import InputField from "../../../../components/InputField/InputField";
import { v4 as uuidv4 } from "uuid";
import { REACT_APP_AMAZON_S3_URL } from "../../../../constants/constant";

type TModalBodyProps = {
  isEdit: boolean;
  multiples: File[];
  stringMultiples: string[];
  eventsNewsForm: TEventsNewsFormProps;
  inputFilesRefs: RefObject<HTMLInputElement[] | null>;
  handelRemoveAttachment: (file: any, index: number) => void;
  handleInputChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleMultipleEventsNewsChange: (event: ChangeEvent<HTMLInputElement>) => void;
};
const ModalBody: React.FC<TModalBodyProps> = React.memo((props) => {
  const {
    isEdit,
    eventsNewsForm,
    multiples,
    inputFilesRefs,
    stringMultiples,
    handleInputChange,
    handelRemoveAttachment,
    handleMultipleEventsNewsChange,
  } = props;

  const fileURLs: any = useMemo(() => {
    return multiples.map((item: any, index: number) => {
      return (
        <div key={uuidv4()}>
          {item.type.startsWith("image/") ? (
            <img src={URL.createObjectURL(item)} alt="file" />
          ) : (
            <video controls muted playsInline>
              <source src={URL.createObjectURL(item)} type="video/mp4" />
            </video>
          )}
          <div
            className={styles.removeAttachment}
            onClick={() => handelRemoveAttachment(item, index)}
          >
            x
          </div>
        </div>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiples]);

  const stringFileURLs: any = useMemo(() => {
    return stringMultiples.map((item: string, index: number) => {
      return (
        <div key={uuidv4()}>
          {eventsNewsForm.type === "image" ? (
            <img src={`${REACT_APP_AMAZON_S3_URL}/${item}`} alt="str_file" />
          ) : (
            <video controls muted playsInline>
              <source
                src={`${REACT_APP_AMAZON_S3_URL}/${item}`}
                type="video/mp4"
              />
            </video>
          )}
          <div
            className={styles.removeAttachment}
            onClick={() => handelRemoveAttachment(item, index)}
          >
            x
          </div>
        </div>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringMultiples]);

  return (
    <div className={styles.eventsNewsContent}>
      <div className={styles.fieldForm}>
        <div className={styles.inputField}>
          <InputField
            id="title_en"
            value={eventsNewsForm.title_en}
            onChange={handleInputChange}
            type="string"
            label="Title (English)"
            required
            placeholder="Enter english title"
            forAdmin
          />
        </div>
        <div className={styles.inputField}>
          <InputField
            id="title_ar"
            value={eventsNewsForm.title_ar}
            onChange={handleInputChange}
            type="string"
            label="Title (Arabic)"
            required
            placeholder="Enter arabic title"
            forAdmin
          />
        </div>
      </div>
      <div className={styles.fieldForm}>
        <div className={styles.inputField}>
          <InputField
            id="short_description_en"
            value={eventsNewsForm.short_description_en}
            onChange={handleInputChange}
            type="string"
            label="Short Description (English)"
            required
            placeholder="Enter english short description"
            forAdmin
          />
        </div>
        <div className={styles.inputField}>
          <InputField
            id="short_description_ar"
            value={eventsNewsForm.short_description_ar}
            onChange={handleInputChange}
            type="string"
            label="Short Description (Arabic)"
            required
            placeholder="Enter arabic short description"
            forAdmin
          />
        </div>
      </div>
      <div className={styles.fieldForm}>
        <div className={styles.inputField}>
          <div className={styles.textareaLabel}>
            Long Description (English) <span>*</span>
          </div>
          <textarea
            id="long_description_en"
            value={eventsNewsForm.long_description_en}
            onChange={handleInputChange}
            required
            placeholder="Enter english long description"
          />
        </div>
        <div className={styles.inputField}>
          <div className={styles.textareaLabel}>
            Long Description (Arabic) <span>*</span>
          </div>
          <textarea
            id="long_description_ar"
            value={eventsNewsForm.long_description_ar}
            onChange={handleInputChange}
            required
            placeholder="Enter arabic long description"
          />
        </div>
      </div>
      <div className={styles.attachment}>
        <div className={styles.uploadFilesContent}>
          <button
            onClick={() => {
              if (inputFilesRefs.current) inputFilesRefs.current[0].click();
            }}
          >
            Upload files
            <span>*</span>
          </button>
          <div
            className={`${styles.socialImage} ${
              fileURLs.length || stringMultiples.length ? styles.bgImage : ""
            }`}
          >
            {!isEdit ? (
              <div className={styles.stringImages}>{fileURLs}</div>
            ) : (
              <div className={styles.stringImages}>
                {stringFileURLs}
                {fileURLs}
              </div>
            )}
          </div>
        </div>
        <input
          ref={(el) => {
            if (el && inputFilesRefs.current) inputFilesRefs.current.push(el);
          }}
          type="file"
          multiple
          id="fileMultipleInput"
          name="fileMultipleInput"
          accept="image/*, video/*"
          onChange={handleMultipleEventsNewsChange}
        />
      </div>
    </div>
  );
});

export default React.memo(ModalBody);
