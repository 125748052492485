import React from "react";
import { TIconProps } from "../../@types/icon";

const VisionIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 191 200"
      fill="none"
    >
      <path
        d="M92.3179 1.74612C92.0798 2.77797 92.0004 8.33408 92.0798 14.2077C92.3179 23.3356 92.5561 24.923 93.7466 25.7168C94.6991 26.3517 95.4929 26.3517 96.5247 25.7168C97.6359 24.923 97.874 23.3356 98.1122 14.2077C98.3503 0.952395 98.1915 -8.03024e-05 95.096 -8.03024e-05C93.3498 -8.03024e-05 92.6354 0.396784 92.3179 1.74612Z"
        fill={fill}
      />
      <path
        d="M45.8847 11.5092C45.1704 12.3823 45.091 13.2554 45.6466 14.5253C51.5996 27.4631 55.0126 34.1304 55.9651 34.4479C57.6319 35.1623 60.1719 33.4955 60.1719 31.7493C60.1719 30.9555 57.9494 25.7963 55.2507 20.3196C51.2027 11.9854 50.0915 10.3186 48.6628 10.3186C47.6309 10.3186 46.4403 10.8742 45.8847 11.5092Z"
        fill={fill}
      />
      <path
        d="M134.862 20.3195C132.163 25.8756 130.02 31.0348 130.02 31.8285C130.02 32.6223 130.734 33.6541 131.687 34.1304C134.227 35.4797 135.576 33.7335 140.735 22.6213C144.545 14.3665 145.18 12.5409 144.307 11.5091C143.752 10.8741 142.482 10.3185 141.529 10.3185C139.942 10.3185 138.989 11.8266 134.862 20.3195Z"
        fill={fill}
      />
      <path
        d="M7.54759 42.7027C6.75386 43.5758 6.357 44.7664 6.59512 45.4807C7.15073 46.7507 22.7078 59.2122 24.8509 60.006C26.5177 60.641 28.4227 58.9741 28.4227 56.8311C28.4227 55.6405 26.0415 53.3386 19.8504 48.6556C15.1674 45.0839 10.8019 41.9089 10.0875 41.6708C9.45255 41.4327 8.26195 41.9089 7.54759 42.7027Z"
        fill={fill}
      />
      <path
        d="M173.04 46.592C162.166 54.9262 161.769 55.3231 161.769 56.9899C161.769 58.9742 163.754 60.6411 165.341 60.0061C167.484 59.2123 183.041 46.7508 183.597 45.4808C183.994 44.2902 182.009 41.2741 180.739 41.2741C180.422 41.2741 176.93 43.6552 173.04 46.592Z"
        fill={fill}
      />
      <path
        d="M83.9837 42.5438C72.1572 44.6868 63.823 47.6236 52.2346 53.656C37.5506 61.2758 20.6441 74.7692 8.4207 88.5007C0.404027 97.4699 -0.469075 98.7398 0.165909 100.962C0.404028 101.994 3.97581 106.518 8.1032 111.122C28.5021 133.902 53.187 150.015 76.8402 156.047C81.5232 157.238 85.5712 157.555 95.4928 157.555C106.843 157.476 108.986 157.317 116.13 155.333C125.893 152.634 139.783 146.046 149.863 139.379C160.023 132.553 173.596 120.805 181.612 111.757C189.788 102.55 190.661 101.28 190.026 99.0573C189.312 96.1205 175.739 81.4365 166.214 73.2611C155.023 63.657 144.783 56.8309 133.195 51.2748C119.702 44.7662 111.606 42.7819 97.4771 42.4644C91.1273 42.3057 85.095 42.3057 83.9837 42.5438ZM115.336 51.116C125.575 54.0528 141.529 62.3076 151.848 69.9274C160.499 76.3566 183.2 98.1048 183.2 100.01C183.2 101.915 160.499 123.663 151.848 130.092C141.688 137.633 125.734 145.887 115.336 148.983C108.827 150.888 107.002 151.126 95.096 151.126C83.1106 151.126 81.3644 150.967 74.8559 148.983C64.2199 145.729 48.504 137.633 38.3443 130.092C29.6133 123.584 6.99198 101.915 6.99198 100.01C6.99198 98.1048 29.6133 76.3566 38.3443 69.9274C51.9171 59.9264 66.9186 52.7829 80.8882 49.608C89.143 47.703 106.367 48.4967 115.336 51.116Z"
        fill={fill}
      />
      <path
        d="M87.9524 57.9423C78.7451 59.6092 71.1253 63.8159 64.458 70.8007C56.2826 79.2936 52.7108 88.1834 52.7108 100.01C52.7108 111.916 56.2826 120.806 64.6961 129.457C73.189 138.268 82.8725 142.395 95.096 142.395C107.319 142.395 117.003 138.268 125.496 129.457C133.909 120.806 137.481 111.916 137.481 100.01C137.481 88.104 133.909 79.2143 125.496 70.5626C120.178 65.0065 115.495 62.0697 108.113 59.5298C102.954 57.7042 93.1116 56.9105 87.9524 57.9423ZM101.525 64.2922C116.606 67.0702 128.591 79.6905 131.052 95.2476C134.624 118.266 112.876 139.696 89.8574 135.887C70.0935 132.632 55.9651 113.662 59.14 94.692C62.6324 74.3725 81.8407 60.641 101.525 64.2922Z"
        fill={fill}
      />
      <path
        d="M87.635 78.2617C81.9995 80.0079 75.0147 87.1514 73.3478 92.8663C71.7604 98.2637 71.7604 101.756 73.3478 107.153C75.094 113.106 81.9995 120.012 87.9524 121.758C97.0803 124.457 104.303 122.711 111.05 115.964C117.797 109.217 119.543 101.994 116.844 92.8663C115.098 86.9133 108.193 80.0079 102.24 78.2617C97.001 76.6742 92.7942 76.7536 87.635 78.2617ZM101.287 84.6115C115.098 90.3264 115.098 109.693 101.287 115.408C90.7305 119.853 78.5071 111.678 78.4277 100.169C78.3483 88.5008 90.6511 80.0873 101.287 84.6115Z"
        fill={fill}
      />
      <path
        d="M93.191 90.882C91.286 92.8663 91.921 94.6125 95.0166 96.1206C96.604 96.835 98.2709 98.5018 98.9852 100.089C100.493 103.185 102.24 103.82 104.224 101.915C106.367 99.7718 104.303 94.2157 100.414 91.7551C97.3978 89.7708 94.6991 89.4533 93.191 90.882Z"
        fill={fill}
      />
      <path
        d="M15.9611 146.443C11.1193 150.253 6.91258 153.904 6.59509 154.539C6.35697 155.333 6.75384 156.523 7.70631 157.476C9.29377 158.984 9.29377 158.984 12.3099 157C17.8661 153.269 27.7083 145.173 28.1845 143.903C28.7401 142.395 27.4702 140.173 25.8827 139.855C25.2477 139.776 20.8028 142.713 15.9611 146.443Z"
        fill={fill}
      />
      <path
        d="M162.722 140.649C160.499 142.871 161.849 144.856 169.072 150.412C173.04 153.428 177.326 156.682 178.596 157.476C180.898 158.984 180.977 158.984 182.486 157.396C183.438 156.523 183.835 155.333 183.597 154.539C182.962 153.031 165.976 139.696 164.627 139.696C164.071 139.696 163.277 140.093 162.722 140.649Z"
        fill={fill}
      />
      <path
        d="M55.2507 166.207C54.6158 166.763 52.0758 171.763 49.5359 177.24C45.6466 185.574 45.0116 187.479 45.8847 188.511C46.4403 189.146 47.6309 189.701 48.6628 189.701C50.0915 189.701 51.2027 188.034 55.2507 179.7C57.9494 174.224 60.1719 169.064 60.1719 168.271C60.1719 167.397 59.4575 166.366 58.6638 165.889C56.6795 164.858 56.5207 164.858 55.2507 166.207Z"
        fill={fill}
      />
      <path
        d="M131.449 165.969C129.226 167.239 129.703 169.144 134.941 179.7C138.989 188.034 140.1 189.701 141.529 189.701C142.561 189.701 143.752 189.146 144.307 188.511C145.18 187.479 144.545 185.574 140.656 177.24C135.02 165.016 134.386 164.223 131.449 165.969Z"
        fill={fill}
      />
      <path
        d="M92.8736 174.779C91.8417 175.811 91.4448 196.289 92.3973 198.75C93.0323 200.417 97.1597 200.417 97.7947 198.75C98.0328 198.036 98.2709 192.638 98.2709 186.606C98.2709 175.494 97.8741 173.827 95.096 173.827C94.3816 173.827 93.4292 174.224 92.8736 174.779Z"
        fill={fill}
      />
    </svg>
  );
};

export default VisionIcon;
