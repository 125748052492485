import React from "react";
import DeleteIcon from "../../../../components/icons/admin/DeleteIcon";
import EditIcon from "../../../../components/icons/admin/EditIcon";
import styles from "../tableTheme.module.scss";

const TableBody: React.FC<{
  getEditItem: (type: string, row: any) => void;
  bodyRows: any[];
}> = ({ bodyRows, getEditItem }) => {
  const onEditItem = (type: string, row: any) => {
    getEditItem(type, row);
  };

  return (
    <>
      {bodyRows?.length
        ? bodyRows.map((row, index) => (
            <tr key={index}>
              {Object.keys(bodyRows?.[0]).map(
                (property: string, propertyIndex: number) => (
                  <td key={propertyIndex}>
                    {property === "image_url" || property === "logo" ? (
                      <img src={row[property]} alt="" title="" width="50" />
                    ) : (
                      row[property]
                    )}
                  </td>
                )
              )}
              <td>
                <div className={styles.actions}>
                  <div onClick={() => onEditItem("edit", row)}>
                    <EditIcon width="20px" height="20px" fill="#333333" />
                  </div>
                  <div onClick={() => onEditItem("deleted", row)}>
                    <DeleteIcon width="20px" height="20px" fill="#e52020" />
                  </div>
                </div>
              </td>
            </tr>
          ))
        : null}
    </>
  );
};

export default TableBody;
