import React from "react";
import styles from "./EventCard.module.scss";
import { TMediaProps, TTabProps } from "../../@types/type";
import HrefIcon from "../icons/HrefIcon";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumb } from "../../app/features/breadcrumbSlice";
import { capitalizeFirstLetter, fDateTime } from "../../utils/formats";
import { RootState } from "../../app/store";
import { REACT_APP_AMAZON_S3_URL } from "../../constants/constant";

const EventCard = (props: TMediaProps) => {
  const { id, isBreadcrumb, type } = props;
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedType } = useSelector((state: RootState) => state.tab);
  const tabs: TTabProps[] = t("media_page.tab", { returnObjects: true });

  const truncatedBody =
    props[`body_${i18n.language}` as keyof TMediaProps];

  const contentToDisplay =
    typeof truncatedBody === "string" ? truncatedBody : String(truncatedBody);

  const handleEventsDetailsClick = (id: number) => {
    let name: string = "";
    tabs.forEach((value) => {
      if (value.value === selectedType) {
        name = value.label;
      }
    });
    if (isBreadcrumb) {
      dispatch(
        setBreadcrumb({
          url: "/media",
          // name: t("links.events_news_text"),
          // key: "links.events_news_text",
          name: t("links.media_text"),
          key: "links.media_text",
        })
      );
      dispatch(
        setBreadcrumb({
          name: capitalizeFirstLetter(type),
          key: type,
        })
      );
    } else {
      dispatch(
        setBreadcrumb({
          name: capitalizeFirstLetter(name),
          key: selectedType,
        })
      );
    }
    navigate(`/media/${id}`);
  };

  return (
    <div className={styles.eventCard}>
      <div className={styles.cardImg}>
        <img
          src={`${REACT_APP_AMAZON_S3_URL}/${props.image}`}
          alt="card_image"
        />
        {props.show ? (
          <div className={styles.imgDate}>
            {fDateTime({
              date: new Date(props.date_time),
              newFormat: "MMMM d, yyyy",
            })}
          </div>
        ) : null}
      </div>
      <div
        className={`${styles.cardInfo} ${props.show ? styles.isShowInfo : ""}`}
      >
        {props.show ? (
          <div className={styles.cardTitle}>
            {props[`title_${i18n.language}` as keyof TMediaProps]}
          </div>
        ) : null}
        {!props.show ? (
          <div className={styles.cardDate}>
            {fDateTime({
              date: new Date(props.date_time),
              newFormat: "MMMM d, yyyy",
            })}
          </div>
        ) : null}
        <div
          className={styles.cardBody}
          dangerouslySetInnerHTML={{
            __html:
              contentToDisplay.length > 100
                ? `${contentToDisplay.substring(0, 100)}...`
                : contentToDisplay,
          }}
        />
        <div className={styles.readMore}>
          {i18n.dir() === "rtl" && (
            <HrefIcon width="12px" height="13px" fill="#333333" />
          )}
          <span onClick={() => id !== null && handleEventsDetailsClick(id)}>
            {t("read_more_text")}
          </span>
          {i18n.dir() === "ltr" && (
            <HrefIcon width="12px" height="13px" fill="#333333" />
          )}
        </div>
      </div>
    </div>
  );
};

export default EventCard;
