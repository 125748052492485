import { useTranslation } from "react-i18next";
import styles from "../JoinUs.module.scss";

const SuccessMessage = () => {
    const { t } = useTranslation();

    return <div className={styles.container}>
        <h2>{t("join_us.success_message.title")}</h2>
        <p>{t("join_us.success_message.membershipNumber")}</p>
        <div className={styles.benefits}>
            <p>{t("join_us.success_message.benefitsList")}</p>
            <ul>
                <li>{t("join_us.success_message.benefitItem1")}</li>
                <li>{t("join_us.success_message.benefitItem2")}</li>
                <li>{t("join_us.success_message.benefitItem3")}</li>
                <li>{t("join_us.success_message.benefitItem4")}</li>
                <li>{t("join_us.success_message.benefitItem5")}</li>
            </ul>
        </div>
        <p className={styles.whatsappLink} dangerouslySetInnerHTML={{ __html: t("join_us.success_message.whatsappLink", { here: '<a target="_blank" href="https://chat.whatsapp.com/LpX8UhJTVCy7NWAdAjtdUT">' + t("join_us.success_message.here") + '</a>' }) }}></p>
    </div>
}

export default SuccessMessage