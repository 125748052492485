import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/store";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import { setUserFilterDetails } from "../../../../app/features/usersSlice";
import styles from "./ModalBody.module.scss";

const ModalBody = () => {
    const { userFilterDetails } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch()

    const handleFilterUserDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        dispatch(setUserFilterDetails({key: id, value}))
    }

    return (
        <div className={styles.modalBody}>
            <div className={styles.inputRadioField}>
                <div className={styles.radioTitle}>
                    <span>Is Email Verified ?</span>
                </div>
                <div className={styles.radioField}>
                    <RadioButton
                        id="isEmailConfirmed"
                        label={"Yes"}
                        value="true"
                        name="isEmailConfirmed"
                        checked={userFilterDetails.isEmailConfirmed === "Yes"}
                        onChange={handleFilterUserDetailsChange}
                    />
                    <RadioButton
                        id="isEmailConfirmed"
                        label={"No"}
                        value="false"
                        name="isEmailConfirmed"
                        checked={userFilterDetails.isEmailConfirmed  === "No"}
                        onChange={handleFilterUserDetailsChange}
                    />
                </div>
            </div>
            <div className={styles.inputRadioField}>
                <div className={styles.radioTitle}>
                    <span>Is Phone Verified ?</span>
                </div>
                <div className={styles.radioField}>
                    <RadioButton
                        id="isPhoneConfirmed"
                        label={"Yes"}
                        value="true"
                        name="isPhoneConfirmed"
                        checked={userFilterDetails.isPhoneConfirmed === "Yes"}
                        onChange={handleFilterUserDetailsChange}
                    />
                    <RadioButton
                        id="isPhoneConfirmed"
                        label={"No"}
                        value="false"
                        name="isPhoneConfirmed"
                        checked={userFilterDetails.isPhoneConfirmed  === "No"}
                        onChange={handleFilterUserDetailsChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default ModalBody