import React from "react";
import styles from "./Modal.module.scss";
import Button from "../Button/Button";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import AdminButton from "../AdminButton/AdminButton";
import { RootState } from "../../app/store";
import { useSelector } from "react-redux";

type TModalProps = {
  isOpen: boolean;
  btnText?: string;
  onClose: () => void;
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  onOutside?: boolean;
  type?: "admin";
  maxWidth?: string;
};

const Modal: React.FC<TModalProps> = ({
  isOpen,
  btnText,
  onClose,
  onClick,
  children,
  disabled,
  onOutside,
  type,
  maxWidth,
}) => {
  const ref = useOutsideClick(() => {
    onClose();
  });
  const { disabledBtn } = useSelector((state: RootState) => state.disabled);

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div ref={onOutside ? ref : null} className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <span className={styles.closeButton} onClick={onClose}>
            &times;
          </span>
        </div>
        <div className={styles.modalBody} style={{ maxWidth: maxWidth }}>
          {children}
        </div>
        <div className={styles.modalFooter}>
          {type === "admin" ? (
            <>
              <AdminButton onClick={onClose}>Cancel</AdminButton>
              {btnText && (
                <AdminButton
                  onClick={onClick}
                  disabled={disabledBtn || disabled}
                >
                  {btnText}
                </AdminButton>
              )}
            </>
          ) : (
            <>
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={onClick} disabled={disabledBtn || disabled}>
                {btnText}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
