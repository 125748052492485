import { ActionReducerMapBuilder, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TUsersState } from "../../@types/type";
import { exportUsersCsv, getAllUsers, searchUser, uploadUsersCsv, filterUsersData } from "../api";

const initialState: TUsersState = {
  usersList: [],
  allUsersForCsv: [],
  userFilterDetails: {
    isEmailConfirmed: "",
    isPhoneConfirmed: ""
  },
  meta: null,
  isFilteredData: false,
  fetchAgain: true,
  isLoading: false,
  isError: false,
}

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateUsersFetchAgain: (state) => {
      state.fetchAgain = true;
    },
    setUserFilterDetails: (
      state: TUsersState,
      action: PayloadAction<any>
    ) => {
      const { key, value } = action.payload;
      state.userFilterDetails = { ...state.userFilterDetails, [key]: value };
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder.addCase(getAllUsers.pending, (state: TUsersState) => {
      state.isLoading = true
    }).addCase(getAllUsers.fulfilled, (state: TUsersState, action: PayloadAction<any>) => {
      state.isLoading = false
      state.fetchAgain = false;
      const { data, status } = action.payload;
      if (status === 200) {
        state.usersList = data.data.data;
        state.meta = data.data.meta;
      }
    }).addCase(getAllUsers.rejected, (state: TUsersState) => {
      state.isError = true
      state.isLoading = false
    })

    builder.addCase(uploadUsersCsv.pending, (state: TUsersState) => {
      state.isLoading = true
    }).addCase(uploadUsersCsv.fulfilled, (state: TUsersState, action: PayloadAction<any>) => {
      state.isLoading = false
      const usersDataClone = [...state.usersList]
      const newUsersData = action?.payload?.data?.data
      const updatedUsersData = usersDataClone.concat(newUsersData)
      state.usersList = updatedUsersData
    }).addCase(uploadUsersCsv.rejected, (state: TUsersState) => {
      state.isLoading = false
      state.isError = true
    })

    builder.addCase(searchUser.pending, (state: TUsersState) => {
      state.isLoading = true
      state.fetchAgain = false;
    }).addCase(searchUser.fulfilled, (state: TUsersState, action: PayloadAction<any>) => {
      state.fetchAgain = false;
      state.isLoading = false
      const serachResults = action?.payload?.data;
      if (action?.payload.status === 200) {
        state.usersList = serachResults.data.data
        state.meta = serachResults.data.meta;
      }
    }).addCase(searchUser.rejected, (state: TUsersState) => {
      state.isLoading = false
      state.isError = true
    })

    builder.addCase(exportUsersCsv.fulfilled, (state: TUsersState, action: PayloadAction<any>) => {
      state.allUsersForCsv = action?.payload?.data;
    })

    builder.addCase(filterUsersData.pending, (state: TUsersState) => {
      state.isLoading = true
      state.fetchAgain = false;
    }).addCase(filterUsersData.fulfilled, (state: TUsersState, action: PayloadAction<any>) => {
      const {data} = action?.payload?.data
      if(action?.payload.status === 200) {
        state.usersList = data?.data
        state.meta = data?.meta
        state.isFilteredData = true
        state.fetchAgain = false;
      }
      state.isLoading = false
    }).addCase(filterUsersData.rejected, (state: TUsersState) => {
      state.isLoading = false
    })
  }
})

export const { updateUsersFetchAgain, setUserFilterDetails } = usersSlice.actions;
export default usersSlice.reducer;