import React from "react";
import { TIconProps } from "../../../@types/icon";

const DeleteIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
    >
      <path
        d="M58.3333 175C53.7499 175 49.8249 173.367 46.5583 170.1C43.2916 166.833 41.661 162.911 41.6666 158.333V50H33.3333V33.3333H74.9999V25H125V33.3333H166.667V50H158.333V158.333C158.333 162.917 156.7 166.842 153.433 170.108C150.167 173.375 146.244 175.006 141.667 175H58.3333ZM141.667 50H58.3333V158.333H141.667V50ZM74.9999 141.667H91.6666V66.6667H74.9999V141.667ZM108.333 141.667H125V66.6667H108.333V141.667Z"
        fill={fill}
      />
    </svg>
  );
};

export default DeleteIcon;
