import React from "react";
import { TIconProps } from "../../../@types/icon";

const RoleIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
    >
      <path
        d="M191.667 158.333C191.667 167.174 188.155 175.652 181.904 181.904C175.652 188.155 167.174 191.667 158.333 191.667H141.667V175H158.333C162.754 175 166.993 173.244 170.118 170.118C173.244 166.993 175 162.754 175 158.333C175 153.913 173.244 149.674 170.118 146.548C166.993 143.423 162.754 141.667 158.333 141.667H141.667V125H158.333C167.174 125 175.652 128.512 181.904 134.763C188.155 141.014 191.667 149.493 191.667 158.333ZM75 158.333C75 149.493 78.5119 141.014 84.7631 134.763C91.0143 128.512 99.4928 125 108.333 125H125V141.667H108.333C103.913 141.667 99.6738 143.423 96.5482 146.548C93.4226 149.674 91.6667 153.913 91.6667 158.333C91.6667 162.754 93.4226 166.993 96.5482 170.118C99.6738 173.244 103.913 175 108.333 175H125V191.667H108.333C99.4928 191.667 91.0143 188.155 84.7631 181.904C78.5119 175.652 75 167.174 75 158.333Z"
        fill={fill}
      />
      <path
        d="M116.667 150H150V166.667H116.667V150ZM75 41.6666C70.0555 41.6666 65.222 43.1329 61.1107 45.8799C56.9995 48.6269 53.7952 52.5314 51.903 57.0995C50.0108 61.6677 49.5157 66.6944 50.4804 71.5439C51.445 76.3934 53.826 80.848 57.3223 84.3443C60.8186 87.8406 65.2732 90.2216 70.1227 91.1863C74.9723 92.1509 79.9989 91.6558 84.5671 89.7636C89.1352 87.8714 93.0397 84.6671 95.7867 80.5559C98.5338 76.4447 100 71.6112 100 66.6666C99.9802 60.0423 97.3399 53.6949 92.6558 49.0108C87.9717 44.3267 81.6243 41.6864 75 41.6666ZM75 75C73.3518 75 71.7407 74.5112 70.3702 73.5955C68.9998 72.6799 67.9317 71.3784 67.301 69.8557C66.6703 68.3329 66.5052 66.6574 66.8268 65.0409C67.1483 63.4244 67.942 61.9395 69.1074 60.7741C70.2729 59.6086 71.7577 58.815 73.3742 58.4934C74.9908 58.1719 76.6663 58.3369 78.189 58.9676C79.7117 59.5984 81.0132 60.6665 81.9289 62.0369C82.8446 63.4073 83.3333 65.0184 83.3333 66.6666C83.3267 68.8747 82.4466 70.9905 80.8853 72.5519C79.3239 74.1133 77.2081 74.9934 75 75ZM44.25 125C53.4261 119.076 64.0791 115.842 75 115.667C77.1708 115.694 79.3371 115.873 81.4833 116.2C89.4955 111.061 98.8147 108.331 108.333 108.333H110.45C99.5309 102.491 87.3818 99.3204 75 99.0833C58.0833 99.0833 25 108 25 128.917V141.667H61.2417C63.4201 135.517 66.7835 129.855 71.1417 125H44.25Z"
        fill={fill}
      />
      <path
        d="M133.333 16.6684H98.5C96.7936 11.797 93.6156 7.57633 89.4056 4.59017C85.1955 1.60402 80.1615 0 75 0C69.8384 0 64.8045 1.60402 60.5944 4.59017C56.3844 7.57633 53.2063 11.797 51.5 16.6684H16.6667C12.2504 16.6815 8.01888 18.4417 4.89612 21.5645C1.77337 24.6872 0.0131894 28.9188 0 33.335L0 150.002C0.0131894 154.418 1.77337 158.649 4.89612 161.772C8.01888 164.895 12.2504 166.655 16.6667 166.668H59.5083C57.9369 161.224 57.9369 155.446 59.5083 150.002H16.6667V33.335H133.333V108.335H150V33.335C149.987 28.9188 148.227 24.6872 145.104 21.5645C141.981 18.4417 137.75 16.6815 133.333 16.6684ZM75 27.085C73.3464 27.0719 71.7643 26.4093 70.595 25.24C69.4258 24.0707 68.7631 22.4886 68.75 20.835C68.75 19.1774 69.4085 17.5877 70.5806 16.4156C71.7527 15.2435 73.3424 14.585 75 14.585C76.6576 14.585 78.2473 15.2435 79.4194 16.4156C80.5915 17.5877 81.25 19.1774 81.25 20.835C81.2369 22.4886 80.5742 24.0707 79.405 25.24C78.2357 26.4093 76.6536 27.0719 75 27.085Z"
        fill={fill}
      />
    </svg>
  );
};

export default RoleIcon;
