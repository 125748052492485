import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { OrderType } from "../enums/enum";
import server from "./server";
import {
  TMediaGetApiProps,
  TFindByIdApiProps,
  TSignInApiProps,
  TPublicationCreateApiProps,
  TListGetApiProps,
  TEventNewsApiProps,
  TContactUsApiProps,
  TContactUsSendEmailProps,
  TAboutUsApiProps,
  TRoleProps,
  TNewsletterCreateApiProps,
} from "../@types/type";

// register memeber
export const registerUser = createAsyncThunk(
  "user/register-member",
  async (query: any) => {
    try {
      const response = await server.post("user/register-member", query);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// update user 
export const updateUserData = createAsyncThunk(
  "user/update-user",
  async (query: any) => {
    try {
      const response = await server.post("user/update-user", query)
      return response
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
)

// get loggedIn member
export const getLoggedInUser = createAsyncThunk(
  "user/get-loggedinuser",
  async (query: string) => {
    try {
      const response = await server.get("user/get-loggedinuser", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${query}`,
        },
      });
      return response
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
)
// filter users
export const filterUsersData = createAsyncThunk(
  "user/filterUsersData",
  async (query: any) => {
    try {
      const response = await server.get(`user/filterUsersData?isEmailConfirmed=${query.isEmailConfirmed}&isPhoneConfirmed=${query.isPhoneConfirmed}&order=${query.order || OrderType.DESC}&page=${query.page || 1
        }&take=${query.take || 10}`);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
)

// get member by Id
export const getMemberById = createAsyncThunk(
  "user/findById",
  async (query: TFindByIdApiProps) => {
    try {
      const response = await server.get(`user/findById/${query.id}`);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// get all users
export const getAllUsers = createAsyncThunk(
  "user/list",
  async (query: TListGetApiProps) => {
    try {
      const response = await server.get(
        `/user/list?order=${query.order || OrderType.DESC}&page=${query.page || 1
        }&take=${query.take || 10}`
      );
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
)

// search user
export const searchUser = createAsyncThunk(
  "user/search",
  async (query: any) => {
    try {
      const response = server.get(`/user/search?searchText=${query}&order=${query.order || OrderType.DESC}&page=${query.page || 1
        }&take=${query.take || 10}`);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
)

//upload users csv
export const uploadUsersCsv = createAsyncThunk(
  "user/uploadUsersCsv",
  async (query: any) => {
    try {
      const response = await server.post('/user/uploadUsersCsv', query)
      return response
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
)

//upload users csv with no membershipId
export const uploadUsersCsvWithNoMembershipId = createAsyncThunk(
  "user/uploadUsersCsvWithNoMembershipId",
  async (query: any) => {
    try {
      const response = await server.post('/user/uploadUsersCsvWithNoMembershipId', query)
      return response
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
)

//export users csv
export const exportUsersCsv = createAsyncThunk(
  "user/exportUsersCsv",
  async () => {
    try {
      return await server.get('/user/exportUsersCsv')
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
)

// confirm email
export const confirmEmail = createAsyncThunk(
  "user/confirm-email",
  async (query: any) => {
    try {
      const response = await server.post("user/confirm-email", query);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// resend verification link
export const resendVerificationLink = createAsyncThunk(
  "user/resend-verification-link",
  async (query: any) => {
    try {
      const response = await server.get(
        `user/resend-verification-link/${query}`
      );
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// verify user email
export const verifyUserEmail = createAsyncThunk(
  "user/verify-user-email",
  async (query: any) => {
    try {
      const response = await server.post("user/verify-user-email", query);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
)

// send verification code
export const sendVerificationCode = createAsyncThunk(
  "user/send-verification-link",
  async (query: any) => {
    try {
      const response = await server.post("user/send-verification-code", query);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// confirm verification code
export const confirmVerificationCode = createAsyncThunk(
  "user/confirm-verification-code",
  async (query: any) => {
    try {
      const response = await server.post(
        "user/confirm-verification-code",
        query
      );
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// change phone number
export const updatePhoneNumber = createAsyncThunk(
  "user/change-phone-number",
  async (query: any) => {
    try {
      const response = await server.post("user/change-phone-number", query);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

//  get current user
export const getCurrentUser = createAsyncThunk("user/me", async () => {
  try {
    const response = await server.get("/user/me");
    return response;
  } catch (err) {
    const error = err as AxiosError;
    return error;
  }
});

// slide
export const slideList = createAsyncThunk("slide/list", async (query: any) => {
  try {
    const response = await server.get(
      `/slide/list?order=${query.order || OrderType.DESC}&page=${query.page || 1
      }&take=${query.take || 10}`
    );
    return response;
  } catch (err) {
    const error = err as AxiosError;
    return error;
  }
});

// partner
export const partnerList = createAsyncThunk(
  "partner/list",
  async (query: any) => {
    try {
      const response = await server.get(
        `/partner/list?order=${query.order || OrderType.DESC}&page=${query.page || 1
        }&take=${query.take || 10}`
      );
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// media list
export const mediaList = createAsyncThunk(
  "media/list",
  async (query: TMediaGetApiProps) => {
    try {
      const type = query.type;
      if (type) {
        const response = await server.get(
          `/media/list?type=${query.type}&order=${query.order || OrderType.DESC
          }&page=${query.page || 1}&take=${query.take || 10}`
        );
        return { response, type: query.type };
      } else {
        const response = await server.get(
          `/media/list?order=${query.order || OrderType.ASC}&page=${query.page || 1
          }&take=${query.take || 10}`
        );
        return { response, type: query.type };
      }
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// media find by id
export const mediaFindById = createAsyncThunk(
  "media/findById",
  async (query: TFindByIdApiProps) => {
    try {
      const response = await server.get(`media/findById/${query.id}`);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// create media
export const createMedia = createAsyncThunk(
  "media/create",
  async (query: any) => {
    try {
      const response = await server.post("media/create", query);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// update media
export const mediaUpdateById = createAsyncThunk(
  "media/update",
  async (query: any) => {
    try {
      const response = await server.patch(
        `media/update/${query.id}`,
        query.data
      );
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// delete media
export const mediaDeleteById = createAsyncThunk(
  "media/delete",
  async (query: any) => {
    try {
      const response = await server.delete(`media/delete/${query.id}`);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// sign in
export const signIn = createAsyncThunk(
  "auth/sign-in",
  async (query: TSignInApiProps) => {
    try {
      const response = await server.post("auth/sign-in", query);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// create publication
export const createPublication = createAsyncThunk(
  "publication/create",
  async (query: TPublicationCreateApiProps) => {
    try {
      const response = await server.post("publication/create", query);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// publication list
export const publicationList = createAsyncThunk(
  "publication/list",
  async (query: TListGetApiProps) => {
    try {
      const response = await server.get(
        `/publication/list?order=${query.order || OrderType.DESC}&page=${query.page || 1
        }&take=${query.take || 10}`
      );
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// publication find by id
export const publicationFindById = createAsyncThunk(
  "publication/findById",
  async (query: TFindByIdApiProps) => {
    try {
      const response = await server.get(`publication/findById/${query.id}`);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// update publication
export const publicationUpdateById = createAsyncThunk(
  "publication/update",
  async (query: any) => {
    try {
      const response = await server.patch(
        `publication/update/${query.id}`,
        query.data
      );
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// delete publication
export const publicationDeleteById = createAsyncThunk(
  "publication/delete",
  async (query: any) => {
    try {
      const response = await server.delete(`publication/delete/${query.id}`);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// create newsletter
export const createNewsletter = createAsyncThunk(
  "newsletter/create",
  async (query: TNewsletterCreateApiProps) => {
    try {
      const response = await server.post("newsletter/create", query);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// newsletter list
export const newsLetterList = createAsyncThunk(
  "newsletter/list",
  async (query: TListGetApiProps) => {
    try {
      const response = await server.get(
        `/newsletter/list?order=${query.order || OrderType.DESC}&page=${query.page || 1
        }&take=${query.take || 10}`
      );
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// remove newsletter
export const deleteNewsletterById = createAsyncThunk(
  "newsletter/remove",
  async (query: any) => {
    try {
      const response = await server.delete(`newsletter/remove/${query.id}`);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// update newsletter
export const updateNewsletterById = createAsyncThunk(
  "newsletter/updateById",
  async (query: any) => {
    try {
      const response = await server.patch(
        `newsletter/updateById/${query.id}`,
        query.data
      );
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// create event & news
export const createEventNews = createAsyncThunk(
  "events-news/create",
  async (query: TEventNewsApiProps) => {
    try {
      const response = await server.post("events-news/create", query);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// events news list
export const eventsNewsList = createAsyncThunk(
  "events-news/list",
  async (query: TListGetApiProps) => {
    try {
      const response = await server.get(
        `/events-news/list?order=${query.order || OrderType.DESC}&page=${query.page || 1
        }&take=${query.take || 10}`
      );
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// events news find by id
export const eventNewsFindById = createAsyncThunk(
  "events-news/findById",
  async (query: TFindByIdApiProps) => {
    try {
      const response = await server.get(`events-news/findById/${query.id}`);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// event news update by id
export const eventNewsUpdateById = createAsyncThunk(
  "events-news/update",
  async (query: any) => {
    try {
      const response = await server.patch(
        `events-news/update/${query.id}`,
        query.data
      );
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// delete event news 
export const eventNewsDeleteById = createAsyncThunk(
  "events-news/delete",
  async (query: any) => {
    try {
      const response = await server.delete(`events-news/delete/${query.id}`);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// contact-us create
export const createContactUs = createAsyncThunk(
  "contact-us/create",
  async (query: TContactUsApiProps) => {
    try {
      const response = await server.post("contact-us/create", query);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// contact-us list
export const contactUsList = createAsyncThunk("contact-us/find", async () => {
  try {
    const response = await server.get("contact-us/find");
    return response;
  } catch (err) {
    const error = err as AxiosError;
    return error;
  }
});

// contact-us send-email
export const contactUsSendEmail = createAsyncThunk(
  "contact-us/send-email",
  async (query: TContactUsSendEmailProps) => {
    try {
      const response = await server.post("contact-us/send-email", query);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// contact-us update
export const updateContactUs = createAsyncThunk(
  "contact-us/update",
  async (query: any) => {
    try {
      const response = await server.patch("contact-us/update", query);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// about-us create
export const createAboutUs = createAsyncThunk(
  "about-us/create",
  async (query: TAboutUsApiProps) => {
    try {
      const response = await server.post("about-us/create", query);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// about-us update
export const updateAboutUs = createAsyncThunk(
  "about-us/update",
  async (query: TAboutUsApiProps) => {
    try {
      const response = await server.patch("about-us/update", query);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// about-us find
export const aboutUsList = createAsyncThunk("about-us/find", async () => {
  try {
    const response = await server.get("about-us/find");
    return response;
  } catch (err) {
    const error = err as AxiosError;
    return error;
  }
});

// role list
export const roleList = createAsyncThunk(
  "role/list",
  async (query: TListGetApiProps) => {
    try {
      const response = await server.get(
        `role/list?order=${query.order || OrderType.ASC}&page=${query.page || 1
        }&take=${query.take || 10}`
      );
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// create role
export const createRole = createAsyncThunk(
  "role/create",
  async (query: TRoleProps) => {
    try {
      const response = await server.post("role/create", query);
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      return error;
    }
  }
);

// search
export const searchList = createAsyncThunk("search", async (query: any) => {
  try {
    const response = await server.get(
      `search?text=${query.text || ""}&order=${query.order || OrderType.ASC
      }&page=${query.page || 1}&take=${query.take || 10}`
    );
    return response;
  } catch (err) {
    const error = err as AxiosError;
    return error;
  }
});
