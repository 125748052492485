import React, { useEffect, useState } from "react";
import styles from "./SearchResult.module.scss";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import InputField from "../../components/InputField/InputField";
import SearchIcon from "../../components/icons/SearchIcon";
import Pagination from "../../components/Pagination/Pagination";
import { AppDispatch, RootState } from "../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { searchList } from "../../app/api";
import { clearSearchData } from "../../app/features/searchSlice";
import Loading from "../../components/Loading/Loading";
import { v4 as uuidv4 } from "uuid";
import { setBreadcrumb } from "../../app/features/breadcrumbSlice";
import { t } from "i18next";
import { capitalizeFirstLetter, getTextFormat } from "../../utils/formats";

type TResultProps = {
  id: number;
  title_ar: string;
  title_en: string;
  description_ar: string;
  description_en: string;
  type: string;
};

const SearchResult = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = searchParams.get("q");
  const dispatch: AppDispatch = useDispatch();
  const { i18n } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>("");
  const [loadDisabled, setLoadDisabled] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [take, setTake] = useState<number>(10);
  const { searchData, isLoading, hasNextPage } = useSelector(
    (state: RootState) => state.search
  );

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearchClick = async () => {
    dispatch(clearSearchData());
    if (searchValue) {
      const query = {
        text: searchValue,
        page,
        take,
      };
      await dispatch(searchList(query)).then((res: any) => {
        if (res.payload.status === 200) {
          const data = res.payload.data.data;
          navigate(`${pathname}?q=${searchValue}`, { state: data });
          if (!data.length) {
            setLoadDisabled(true);
          } else {
            setLoadDisabled(false);
          }
        }
      });
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearchClick();
    }
  };

  const handleItemClick = (item: TResultProps) => {
    const { id, type } = item;
    const evnetItem = getTextFormat(item.type);
    if (type.startsWith("events-news")) {
      dispatch(
        setBreadcrumb({
          url: "/events-news",
          name: t("links.events_news_text"),
          key: "links.events_news_text",
        })
      );
      dispatch(
        setBreadcrumb({
          name: capitalizeFirstLetter(evnetItem.type),
          key: type,
        })
      );
    } else if (type === "media") {
      dispatch(
        setBreadcrumb({
          url: "/media",
          name: t("links.media_text"),
          key: "links.media_text",
        })
      );
    } else {
      dispatch(
        setBreadcrumb({
          url: "/publication",
          name: t("links.publication_text"),
          key: "links.publication_text",
        })
      );
    }

    if (type.startsWith("events-news")) {
      navigate(`/${evnetItem.url}/${id}`);
    } else {
      navigate(`/${type}/${id}`);
    }
  };

  const searchLists = async () => {
    if (searchValue || params) {
      const query = {
        text: searchValue || params,
        page,
        take,
      };
      await dispatch(searchList(query)).then((res: any) => {
        if (res.payload.status === 200) {
          navigate(`${pathname}?q=${searchValue}`);
        }
      });
    }
  };

  useEffect(() => {
    if (!searchValue && params) {
      setSearchValue(params);
    }
    searchLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    dispatch(
      setBreadcrumb({
        url: "/search-result",
        name: t("search.name_text"),
        key: "search.name_text",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      setSearchValue("");
      dispatch(clearSearchData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`container ${styles.searchResult}`}>
      <h1 className={styles.title}>Search</h1>
      <div className={styles.searchField}>
        <InputField
          id="search"
          onKeyPress={handleKeyPress}
          value={searchValue}
          onChange={handleSearchChange}
          placeholder="Type a keyword to search here"
          inputIcon={<SearchIcon width="28px" height="28px" fill="#333333" />}
          handleInputIconClick={handleSearchClick}
        />
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.searchContent}>
          {searchData.length ? (
            searchData?.map((item) => {
              return (
                <div key={uuidv4()} className={styles.result}>
                  <h3 onClick={() => handleItemClick(item)}>
                    {item[`title_${i18n.language}` as keyof TResultProps]}
                  </h3>
                  <div>
                    {item[`description_${i18n.language}` as keyof TResultProps]}
                  </div>
                </div>
              );
            })
          ) : (
            <div className={styles.noResults}>No results</div>
          )}
          <div className={styles.pagination}>
            <Pagination
              itemsPerPage={page}
              currentPage={take}
              isShow={hasNextPage}
              disabled={loadDisabled}
              onLoadMore={handleLoadMore}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchResult;
