import { t } from "i18next";
import { TUserProps } from "../../../@types/type";
import Button from "../../../components/Button/Button";
import { REACT_APP_AMAZON_S3_URL } from "../../../constants/constant";
import defaultAvatar from "../../../assets/images/avatars/defaultavatar.png"
import styles from "../Profile.module.scss"
import { RootState } from "../../../app/store";
import { useSelector } from "react-redux";

type TMainInfoProps = {
    handleLogoutClick: () => void
};

const MainInfo: React.FC<TMainInfoProps> = ({ handleLogoutClick }) => {
    const {userInfo} = useSelector((state: RootState) => state.auth)

    return <div>
        {userInfo && (() => {
            const { firstName, lastName, email, profilePicture } = userInfo;

            return (
                <div className={styles.mainInfo}>
                    <div>
                        <div className={styles.imageContainer}>
                            {
                                profilePicture ? <img src={`${REACT_APP_AMAZON_S3_URL}/${profilePicture}`} alt="profile_picture" /> : <img src={defaultAvatar} alt="profile_picture" />
                            }
                        </div>
                        <h3>{firstName} {lastName}</h3>
                        <p>{email}</p>
                        <Button onClick={handleLogoutClick}>
                            {t("profile.logout")}
                        </Button>
                    </div>
                </div>
            );
        })()}
    </div>
}

export default MainInfo