import React from "react";
import { TIconProps } from "../../@types/icon";

const ShareIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
    >
      <path
        d="M150.017 179.167C144.239 179.167 139.319 177.139 135.258 173.083C131.197 169.039 129.167 164.122 129.167 158.333C129.167 157.222 129.264 156.075 129.458 154.892C129.647 153.703 129.933 152.586 130.317 151.542L65.8 113.525C63.7611 115.825 61.3888 117.617 58.6833 118.9C55.9833 120.189 53.0888 120.833 50 120.833C44.2111 120.833 39.2944 118.811 35.25 114.767C31.1944 110.717 29.1666 105.8 29.1666 100.017C29.1666 94.2389 31.1944 89.3195 35.25 85.2584C39.2888 81.1973 44.2055 79.1667 50 79.1667C53.0888 79.1667 55.9833 79.8112 58.6833 81.1C61.3888 82.3834 63.7611 84.175 65.8 86.475L130.317 48.4584C129.933 47.4139 129.647 46.2973 129.458 45.1084C129.264 43.925 129.167 42.7778 129.167 41.6667C129.167 35.8778 131.192 30.9612 135.242 26.9167C139.286 22.8612 144.2 20.8334 149.983 20.8334C155.761 20.8334 160.681 22.8584 164.742 26.9084C168.803 30.9528 170.833 35.8667 170.833 41.65C170.833 47.4278 168.806 52.3473 164.75 56.4084C160.706 60.4695 155.789 62.5 150 62.5C146.911 62.5 144.017 61.8556 141.317 60.5667C138.611 59.2834 136.239 57.4917 134.2 55.1917L69.6833 93.2084C70.0666 94.2528 70.3527 95.3639 70.5416 96.5417C70.7361 97.725 70.8333 98.8667 70.8333 99.9667C70.8333 101.067 70.7361 102.219 70.5416 103.425C70.3527 104.625 70.0666 105.747 69.6833 106.792L134.2 144.808C136.239 142.508 138.611 140.717 141.317 139.433C144.017 138.144 146.911 137.5 150 137.5C155.789 137.5 160.706 139.525 164.75 143.575C168.806 147.619 170.833 152.533 170.833 158.317C170.833 164.094 168.811 169.014 164.767 173.075C160.717 177.136 155.8 179.167 150.017 179.167Z"
        fill={fill}
      />
    </svg>
  );
};

export default ShareIcon;
