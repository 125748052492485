import React from "react";
import styles from "./Checkbox.module.scss";

type TCheckboxProps = {
  id: string;
  checked: boolean;
  value?: string;
  label?: string;
  forAdmin?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox = ({
  id,
  checked,
  value,
  label,
  forAdmin,
  onChange,
}: TCheckboxProps) => {
  return (
    <div className={styles.formCheckbox}>
      <input
        id={id}
        className={`${styles.checkbox} ${forAdmin ? styles.adminCheckbox : ""}`}
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Checkbox;
