import { ActionReducerMapBuilder, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TAboutUsApiProps } from "../../@types/type";
import { aboutUsList } from "../api";

type TAboutUsState = {
    aboutUsData: TAboutUsApiProps | null,
    isError: boolean | null;
    isLoading: boolean;
}

const initialState: TAboutUsState = {
    aboutUsData: null,
    isError: null,
    isLoading: false,
};

const aboutUsSlice = createSlice({
    name: 'about-us',
    initialState,
    reducers: {
    },
    extraReducers: (builder: ActionReducerMapBuilder<any>) => {
        builder.addCase(aboutUsList.pending, (state: TAboutUsState) => {
            state.isLoading = true;
        });
        builder.addCase(aboutUsList.fulfilled, (state: TAboutUsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            if (action.payload.status === 200) {
                state.aboutUsData = action.payload.data.data
            }
        });
        builder.addCase(aboutUsList.rejected, (state: TAboutUsState) => {
            state.isLoading = false;
            state.isError = true;
        });
    }
});

export default aboutUsSlice.reducer;