import React, { useEffect } from "react";
import styles from "./ModalBody.module.scss";
import InputField from "../../../../components/InputField/InputField";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import {
  attachmentsFilesFormat,
  truncateFormt,
} from "../../../../utils/formats";
import { v4 as uuidv4 } from "uuid";
import { REACT_APP_AMAZON_S3_URL } from "../../../../constants/constant";

const ModalBody = ({
  publicationForm,
  handleInputChange,
  handleUpload,
  selectedImage,
  inputRefs,
  handleMediaChange,
  handleCKEditorChange,
  inputPubFilesRefs,
  multiples,
  attachments,
  handelRemoveAttachment,
  handleMultipleMediaChange,
}: any) => {
  useEffect(() => {
    inputPubFilesRefs.current = inputPubFilesRefs.current.filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.publicationContent}>
      <div className={styles.title}>
        <div className={styles.inputField}>
          <InputField
            id="engTitle"
            value={publicationForm.engTitle}
            onChange={handleInputChange}
            type="string"
            label="Title (English)"
            required
            placeholder="Enter title"
            forAdmin
          />
        </div>
        <div className={styles.inputField}>
          <InputField
            id="arTitle"
            value={publicationForm.arTitle}
            onChange={handleInputChange}
            type="string"
            label="Title (Arabic)"
            required
            placeholder="Enter title"
            forAdmin
          />
        </div>
      </div>
      <div className={styles.thumbnailImageField}>
        <div className={styles.uploadContent}>
          <button onClick={handleUpload}>
            Upload thumbnail image
            <span>*</span>
          </button>
          <div className={styles.socialImage}>
            {selectedImage && (
              <div className={styles.image}>
                <img
                  src={
                    typeof selectedImage === "string"
                      ? `${REACT_APP_AMAZON_S3_URL}/${selectedImage}`
                      : URL.createObjectURL(selectedImage)
                  }
                  alt="Uploaded"
                />
              </div>
            )}
          </div>
        </div>
        <input
          ref={(el) => {
            inputRefs.current[0] = el!;
          }}
          type="file"
          id="fileInput"
          name="fileInput"
          accept="image/*"
          onChange={handleMediaChange}
        />
      </div>
      <div className={styles.ckEditor}>
        <div>
          <p className={styles.editorTitle}>
            Content (English)<span>*</span>
          </p>
          <CKEditor
            id="engContent"
            editor={ClassicEditor}
            config={{
              toolbar: [
                "heading",
                "|",
                "bold",
                "italic",
                "|",
                "bulletedList",
                "numberedList",
                "|",
                "insertTable",
                "|",
                "|",
                "|",
                "undo",
                "redo",
              ],
            }}
            data={publicationForm?.engContent || ""}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleCKEditorChange("engContent", data);
            }}
          />
        </div>
        <div>
          <p className={styles.editorTitle}>
            Content (Arabic)<span>*</span>
          </p>
          <CKEditor
            id="arContent"
            editor={ClassicEditor}
            config={{
              toolbar: [
                "heading",
                "|",
                "bold",
                "italic",
                "|",
                "bulletedList",
                "numberedList",
                "|",
                "insertTable",
                "|",
                "|",
                "|",
                "undo",
                "redo",
              ],
            }}
            data={publicationForm?.arContent || ""}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleCKEditorChange("arContent", data);
            }}
          />
        </div>
      </div>
      <div className={styles.attachment}>
        <div className={styles.uploadFilesContent}>
          <button onClick={() => inputPubFilesRefs.current[0].click()}>
            Upload files
          </button>
          <div className={styles.socialImage}>
            {multiples.map((file: File, index: number) => (
              <div key={uuidv4()}>
                <div>{truncateFormt(file.name)}</div>
                <div
                  className={styles.removeAttachment}
                  onClick={() => handelRemoveAttachment(index, "file")}
                >
                  x
                </div>
              </div>
            ))}
            {attachments &&
              attachments.map((item: string, index: number) => {
                const files = attachmentsFilesFormat(item);
                return (
                  <div key={uuidv4()}>
                    <div>{files?.fileName}</div>
                    <div
                      className={styles.removeAttachment}
                      onClick={() => handelRemoveAttachment(index, "string")}
                    >
                      x
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <input
          ref={(el) => {
            if (el) inputPubFilesRefs.current.push(el);
          }}
          type="file"
          multiple
          id="fileMultipleInput"
          name="fileMultipleInput"
          accept=".pdf, .doc, .docx, .xls, .xlsx, .csv, .zip"
          onChange={handleMultipleMediaChange}
        />
      </div>
      <div className={styles.category}>
        <p className={styles.checkboxLable}>
          Category<span>*</span>
        </p>
        <div className={styles.checkboxField}>
          <RadioButton
            id="articleId"
            label="Article"
            value="article"
            name="publicationType"
            checked={publicationForm.type === "article"}
            onChange={handleInputChange}
            forAdmin
          />
          <RadioButton
            id="studyId"
            label="Study"
            value="study"
            name="publicationType"
            checked={publicationForm.type === "study"}
            onChange={handleInputChange}
            forAdmin
          />
        </div>
      </div>
    </div>
  );
};

export default ModalBody;
