import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./EventsNewsList.module.scss";
import TableBody from "./TableBody/TableBody";
import Modal from "../../../components/Modal/Modal";
import ModalBody from "./ModalBody/ModalBody";
import VideoSnapshot from "video-snapshot";
import { TEventsNewsCardProps } from "../../../@types/type";
import { createEventNews, eventsNewsList, eventNewsUpdateById } from "../../../app/api";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import ArrowRightIcon from "../../../components/icons/ArrowRightIcon";
import ArrowLeftIcon from "../../../components/icons/ArrowLeftIcon";
import { updateEventNewsFetchAgain } from "../../../app/features/eventNewsSlice";
import Loading from "../../../components/Loading/Loading";

const headerColumns = [
  "ID",
  "Title",
  "Short Description",
  "Long Description",
  "Thumbnail Image",
  "Type",
  "Date",
  "Actions",
];

const EventsNews = () => {
  const dispatch: AppDispatch = useDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isEventsNewsFetching, setIsEventsNewsFetching] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [take, setTake] = useState<number>(3);
  const inputFilesRefs = useRef<HTMLInputElement[]>([]);
  const [thumbnailImage, setThumbnailImage] = useState<File | null>(null);
  const [multiples, setMultiples] = useState<File[]>([]);
  const [stringMultiples, setStringMultiples] = useState<string[]>([]);
  const [eventsNewsType, setEventsNewsType] = useState<string>("");
  const [isValidate, setIsValidate] = useState<boolean>(false);
  const [eventsNewsForm, setEventsNewsForm] = useState<TEventsNewsFormProps>({
    title_en: "",
    title_ar: "",
    short_description_en: "",
    short_description_ar: "",
    long_description_en: "",
    long_description_ar: "",
    type: "",
  });
  const { eventsNewsData, isLoading, fetchAgain, meta } = useSelector(
    (state: RootState) => state.event_news
  );
  const [isActiveId, setIsActiveId] = useState<number | null>(null);

  const { pageCount }: any = meta ?? {};

  const handleModalClose = () => {
    setMultiples([]);
    setIsEdit(false);
    setShowModal(false);
    setStringMultiples([]);
    setThumbnailImage(null);
    inputFilesRefs.current = [];
    setEventsNewsForm({
      title_en: "",
      title_ar: "",
      short_description_en: "",
      short_description_ar: "",
      long_description_en: "",
      long_description_ar: "",
      type: "",
    });
  };

  const handlePageClick = (newPage: any) => {
    setPage(newPage.selected + 1);
    dispatch(updateEventNewsFetchAgain());
  };

  const handleMultipleEventsNewsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles: File[] = [];
    let hasVideo = false;
    let hasImage = false;

    if (inputFilesRefs.current.length > 0) {
      inputFilesRefs.current.forEach((inputRef) => {
        const files = inputRef.files;

        if (files) {
          const newFiles = Array.from(files);
          const uniqueNewFiles = newFiles.filter(
            (file) =>
              !selectedFiles.some(
                (selectedFile) => selectedFile.name === file.name
              )
          );

          newFiles.forEach(async (file: File) => {
            const fileType = file.type.split("/")[0];
            setEventsNewsType(fileType);
            if (fileType === "video") {
              hasVideo = true;
              const snapshoter = new VideoSnapshot(files[0]);
              const previewSrc = await snapshoter.takeSnapshot();
              fetch(previewSrc)
                .then((res) => res.blob())
                .then((blob) => {
                  const thumbnail_image = new File([blob], "thumbnail", {
                    type: "image/png",
                  });
                  setThumbnailImage(thumbnail_image);
                });
            } else if (fileType === "image") {
              hasImage = true;
              setThumbnailImage(files[0]);
            }
          });

          if (
            (!hasVideo &&
              hasImage &&
              uniqueNewFiles.every((file) => file.type.startsWith("image/"))) ||
            (hasVideo &&
              !hasImage &&
              uniqueNewFiles.every((file) => file.type.startsWith("video/")))
          ) {
            selectedFiles.push(...uniqueNewFiles);
          }
        }
      });

      if (!isEdit && !hasImage) {
        setMultiples(selectedFiles);
      } else {
        if (hasImage) {
          setMultiples(selectedFiles);
        } else {
          setStringMultiples([]);
          setMultiples(selectedFiles);
        }
      }
    }
  };

  const handelRemoveAttachment = async (file: any, index: number) => {
    const updatedatTachment = [...multiples];
    const updatedatStringTachment = [...stringMultiples];

    const fileInTachment = updatedatTachment.find((item) => item === file);
    const fileInStringTachment = updatedatStringTachment.find(
      (item) => item === file
    );

    if (fileInStringTachment) {
      updatedatStringTachment.splice(index, 1);
      setStringMultiples(updatedatStringTachment);
    } else if (fileInTachment) {
      updatedatTachment.splice(index, 1);
      setMultiples(updatedatTachment);
    }
  };

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { id, value } = e.target;
      setEventsNewsForm({ ...eventsNewsForm, [id]: value });
    },
    [eventsNewsForm]
  );

  const handleEditRowClick = (row: TEventsNewsCardProps) => {
    inputFilesRefs.current = [];
    setIsEdit(true);
    setShowModal(true);
    setIsActiveId(row.id);
    setEventsNewsForm({ ...row });
    setStringMultiples(row.eventsNewsUrls);
  };

  const handleEditClick = async () => {
    const formData: any = new FormData();
    const thumbnail_image: File | null = thumbnailImage;
    const attachments: File[] = multiples;

    if (stringMultiples.length) {
      stringMultiples.forEach((file) => formData.append("eventsNewsUrls[]", file));
      formData.append("thumbnail_image", stringMultiples[0]);
    } else {
      formData.append("thumbnail_image", thumbnail_image);
    }
    if (attachments.length) {
      attachments.forEach((file) => formData.append("files", file));
    }

    formData.append("title_en", eventsNewsForm.title_en);
    formData.append("title_ar", eventsNewsForm.title_ar);
    formData.append("short_description_en", eventsNewsForm.short_description_en);
    formData.append("short_description_ar", eventsNewsForm.short_description_ar);
    formData.append("long_description_en", eventsNewsForm.long_description_en);
    formData.append("long_description_ar", eventsNewsForm.long_description_ar);
    formData.append("type", eventsNewsForm.type);

    const query = {
      id: isActiveId,
      data: formData,
    };

    await dispatch(eventNewsUpdateById(query)).then((res: any) => {
      if (res.payload.status === 200) {
        handleModalClose();
        toast.success(res.payload.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleCreateClick = async () => {
    const formData: any = new FormData();
    const thumbnail_image: File | null = thumbnailImage;
    const attachments: File[] = multiples;

    if (thumbnail_image !== null) {
      formData.append("thumbnail_image", thumbnail_image);
    }
    if (attachments.length) {
      attachments.forEach((file) => formData.append("files", file));
    }

    formData.append("title_en", eventsNewsForm.title_en);
    formData.append("title_ar", eventsNewsForm.title_ar);
    formData.append("short_description_en", eventsNewsForm.short_description_en);
    formData.append("short_description_ar", eventsNewsForm.short_description_ar);
    formData.append("long_description_en", eventsNewsForm.long_description_en);
    formData.append("long_description_ar", eventsNewsForm.long_description_ar);
    formData.append("type", eventsNewsForm.type);

    await dispatch(createEventNews(formData)).then((res: any) => {
      if (res.payload.status === 201) {
        handleModalClose();
        toast.success(res.payload.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const fetchEventsNewsData = useCallback(async () => {
    try {
      await dispatch(eventsNewsList({ page, take })).then((res: any) => {
        if (res.payload.status === 200) {
          setIsEventsNewsFetching(true);
        }
      });
    } catch (error: unknown) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (fetchAgain) {
      fetchEventsNewsData();
    }
  }, [fetchEventsNewsData, fetchAgain]);

  useEffect(() => {
    setIsEventsNewsFetching(true);
  }, [eventsNewsData]);

  useEffect(() => {
    if (eventsNewsType) {
      setEventsNewsForm({ ...eventsNewsForm, type: eventsNewsType });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsNewsType]);

  useEffect(() => {
    if (stringMultiples.length === 0 && eventsNewsForm) {
      if (eventsNewsForm.eventsNewsUrls || eventsNewsForm.thumbnail_image) {
        eventsNewsForm.eventsNewsUrls = [];
        eventsNewsForm.thumbnail_image = "";
      }
    }
  }, [stringMultiples, eventsNewsForm]);

  useEffect(() => {
    if (!multiples.length && !stringMultiples.length) {
      setIsValidate(true);
    } else {
      setIsValidate(false);
    }
  }, [stringMultiples, multiples]);

  return (
    <div className={`container ${styles.eventsNewsList}`}>
      <h2>Events & News</h2>
      <TableBody
        rowsData={eventsNewsData}
        headerColumns={headerColumns}
        setShowModal={setShowModal}
        handleEditClick={handleEditRowClick}
      />
      {!isEventsNewsFetching ? (
        <Loading />
      ) : (
        <Modal
          isOpen={showModal}
          btnText={isEdit ? "Edit" : "Create"}
          disabled={isValidate || isLoading}
          onClose={handleModalClose}
          onClick={!isEdit ? handleCreateClick : handleEditClick}
          onOutside
          type="admin"
        >
          <ModalBody
            isEdit={isEdit}
            eventsNewsForm={eventsNewsForm}
            multiples={multiples}
            stringMultiples={stringMultiples}
            inputFilesRefs={inputFilesRefs}
            handleInputChange={handleInputChange}
            handelRemoveAttachment={handelRemoveAttachment}
            handleMultipleEventsNewsChange={handleMultipleEventsNewsChange}
          />
        </Modal>
      )}
      {meta ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <ArrowRightIcon fill="#2065D1" width="30px" height="30px" />
          }
          onPageChange={(page) => handlePageClick(page)}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={
            <ArrowLeftIcon fill="#2065D1" width="30px" height="30px" />
          }
          renderOnZeroPageCount={null}
          className="pagination"
          previousClassName="prev"
        />
      ) : null}
    </div>
  );
};

export default EventsNews;
