import { useRef, useState } from 'react';
import styles from "./UsersTableFilters.module.scss"
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import { exportUsersCsv, filterUsersData, searchUser } from '../../../app/api';
import { CSVDownload } from "react-csv";
import FilterIcon from '../../../components/icons/admin/FilterIcon';
import Modal from '../../../components/Modal/Modal';
import ModalBody from './ModalBody/ModalBody';

const UsersTableFilters = () => {
  const csvRef: any = useRef(null)
  const dispatch = useDispatch<AppDispatch>()
  const { allUsersForCsv, isLoading, userFilterDetails } = useSelector(
    (state: RootState) => state.user
  );
  const [downloadCsv, setDownloadCsv] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false)

  const hadleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target.value
    if (value) {
      setSearchValue(value)
    }
  }

  const handleSearchClick = async () => {
    await dispatch(searchUser(searchValue))
  }

  const handleExportFile = async () => {
    await dispatch(exportUsersCsv())
    setDownloadCsv(true)
  }

  const handleCloseModal = () => {
    setShowFilterModal(false)
  }

  const handleOpenModal = () => {
    setShowFilterModal(true)
  }

  const filterUsers = async () => {
    await dispatch(filterUsersData({...userFilterDetails}))
  }


  return (
    <div className={styles.files}>
      {
        downloadCsv && <CSVDownload ref={csvRef} data={allUsersForCsv} target="_blank" />
      }
      <div className={styles.fileUpload}>
        <button onClick={handleExportFile}>Export CSV</button>
        <button onClick={handleOpenModal}>Filter <FilterIcon /></button>
      </div>
      <div className={styles.searchInput}>
        <input
          value={searchValue}
          placeholder={"Search by Company Name/Job Title"}
          onChange={hadleSearchChange}
        />
        <button className={styles.searchInputButton} onClick={handleSearchClick}>search</button>
      </div>
      <Modal
          isOpen={showFilterModal}
          btnText={"Filter"}
          onClose={handleCloseModal}
          onClick={filterUsers}
          disabled={isLoading}
          type="admin"
        >
          <ModalBody />
        </Modal>
    </div>
  );
};

export default UsersTableFilters;
