import React from 'react';
import { TIconProps } from '../../../@types/icon';

const AboutUsIcon = ({ fill, width, height }: TIconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 192 200"
            fill="none">
            <path
                d="M83.6667 75H100.333V58.3333H83.6667M92.0001 166.667C55.2501 166.667 25.3334 136.75 25.3334 100C25.3334 63.25 55.2501 33.3333 92.0001 33.3333C128.75 33.3333 158.667 63.25 158.667 100C158.667 136.75 128.75 166.667 92.0001 166.667ZM92.0001 16.6666C81.0566 16.6666 70.2203 18.8221 60.1098 23.01C49.9993 27.1979 40.8127 33.3362 33.0745 41.0744C17.4465 56.7024 8.66675 77.8986 8.66675 100C8.66675 122.101 17.4465 143.297 33.0745 158.926C40.8127 166.664 49.9993 172.802 60.1098 176.99C70.2203 181.178 81.0566 183.333 92.0001 183.333C114.101 183.333 135.298 174.554 150.926 158.926C166.554 143.297 175.333 122.101 175.333 100C175.333 89.0565 173.178 78.2201 168.99 68.1097C164.802 57.9992 158.664 48.8126 150.926 41.0744C143.187 33.3362 134.001 27.1979 123.89 23.01C113.78 18.8221 102.944 16.6666 92.0001 16.6666ZM83.6667 141.667H100.333V91.6666H83.6667V141.667Z"
                fill={fill}
            />
        </svg>
    )
}

export default AboutUsIcon;
