import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type TTabState = {
  disabledBtn: boolean;
};

const initialState: TTabState = {
  disabledBtn: false,
};

const disabledBtnSlice = createSlice({
  name: "disabledBtn",
  initialState,
  reducers: {
    setdDisabledBtn: (state, action: PayloadAction<boolean>) => {
      state.disabledBtn = action.payload;
    },
  },
});

export const { setdDisabledBtn } = disabledBtnSlice.actions;

export default disabledBtnSlice.reducer;
