import { useDispatch, useSelector } from "react-redux";
import UsersTable from "./TableBody/UsersTable"
import styles from "./Users.module.scss"
import { AppDispatch, RootState } from "../../../app/store";
import { useCallback, useEffect, useState } from "react";
import { filterUsersData, getAllUsers } from "../../../app/api";
import ReactPaginate from "react-paginate";
import ArrowRightIcon from "../../../components/icons/ArrowRightIcon";
import ArrowLeftIcon from "../../../components/icons/ArrowLeftIcon";
import { updateUsersFetchAgain } from "../../../app/features/usersSlice";
import Loading from "../../../components/Loading/Loading";

const Users = () => {
   const dispatch: AppDispatch = useDispatch();
   const [page, setPage] = useState<number>(1);
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [take, setTake] = useState<number>(10);
   const { usersList, isLoading, fetchAgain, meta, isFilteredData, userFilterDetails } = useSelector(
      (state: RootState) => state.user
   );
   const { pageCount }: any = meta ?? {};
   const headerColumns = [
    "Membership ID",
    "Name",
    "Surname",
    "Email",
    "Phone",
    "Birthdate",
    "Gender",
    "Nationality",
    "Residency",
    "Security",
    "Job",
    "Company",
    "Country",
    "License Issuing Country",
    "License Id Number",
    "License File",
    "Contribution",
    "Email Verified",
    "Phone Verified",
    "Registration Step",
    "Created Date"
   ]

   const handlePageClick = (newPage: any) => {
      setPage(newPage.selected + 1);
      dispatch(updateUsersFetchAgain());
   };

   const fetchUsersData = useCallback(async () => {
      try {
        if(!isFilteredData) {
          await dispatch(getAllUsers({ page, take }))
        }
        await dispatch(filterUsersData({...userFilterDetails, page, take}))
      } catch (error: unknown) {
        console.error("Error fetching data:", error);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);
  
    useEffect(() => {
      if (fetchAgain) {
        fetchUsersData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchAgain]);

   return <div className={`container ${styles.users}`}>
      <h2>Users</h2>
      {
        isLoading ? <Loading /> : <UsersTable headerColumns={headerColumns} rowsData={usersList}/>
      }
      {meta ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <ArrowRightIcon fill="#2065D1" width="30px" height="30px" />
          }
          onPageChange={(page) => handlePageClick(page)}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={
            <ArrowLeftIcon fill="#2065D1" width="30px" height="30px" />
          }
          renderOnZeroPageCount={null}
          className="pagination"
          previousClassName="prev"
        />
      ) : null}
   </div>
}

export default Users