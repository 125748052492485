import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import InputField from "../../../components/InputField/InputField"
import styles from "../JoinUs.module.scss"
import { useState } from "react";
import EyeIcon from "../../../components/icons/EyeIcon";
import CloseEyeIcon from "../../../components/icons/CloseEyeIcon";
import { t } from "i18next";
import { setUserLoginDetails } from "../../../app/features/joinUsSlice";

const MemberLogin = () => {
  const dispatch: AppDispatch = useDispatch();
  const [showPassowrd, setShowPassowrd] = useState(false);
  const { memberLoginDetails } = useSelector((state: RootState) => state.joinUs)
  const { isLoading } = useSelector((state: RootState) => state.auth);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    dispatch(setUserLoginDetails({ key: id, value }));
  };

  return (
    <div>
      <div className={`${styles.sectionContent} ${styles.loginSectionContent}`}>
        <div className={styles.inputField}>
          <InputField
            id="email"
            value={memberLoginDetails.email}
            onChange={handleInputChange}
            type="email"
            label={t("sign_in.email.label_text")}
            required
            placeholder={t("sign_in.email.description_text")}
          />
        </div>
        <div className={styles.inputField}>
          <InputField
            id="password"
            value={memberLoginDetails.password}
            onChange={handleInputChange}
            type={!showPassowrd ? "password" : "text"}
            label={t("sign_in.password.label_text")}
            required
            placeholder={t("sign_in.password.description_text")}
            inputIcon={
              !showPassowrd ? (
                <EyeIcon fill="#333333" width="18px" height="18px" />
              ) : (
                <CloseEyeIcon fill="#333333" width="18px" height="18px" />
              )
            }
            handleInputIconClick={() => setShowPassowrd(!showPassowrd)}
          />
        </div>
      </div>
    </div>
  )
}

export default MemberLogin