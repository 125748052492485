import axios from "axios";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const server = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

server.interceptors.request.use(
  (config: any) => {
    const jwtToken = localStorage.access_token;
    config.headers = jwtToken
      ? {
        Authorization: `Bearer ${jwtToken}`,
      }
      : {};
    return config;
  },
  (error) => {
    console.log(error);
  }
);

server.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("access_token");
      window.location.reload();
    }
    return Promise.reject(error.response);
  }
);

export default server;
