import React, { ChangeEvent, useState } from "react";
import { Partner } from "../../../../@types/partner";
import Modal from "../../../../components/Modal/Modal";
import styles from "../../Media/ModalBody/ModalBody.module.scss";
import InputField from "../../../../components/InputField/InputField";
import UploadFile from "../../../components/UploadFile/uploadFile";
import { setdDisabledBtn } from "../../../../app/features/disabledBtnSlice";
import { useDispatch } from "react-redux";
import { validateFormObjetc } from "../../../common/common";
import { toast } from "react-toastify";
import { CreatePartner, EditPartner } from "../api/partnerApi";
import { setItemEdit } from "../../../../app/features/itemEditSlice";

const PartnerForm: React.FC<{
  isEdit: boolean;
  showModal: boolean;
  editRowData: Partner;
  closeModal: (type: boolean, addedItem?: Partner) => void;
}> = ({ showModal, isEdit, editRowData, closeModal }) => {
  const [formModel, setFormModel] = useState<Partner | null>({
    website: editRowData?.website ?? "",
  });
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [validation, setValidation] = useState<string[]>([]);
  const dispatch = useDispatch();

  const onHandelChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { id, value } = e?.target;

    if (value && value !== "") {
      const updatedValidation = validation?.filter((item) => item !== id);
      setValidation(updatedValidation);
    }

    setFormModel((oldState) => ({
      ...oldState,
      [id]: value,
    }));
  };

  const handleModalClose = () => {
    closeModal(false);
  };

  const onSubmitForm = () => {
    dispatch(setdDisabledBtn(true));
    if (validateFormObjetc(formModel)?.length) {
      setValidation(validateFormObjetc(formModel));
      dispatch(setdDisabledBtn(false));
      return;
    }

    if (!imageFile && !isEdit) {
      toast.warning("add image !");
      dispatch(setdDisabledBtn(false));
      return;
    }

    const formData: any = new FormData();
    formData.append("website", formModel?.website!);

    if (isEdit && !imageFile) {
      formData.append("logo", editRowData?.logo);
    } else {
      imageFile && formData.append("logo", imageFile);
    }

    if (isEdit) {
      EditPartner(formData, editRowData?.id!).then((res) => {
        dispatch(setItemEdit(res));
        toast.success("Edit successfully !");
      });
    } else {
      CreatePartner(formData).then((res) => {
        toast.success("created successfully !");
        dispatch(setItemEdit(res));
      });
    }
    dispatch(setdDisabledBtn(false));
    setFormModel({
      website: "",
      logo: "",
    });
    closeModal(false);
  };

  const onSetFile = (file: File): void => {
    setImageFile(file);
  };

  return (
    <Modal
      isOpen={showModal}
      btnText={!isEdit ? "Create" : "Edit"}
      onClose={handleModalClose}
      onClick={onSubmitForm}
      onOutside
      type="admin"
    >
      <div className={styles.mediaModalContent}>
        <div className={styles.fieldForm}>
          <div className={styles.inputField}>
            <InputField
              id="website"
              value={formModel?.website!}
              onChange={onHandelChange}
              type="string"
              label="Website"
              required
              placeholder="Enter Website"
              forAdmin
            />
          </div>
        </div>

        <div className={styles.attachment}>
          <div className={styles.uploadFilesContent}>
            <UploadFile
              setFileDataValCB={onSetFile}
              imageUrlString={editRowData?.logo}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PartnerForm;
