import React from "react";
import styles from "./ModalBody.module.scss";
import InputField from "../../../../components/InputField/InputField";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { REACT_APP_AMAZON_S3_URL } from "../../../../constants/constant";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import Checkbox from "../../../../components/Checkbox/Checkbox";

const ModalBody = (props: any) => {
  const {
    inputRef,
    handleUpload,
    selectedImage,
    handleInputChange,
    handleMediaChange,
    mediaFormData,
    handleCKEditorChange,
  } = props;

  return (
    <div className={styles.mediaModalContent}>
      <div className={styles.title}>
        <div className={styles.inputField}>
          <InputField
            id="title_en"
            value={mediaFormData.title_en}
            onChange={handleInputChange}
            type="string"
            label="Title (English)"
            required
            placeholder="Enter english title"
            forAdmin
          />
        </div>
        <div className={styles.inputField}>
          <InputField
            id="title_ar"
            value={mediaFormData.title_ar}
            onChange={handleInputChange}
            type="string"
            label="Title (Arabic)"
            required
            placeholder="Enter arabic title"
            forAdmin
          />
        </div>
      </div>
      <div className={styles.imageField}>
        <div className={styles.uploadContent}>
          <button onClick={handleUpload}>
            Upload image
            <span>*</span>
          </button>
          <div className={styles.socialImage}>
            {selectedImage && (
              <div className={styles.image}>
                <img
                  src={
                    typeof selectedImage === "string"
                      ? `${REACT_APP_AMAZON_S3_URL}/${selectedImage}`
                      : URL.createObjectURL(selectedImage)
                  }
                  alt="Uploaded"
                />
              </div>
            )}
          </div>
        </div>
        <input
          ref={(el) => {
            inputRef.current[0] = el!;
          }}
          type="file"
          id="fileImgInput"
          name="fileImgInput"
          accept="image/*"
          onChange={handleMediaChange}
        />
      </div>
      <div className={styles.ckEditor}>
        <div>
          <p className={styles.editorTitle}>
            Content (English)<span>*</span>
          </p>
          <CKEditor
            id="body_en"
            editor={ClassicEditor}
            config={{
              toolbar: [
                "heading",
                "|",
                "bold",
                "italic",
                "|",
                "bulletedList",
                "numberedList",
                "|",
                "insertTable",
                "|",
                "|",
                "|",
                "undo",
                "redo",
              ],
            }}
            data={mediaFormData?.body_en || ""}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleCKEditorChange("body_en", data);
            }}
          />
        </div>
        <div>
          <p className={styles.editorTitle}>
            Content (Arabic)<span>*</span>
          </p>
          <CKEditor
            id="body_ar"
            editor={ClassicEditor}
            config={{
              toolbar: [
                "heading",
                "|",
                "bold",
                "italic",
                "|",
                "bulletedList",
                "numberedList",
                "|",
                "insertTable",
                "|",
                "|",
                "|",
                "undo",
                "redo",
              ],
            }}
            data={mediaFormData?.body_ar || ""}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleCKEditorChange("body_ar", data);
            }}
          />
        </div>
      </div>
      <div className={styles.category}>
        <p className={styles.checkboxLable}>
          Category<span>*</span>
        </p>
        <div className={styles.checkboxField}>
          <RadioButton
            id="eventId"
            label="Event"
            value="event"
            name="mediaType"
            checked={mediaFormData.type === "event"}
            onChange={handleInputChange}
            forAdmin
          />
          <RadioButton
            id="newsId"
            label="News"
            value="news"
            name="mediaType"
            checked={mediaFormData.type === "news"}
            onChange={handleInputChange}
            forAdmin
          />
        </div>
      </div>
      <div className={styles.publish}>
        <p className={styles.checkboxLable}>Publish</p>
        <Checkbox
          id="publish"
          checked={mediaFormData.publish}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default ModalBody;
