import React from 'react';
import { TIconProps } from '../../@types/icon';

const AddressIcon = ({ fill, width, height }: TIconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 200 200"
            fill="none">
            <path
                d="M100 54.1667C105.525 54.1667 110.824 56.3617 114.731 60.2687C118.638 64.1757 120.833 69.4747 120.833 75.0001C120.833 77.736 120.294 80.445 119.247 82.9727C118.2 85.5003 116.666 87.7969 114.731 89.7315C112.797 91.666 110.5 93.2006 107.973 94.2476C105.445 95.2945 102.736 95.8334 100 95.8334C94.4746 95.8334 89.1756 93.6385 85.2686 89.7315C81.3616 85.8245 79.1666 80.5254 79.1666 75.0001C79.1666 69.4747 81.3616 64.1757 85.2686 60.2687C89.1756 56.3617 94.4746 54.1667 100 54.1667ZM100 16.6667C115.471 16.6667 130.308 22.8126 141.248 33.7522C152.187 44.6918 158.333 59.5291 158.333 75.0001C158.333 118.75 100 183.333 100 183.333C100 183.333 41.6666 118.75 41.6666 75.0001C41.6666 59.5291 47.8124 44.6918 58.7521 33.7522C69.6917 22.8126 84.529 16.6667 100 16.6667ZM100 33.3334C88.9493 33.3334 78.3512 37.7233 70.5372 45.5373C62.7232 53.3513 58.3333 63.9494 58.3333 75.0001C58.3333 83.3334 58.3333 100 100 155.917C141.667 100 141.667 83.3334 141.667 75.0001C141.667 63.9494 137.277 53.3513 129.463 45.5373C121.649 37.7233 111.051 33.3334 100 33.3334Z"
                fill={fill}
            />
        </svg>
    )
}

export default AddressIcon;
