import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./Media.module.scss";
import TableBody from "./TableBody/MediaTableBody";
import { AppDispatch, RootState } from "../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import {
  createMedia,
  mediaList,
  mediaUpdateById,
} from "../../../app/api";
import Modal from "../../../components/Modal/Modal";
import ModalBody from "./ModalBody/ModalBody";
import { TMediaApiProps } from "../../../@types/type";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { updateMediaFetchAgain } from "../../../app/features/mediaSlice";
import ArrowLeftIcon from "../../../components/icons/ArrowLeftIcon";
import ArrowRightIcon from "../../../components/icons/ArrowRightIcon";
import Loading from "../../../components/Loading/Loading";

const headerColumns = [
  "ID",
  "Title",
  "Image",
  "Body",
  "Type",
  "Published",
  "Date",
  "Actions",
];

const Media = () => {
  const dispatch: AppDispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isDataFetching, setIsDataFetching] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [take, setTake] = useState(5);
  const [isFieldValid, setIsFieldValid] = useState<boolean>(false);
  const [isActiveId, setIsActiveId] = useState<number | null>(null);
  const [mediaFormData, setMediaFormData] =
    useState<TMediaApiProps>({
      title_en: "",
      title_ar: "",
      body_en: "",
      body_ar: "",
      type: "media",
      publish: true,
    });
  const { allMediaData, isLoading, fetchAgain, meta } = useSelector(
    (state: RootState) => state.media
  );

  const { pageCount }: any = meta ?? {};

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    if (id === "eventId" || id === "newsId") {
      setMediaFormData({ ...mediaFormData, type: value });
    } else if (id === "publish") {
      setMediaFormData({
        ...mediaFormData,
        publish: !mediaFormData.publish,
      });
    } else {
      setMediaFormData({ ...mediaFormData, [id]: value });
    }
  };

  const handlePageClick = (newPage: any) => {
    setPage(newPage.selected + 1);
    dispatch(updateMediaFetchAgain());
  };

  const handleCKEditorChange = (id: string, data: string) => {
    setMediaFormData({ ...mediaFormData, [id]: data });
  };

  const handleUpload = () => {
    inputRef.current[0].click();
  };

  const handleMediaChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setSelectedImage(file);
    }
  };

  const handleCreateClick = async () => {
    const formData: any = new FormData();
    const thumbnail_image: File | null = selectedImage;

    if (thumbnail_image !== null) {
      formData.append("image", thumbnail_image);
    }
    formData.append("title_en", mediaFormData.title_en);
    formData.append("title_ar", mediaFormData.title_ar);
    formData.append("body_en", mediaFormData.body_en);
    formData.append("body_ar", mediaFormData.body_ar);
    formData.append("type", mediaFormData.type);
    formData.append("published", mediaFormData.publish);

    await dispatch(createMedia(formData)).then((res: any) => {
      if (res.payload.status === 201) {
        setShowModal(false);
        setSelectedImage(null);
        setMediaFormData({
          title_en: "",
          title_ar: "",
          body_en: "",
          body_ar: "",
          type: "media",
          publish: true,
        });
        toast.success(res.payload.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleEditClick = async () => {
    const formData: any = new FormData();
    const thumbnail_image: File | null = selectedImage;

    if (thumbnail_image !== null) {
      formData.append("image", thumbnail_image);
    }
    formData.append("title_en", mediaFormData.title_en);
    formData.append("title_ar", mediaFormData.title_ar);
    formData.append("body_en", mediaFormData.body_en);
    formData.append("body_ar", mediaFormData.body_ar);
    formData.append("type", mediaFormData.type);
    formData.append("published", mediaFormData.publish);

    const query = {
      id: isActiveId,
      data: formData,
    };

    await dispatch(mediaUpdateById(query)).then((res: any) => {
      if (res.payload.status === 200) {
        setShowModal(false);
        setSelectedImage(null);
        setMediaFormData({
          title_en: "",
          title_ar: "",
          body_en: "",
          body_ar: "",
          type: "media",
          publish: true,
        });
        toast.success(res.payload.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleEditRowClick = (row: any) => {
    setIsEdit(true);
    setShowModal(true);
    setIsActiveId(row.id);
    setSelectedImage(row.image);
    setMediaFormData({
      title_en: row.title_en,
      title_ar: row.title_ar,
      body_en: row.body_en,
      body_ar: row.body_ar,
      type: row.type,
      publish: row.published,
    });
  };

  const handleModalClose = () => {
    setShowModal(false);
    setIsEdit(false);
    setSelectedImage(null);
    setMediaFormData({
      title_en: "",
      title_ar: "",
      body_en: "",
      body_ar: "",
      type: "media",
      publish: true,
    });
  };

  const allFetchingData = useCallback(async () => {
    try {
      await dispatch(mediaList({ take, page })).then((res: any) => {
        if (res.payload.status === 200) {
          setIsDataFetching(true);
        }
      });
    } catch (error: unknown) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (fetchAgain) {
      allFetchingData();
    }
  }, [allFetchingData, fetchAgain]);

  useEffect(() => {
    setIsDataFetching(true);
  }, [allMediaData]);

  useEffect(() => {
    const isFormEmpty = Object.values(mediaFormData).some(
      (value) => !value && value !== false
    );

    const isPublishInvalid =
      mediaFormData.publish !== true &&
      mediaFormData.publish !== false;

    const isImageValid = selectedImage !== null && selectedImage;

    setIsFieldValid(isFormEmpty || isPublishInvalid || !isImageValid);
  }, [mediaFormData, selectedImage]);

  return (
    <div className={`container ${styles.mediaList}`}>
      <h2>Media</h2>
      <TableBody
        setShowModal={setShowModal}
        rowsData={allMediaData}
        headerColumns={headerColumns}
        handleEditRowClick={handleEditRowClick}
      />
      {!isDataFetching ? (
        <Loading />
      ) : (
        <Modal
          isOpen={showModal}
          btnText={isEdit ? "Edit" : "Create"}
          onClose={handleModalClose}
          onClick={!isEdit ? handleCreateClick : handleEditClick}
          disabled={isFieldValid || isLoading}
          onOutside
          type="admin"
        >
          <ModalBody
            mediaFormData={mediaFormData}
            handleInputChange={handleInputChange}
            handleUpload={handleUpload}
            selectedImage={selectedImage}
            inputRef={inputRef}
            handleMediaChange={handleMediaChange}
            handleCKEditorChange={handleCKEditorChange}
          />
        </Modal>
      )}
      {meta ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <ArrowRightIcon fill="#2065D1" width="30px" height="30px" />
          }
          onPageChange={(page) => handlePageClick(page)}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={
            <ArrowLeftIcon fill="#2065D1" width="30px" height="30px" />
          }
          renderOnZeroPageCount={null}
          className="pagination"
          previousClassName="prev"
        />
      ) : null}
    </div>
  );
};

export default Media;
