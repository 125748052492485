import { v4 as uuidv4 } from "uuid";
import AdminButton from "../../../../components/AdminButton/AdminButton";
import PlusIcon from "../../../../components/icons/PlusIcon";
import styles from "./TableBody.module.scss";
import EditIcon from "../../../../components/icons/admin/EditIcon";
import DeleteIcon from "../../../../components/icons/admin/DeleteIcon";
import { AppDispatch, RootState } from "../../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import EyeIcon from "../../../../components/icons/EyeIcon";
import { checkDate } from "../../../../utils/formats";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import { deleteNewsletterById } from "../../../../app/api";
import { toast } from "react-toastify";
import Modal from "../../../../components/Modal/Modal";
import DetailsModalBody from "../DetailsModalBody/DetailsModalBody";

type TableBodyProps = {
  rowsData: Array<unknown>;
  headerColumns: string[];
  handleShowModal: () => void;
  handleEditRowClick: (id: string) => void;
};

const TableBody = ({
  rowsData,
  headerColumns,
  handleShowModal,
  handleEditRowClick,
}: TableBodyProps) => {
  const dispatch: AppDispatch = useDispatch();
  const [deletingNewsletterId, setDeletingNewsletterId] = useState<
    null | number | string
  >(null);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [selectedNewsletterBody, setSelectedNewsletterBody] =
    useState<string>("");

  const { isLoading } = useSelector((state: RootState) => state.newsletter);

  const handleDeleteRowClick = (id: string) => {
    setDeletingNewsletterId(id);
    setShowConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    setDeletingNewsletterId(null);
  };

  const handleConfirmClick = async () => {
    await dispatch(deleteNewsletterById({ id: deletingNewsletterId })).then(
      (res: any) => {
        if (res.payload.status === 200) {
          handleCloseConfirmationModal();
          toast.success(res.payload.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const handleViewDetailsClick = (body: string) => {
    setShowDetailsModal(true);
    setSelectedNewsletterBody(body);
  };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableTopActions}>
        <AdminButton onClick={handleShowModal}>
          <PlusIcon width="22px" height="22px" fill="#FFFFFF" />
          Create
        </AdminButton>
      </div>
      <div className={styles.tableBody}>
        {rowsData.length ? (
          <table>
            <thead>
              <tr>
                {headerColumns.map((col: any) => {
                  return <th key={uuidv4()}>{col}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {rowsData.map((row: any) => {
                const { id, title, shipDate, body } = row;
                const date = new Date(shipDate)
                  .toLocaleString("en-US", {
                    year: "numeric",
                    day: "2-digit",
                    month: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })
                  .replaceAll("/", "-")
                  .replace(",", "");

                const isUpcomingLetter = checkDate(new Date(shipDate));

                return (
                  <tr key={uuidv4()}>
                    <td>
                      <p>{id}</p>
                    </td>
                    <td>
                      <p>{title}</p>
                    </td>
                    <td>
                      <p>{date}</p>
                    </td>
                    <td>
                      <div className={styles.actions}>
                        <button
                          onClick={() => handleEditRowClick(row)}
                          disabled={!isUpcomingLetter}
                        >
                          <EditIcon width="20px" height="20px" fill="#333333" />
                        </button>
                        <button
                          onClick={() => handleDeleteRowClick(id)}
                          disabled={!isUpcomingLetter}
                        >
                          <DeleteIcon
                            width="20px"
                            height="20px"
                            fill="#e52020"
                          />
                        </button>
                        <button onClick={() => handleViewDetailsClick(body)}>
                          <EyeIcon width="20px" height="20px" fill="#333333" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}
      </div>
      <ConfirmationModal
        isOpen={showConfirmationModal}
        text="Are you sure you want to continue ?"
        onClose={handleCloseConfirmationModal}
        onClick={handleConfirmClick}
        onOutside
        disabled={isLoading}
      />
      <Modal
        isOpen={showDetailsModal}
        onClose={() => setShowDetailsModal(false)}
        type="admin"
        maxWidth="700px"
      >
        <DetailsModalBody newsletterBody={selectedNewsletterBody} />
      </Modal>
    </div>
  );
};

export default TableBody;
