import React from 'react';
import styles from './Page404.module.scss';
import { Link } from 'react-router-dom';

const Page404 = () => {
    return (
        <div className={`container ${styles.pageNotFound}`}>
            <div className={styles.title}>
                <p>404</p>
                <span />
                <p>Page Not Found</p>
            </div>
            <Link to="/">Go Back</Link>
        </div>
    )
}

export default Page404;
