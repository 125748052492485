import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../app/store";
import DashboardLayout from "../Layout/DashboardLayout";

const PrivateRoutes = () => {
  const { token } = useSelector((state: RootState) => state.auth);

  return token ? <DashboardLayout /> : <Navigate to="/sign-in" />;
};

export default PrivateRoutes;
