import React from "react";
import { TIconProps } from "../../@types/icon";

const NewsIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
    >
      <path
        d="M165.625 25H34.375C24.6083 25 16.6666 32.475 16.6666 41.6667V158.333C16.6666 167.525 24.6083 175 34.375 175H165.625C175.392 175 183.333 167.525 183.333 158.333V41.6667C183.333 32.475 175.392 25 165.625 25ZM165.625 158.333H34.375C33.9 158.333 33.575 158.2 33.4333 158.2C33.375 158.2 33.3416 158.217 33.3333 158.267L33.2333 42.05C33.2916 41.9667 33.6666 41.6667 34.375 41.6667H165.625C166.283 41.675 166.642 41.9 166.667 41.7333L166.767 157.95C166.708 158.033 166.333 158.333 165.625 158.333Z"
        fill={fill}
      />
      <path
        d="M50 58.3334H100V108.333H50V58.3334ZM108.333 125H50V141.667H150V125H116.667H108.333ZM116.667 91.6667H150V108.333H116.667V91.6667ZM116.667 58.3334H150V75H116.667V58.3334Z"
        fill={fill}
      />
    </svg>
  );
};

export default NewsIcon;
