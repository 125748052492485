import React from "react";
import { TIconProps } from "../../../@types/icon";

const PartnerIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
    >
      <path
        d="M100 71.475L76.7667 48.2333L100 25L123.4 48.2333L100 71.475ZM16.8334 161.542V132.375C16.8334 129.042 17.9278 126.178 20.1167 123.783C22.3167 121.394 25.0112 120.089 28.2 119.867H52.2917C54.5362 119.867 56.6667 120.403 58.6834 121.475C60.7056 122.542 62.3723 124.097 63.6834 126.142C67.9278 132.086 73.2139 136.697 79.5417 139.975C85.875 143.242 92.6945 144.875 100 144.875C107.339 144.875 114.2 143.239 120.583 139.967C126.972 136.7 132.269 132.089 136.475 126.133C137.853 124.094 139.544 122.539 141.55 121.467C143.556 120.394 145.608 119.864 147.708 119.875H171.792C175.019 120.086 177.75 121.389 179.983 123.783C182.217 126.172 183.333 129.033 183.333 132.367V161.533H133.333V141.3C128.683 145.089 123.519 148.022 117.842 150.1C112.164 152.172 106.217 153.208 100 153.208C93.9223 153.208 88.0612 152.189 82.4167 150.15C76.7778 148.117 71.5834 145.203 66.8334 141.408V161.542H16.8334ZM38.1417 105.125C32.475 105.125 27.5862 103.069 23.475 98.9583C19.3639 94.8472 17.3084 89.9583 17.3084 84.2917C17.3084 78.4917 19.3639 73.5694 23.475 69.525C27.5862 65.4806 32.475 63.4583 38.1417 63.4583C43.9417 63.4583 48.8639 65.4806 52.9084 69.525C56.9528 73.5694 58.975 78.4917 58.975 84.2917C58.975 89.9583 56.9528 94.8472 52.9084 98.9583C48.8639 103.069 43.9417 105.125 38.1417 105.125ZM161.858 105.125C156.192 105.125 151.303 103.069 147.192 98.9583C143.081 94.8472 141.025 89.9583 141.025 84.2917C141.025 78.4917 143.081 73.5694 147.192 69.525C151.303 65.4806 156.192 63.4583 161.858 63.4583C167.658 63.4583 172.581 65.4806 176.625 69.525C180.669 73.5694 182.692 78.4917 182.692 84.2917C182.692 89.9583 180.669 94.8472 176.625 98.9583C172.581 103.069 167.658 105.125 161.858 105.125Z"
        fill={fill}
      />
    </svg>
  );
};

export default PartnerIcon;
