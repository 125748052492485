import { ActionReducerMapBuilder, PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import { getLoggedInUser, signIn, updateUserData } from "../api";
import { TUserProps } from "../../@types/type";
import { DateFormatToOneString } from "../../utils/formats";
import { t } from "i18next";

type TAuthState = {
    userInfo: TUserProps;
    token: string | null;
    role: string;
    isError: boolean | null;
    status: string | null;
    isLoading: boolean;
    showOTP: boolean;
}

const initialState: TAuthState = {
    userInfo: {
        id: "",
        firstName: "",
        lastName: "",
        birthdate: "",
        gender: "",
        dateValue: {
            day: "",
            year: "",
        },
        selectedMonth: {
            label: "",
            value: "",
        },
        selectedNationality: {
            label: "",
            value: "",
        },
        selectedResidency: {
            label: "",
            value: "",
        },
        nationality: "",
        residency: "",
        email: "",
        phone: "",
        security: "",
        jobTitle: "",
        companyName: "",
        country: "",
        contribute: "No",
        licenseIssuingCountry: "",
        licenseIdNumber: "",
        licenseFile: "",
        selectedFile: null,
        multipleContribution: [],
        selectedLicenseCountry: {
            label: "",
            value: "",
        },
        selectedSecurity: {
            label: "",
            value: "",
        },
        contribution: [],
        emiratesIdNumber: "",
        emiratesIdExpiredDate: "",
        martialStatus: "",
        membershipId: "",
        profilePicture: "",
    },
    token: localStorage.access_token ? localStorage.access_token : '',
    role: localStorage.role ? localStorage.role : '',
    showOTP: false,
    isError: null,
    status: null,
    isLoading: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.token = ''
            state.userInfo = {
                id: "",
                firstName: "",
                lastName: "",
                birthdate: "",
                gender: "",
                dateValue: {
                    day: "",
                    year: "",
                },
                selectedMonth: {
                    label: "",
                    value: "",
                },
                selectedNationality: {
                    label: "",
                    value: "",
                },
                selectedResidency: {
                    label: "",
                    value: "",
                },
                nationality: "",
                residency: "",
                email: "",
                phone: "",
                security: "",
                jobTitle: "",
                companyName: "",
                country: "",
                contribute: "No",
                licenseIssuingCountry: "",
                licenseIdNumber: "",
                licenseFile: "",
                selectedFile: null,
                multipleContribution: [],
                selectedLicenseCountry: {
                    label: "",
                    value: "",
                },
                selectedSecurity: {
                    label: "",
                    value: "",
                },
                contribution: [],
                emiratesIdNumber: "",
                emiratesIdExpiredDate: "",
                martialStatus: "",
                membershipId: "",
                profilePicture: "",
            }
            localStorage.access_token = ''
        },
        setProfileDetails: (
            state: TAuthState,
            action: PayloadAction<any>
        ) => {
            const { key, value } = action.payload;

            if (key === "year" || key === "day") {
                state.userInfo = {
                    ...state.userInfo,
                    dateValue: { ...state.userInfo.dateValue, [key]: value },
                };
            } else if (key === "file") {
                state.userInfo = { ...state.userInfo, selectedFile: value };
            } else if (key === "contribute") {
                const multipleContributionArray = current(
                    state.userInfo.multipleContribution
                );
                state.userInfo = { ...state.userInfo, contribute: value };
                if (multipleContributionArray.length > 0 && value === "No") {
                    state.userInfo.multipleContribution = [];
                }
            } else if (key === "multipleContribution") {
                let cloneUserInfo = [
                    ...state.userInfo.multipleContribution,
                ];

                if (cloneUserInfo.includes(value)) {
                    cloneUserInfo.splice(cloneUserInfo.indexOf(value), 1);
                    state.userInfo = {
                        ...state.userInfo,
                        multipleContribution: cloneUserInfo,
                    };
                } else {
                    cloneUserInfo.push(value);
                    state.userInfo = {
                        ...state.userInfo,
                        multipleContribution: cloneUserInfo,
                    };
                }
            } else if (key === "resetLicenseFile") {
                state.userInfo.licenseFile = ""
            }
            else {
                state.userInfo = { ...state.userInfo, [key]: value };
            }

            if (
                state.userInfo.dateValue &&
                state.userInfo.selectedMonth
            ) {
                const date = DateFormatToOneString(
                    state.userInfo.dateValue,
                    state.userInfo.selectedMonth
                );
                state.userInfo = { ...state.userInfo, birthdate: date };
            }

            if (state.userInfo.selectedNationality) {
                state.userInfo = {
                    ...state.userInfo,
                    nationality: state.userInfo.selectedNationality.value,
                };
            }

            if (state.userInfo.selectedResidency) {
                state.userInfo = {
                    ...state.userInfo,
                    residency: state.userInfo.selectedResidency.label,
                };
            }

            if (state.userInfo.selectedSecurity) {
                state.userInfo = {
                    ...state.userInfo,
                    security: state.userInfo.selectedSecurity.label,
                };
            }

            if (state.userInfo.selectedLicenseCountry) {
                state.userInfo = {
                    ...state.userInfo,
                    licenseIssuingCountry: state.userInfo.selectedLicenseCountry.label,
                };
            }
        },

        setShowOTP: (
            state: TAuthState,
            action: PayloadAction<any>
        ) => {
           state.showOTP = action.payload
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<any>) => {
        builder.addCase(signIn.pending, (state: TAuthState) => {
            state.isLoading = true;
        }).addCase(signIn.fulfilled, (state: TAuthState, action: PayloadAction<any>) => {
            state.isLoading = false;
            if (action.payload.status === 201) {
                state.role = action.payload.data?.role
                state.token = action.payload.data.access_token
                localStorage.access_token = action.payload.data.access_token;
                localStorage.role = action.payload.data.role;
            }
        }).addCase(signIn.rejected, (state: TAuthState) => {
            state.isLoading = false;
            state.isError = true;
        });
        builder.addCase(getLoggedInUser.pending, (state: TAuthState) => {
            state.isLoading = true
        }).addCase(getLoggedInUser.fulfilled, (state: TAuthState, action: PayloadAction<any>) => {
            const { data } = action?.payload?.data
            if (data) {
                state.userInfo = { ...state.userInfo, ...data }
                state.userInfo.selectedNationality = { label: data.nationality, value: data.nationality }
                state.userInfo.selectedResidency = { label: data.residency, value: data.residency }
                state.userInfo.selectedLicenseCountry = { label: data.licenseIssuingCountry, value: data.licenseIssuingCountry }
                state.userInfo.selectedSecurity = { label: data.security, value: data.security }
                state.userInfo.multipleContribution = data.contribution?.length ? data.contribution : []
                state.userInfo.contribute = data.contribution?.length ? "Yes" : "No"

                if (data.birthdate) {
                    const monthTranslations: string[] = t("date.months", { returnObjects: true });

                    const months = monthTranslations?.map((month, index) => {
                        const value = (index + 1 < 10 ? "0" : "") + (index + 1).toString();
                        return { value, label: month };
                    });

                    const [year, month, day] = data.birthdate.split('-');
                    const selectedMonth = months?.find(m => m.value === month);
                    const dateValue = { day: day, year: year };
                    state.userInfo.dateValue = dateValue
                    selectedMonth ? state.userInfo.selectedMonth = selectedMonth : state.userInfo.selectedMonth = { label: "", value: "" }
                }
            }
            state.isLoading = false
        }).addCase(getLoggedInUser.rejected, (state: TAuthState) => {
            state.isLoading = false
            state.isError = true
        });
        builder.addCase(updateUserData.pending, (state: TAuthState) => {
            state.isLoading = true
        }).addCase(updateUserData.fulfilled, (state: TAuthState, action: PayloadAction<any>) => {
            const { data } = action?.payload?.data
            if(data) {
                state.userInfo = { ...state.userInfo, ...data }
                state.userInfo.selectedNationality = { label: data.nationality, value: data.nationality }
                state.userInfo.selectedResidency = { label: data.residency, value: data.residency }
                state.userInfo.selectedLicenseCountry = { label: data.licenseIssuingCountry, value: data.licenseIssuingCountry }
                state.userInfo.selectedSecurity = { label: data.security, value: data.security }
                state.userInfo.multipleContribution = data.contribution?.length ? data.contribution : []
                state.userInfo.contribute = data.contribution?.length ? "Yes" : "No"
    
                if (data.birthdate) {
                    const monthTranslations: string[] = t("date.months", { returnObjects: true });
    
                    const months = monthTranslations?.map((month, index) => {
                        const value = (index + 1 < 10 ? "0" : "") + (index + 1).toString();
                        return { value, label: month };
                    });
    
                    const [year, month, day] = data.birthdate.split('-');
                    const selectedMonth = months?.find(m => m.value === month);
                    const dateValue = { day: day, year: year };
                    state.userInfo.dateValue = dateValue
                    selectedMonth ? state.userInfo.selectedMonth = selectedMonth : state.userInfo.selectedMonth = { label: "", value: "" }
                }
            }
            state.isLoading = false
        }).addCase(updateUserData.rejected, (state: TAuthState) => {
            state.isLoading = false
            state.isError = true
        });
    }
});

export const { logout, setProfileDetails, setShowOTP } = authSlice.actions;

export default authSlice.reducer;