import server from "../../../../app/server";
import { IDashboard } from "../../../../@types/dashboard";

export const getdashboardStatistics = async (): Promise<IDashboard> => {
  return await server
    .get("/dashboard/statistics")
    .then((res) => {
      return res?.data?.data;
    })
    .catch((error) => console.log(error));
};
