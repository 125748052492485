import React from 'react'
import { TIconProps } from '../../@types/icon'

const SearchIcon = ({ fill, width, height }: TIconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 200 200"
            fill="none">
            <path
                d="M162.375 167.95L110.192 115.767C106.025 119.317 101.233 122.064 95.8167 124.008C90.4001 125.953 84.9556 126.925 79.4834 126.925C66.139 126.925 54.8445 122.306 45.6001 113.067C36.3556 103.822 31.7334 92.5307 31.7334 79.1918C31.7334 65.8529 36.3501 54.5557 45.5834 45.3001C54.8223 36.039 66.1112 31.4084 79.4501 31.4084C92.7945 31.4084 104.095 36.0307 113.35 45.2751C122.606 54.5196 127.233 65.8168 127.233 79.1668C127.233 84.9557 126.208 90.5584 124.158 95.9751C122.103 101.392 119.408 106.025 116.075 109.875L168.258 162.05L162.367 167.95H162.375ZM79.4834 118.583C90.539 118.583 99.8751 114.778 107.492 107.167C115.103 99.5557 118.908 90.2196 118.908 79.1584C118.908 68.1029 115.103 58.7696 107.492 51.1584C99.8806 43.5473 90.5473 39.7418 79.4917 39.7418C68.4362 39.7418 59.1001 43.5473 51.4834 51.1584C43.8723 58.7696 40.0667 68.1029 40.0667 79.1584C40.0667 90.214 43.8723 99.5473 51.4834 107.158C59.0945 114.77 68.4278 118.583 79.4834 118.583Z"
                fill={fill}
            />
        </svg>
    )
}

export default SearchIcon
