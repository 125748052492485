import React from "react";
import styles from "./MediaCard.module.scss";
import { TEventsNewsCardProps } from "../../@types/type";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { REACT_APP_AMAZON_S3_URL } from "../../constants/constant";
import { fDateTime } from "../../utils/formats";

const EventsNewsCard: React.FC<TEventsNewsCardProps> = (props) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const truncatedDescription =
    props[`long_description_${i18n.language}` as keyof TEventsNewsCardProps];

  const handleEventNewsClick = (id: number) => {
    navigate(`/events-news/${id}`);
  };

  return (
    <div className={styles.eventNewsCard}>
      <div className={styles.cardThumbnail}>
        <img
          src={`${REACT_APP_AMAZON_S3_URL}/${props.thumbnail_image}`}
          alt="thumbnail_image"
        />
        <div className={styles.cardDate}>
          {fDateTime({ date: new Date(props.date), newFormat: "dd/MM/yyyy" })}
        </div>
      </div>
      <div className={styles.cardBody}>
        <div className={styles.bodyData}>
          <h3>{props[`title_${i18n.language}` as keyof TEventsNewsCardProps]}</h3>
          <div className={styles.desc}>
            {typeof truncatedDescription === "string" &&
            truncatedDescription.length > 100
              ? `${truncatedDescription.substring(0, 100)}...`
              : truncatedDescription}
          </div>
        </div>
        <div
          className={styles.btnMore}
          onClick={() => handleEventNewsClick(props.id)}
        >
          {t("more_btn_text")}
        </div>
      </div>
    </div>
  );
};

export default EventsNewsCard;
