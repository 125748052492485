import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TBreadcrumbItemState } from "../../@types/type";
import { t } from "i18next";



type TBreadcrumbState = {
    breadcrumbItems: TBreadcrumbItemState[];
    isLoading: boolean;
};

const initialState: TBreadcrumbState = {
    breadcrumbItems: localStorage.breadcrumbs && localStorage.breadcrumbs !== undefined ? JSON.parse(localStorage.breadcrumbs) : null || [],
    isLoading: false
};

const breadcrumbSlice = createSlice({
    name: 'breadcrumb',
    initialState,
    reducers: {
        setBreadcrumb: (state, action: PayloadAction<TBreadcrumbItemState>) => {
            state.breadcrumbItems = action.payload.url ? [{ url: "/", name: t('links.home_text'), key: "links.home_text" }, action.payload] : [...state.breadcrumbItems, action.payload];
            const filteredBreadcrumbs = state.breadcrumbItems.filter(breadcrumb => breadcrumb.name);
            state.breadcrumbItems = filteredBreadcrumbs;
            localStorage.breadcrumbs = JSON.stringify(filteredBreadcrumbs);
        },
        setBreadCrumbsArray: (state, action: PayloadAction<TBreadcrumbItemState[]>) => {
            state.breadcrumbItems = action.payload;
            localStorage.breadcrumbs = JSON.stringify(action.payload);
        },
        setClearBreadCrumbsArray: (state) => {
            state.breadcrumbItems = [];
            localStorage.removeItem("breadcrumbs")
        }
    },
    extraReducers: (builder: any) => { }
});

export const { setBreadcrumb, setBreadCrumbsArray, setClearBreadCrumbsArray } = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;