import { ActionReducerMapBuilder, PayloadAction, createSlice } from "@reduxjs/toolkit";

import { createPublication, publicationDeleteById, publicationFindById, publicationList, publicationUpdateById } from "../api";
import { TMetaProps, TPublicationProps } from "../../@types/type";

type TPublicationState = {
    publicationsList: TPublicationProps[];
    publicationDetails: TPublicationProps | null;
    isError: boolean;
    isLoading: boolean;
    fetchAgain: boolean;
    meta: TMetaProps | null
}

const initialState: TPublicationState = {
    publicationsList: [],
    publicationDetails: null,
    meta: null,
    isError: false,
    isLoading: false,
    fetchAgain: true,
};

const publicationSlice = createSlice({
    name: 'publication',
    initialState,
    reducers: {
        resetSinglePublication: (state) => {
            state.publicationDetails = null
        },
        updatePublicationFetchAgain: (state) => {
            state.fetchAgain = true
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<any>) => {
        // create
        builder.addCase(createPublication.pending, (state: TPublicationState) => {
            state.isLoading = true;
        });
        builder.addCase(createPublication.fulfilled, (state: TPublicationState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.fetchAgain = true;
            if (action.payload.status === 201) {
                let clonePublicationData = [...state.publicationsList];
                clonePublicationData.unshift(action.payload.data.publication)
                state.publicationsList = clonePublicationData
            }
        });
        builder.addCase(createPublication.rejected, (state: TPublicationState) => {
            state.isLoading = false;
            state.isError = true;
        });
        // get list
        builder.addCase(publicationList.pending, (state: TPublicationState) => {
            state.isLoading = true;
        });
        builder.addCase(publicationList.fulfilled, (state: TPublicationState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.fetchAgain = false;
            if (action.payload.status === 200) {
                state.publicationsList = action.payload.data.data.data
                state.meta = action.payload.data.data.meta
            }

        });
        builder.addCase(publicationList.rejected, (state: TPublicationState) => {
            state.isLoading = false;
            state.isError = true;
        });
        // find by id
        builder.addCase(publicationFindById.pending, (state: TPublicationState) => {
            state.isLoading = true;
        });
        builder.addCase(publicationFindById.fulfilled, (state: TPublicationState, action: PayloadAction<any>) => {
            state.isLoading = false;
            if (action.payload.status === 200) {
                state.publicationDetails = action.payload.data.data
            }

        });
        builder.addCase(publicationFindById.rejected, (state: TPublicationState) => {
            state.isLoading = false;
            state.isError = true;
        });

        // update
        builder.addCase(publicationUpdateById.pending, (state: TPublicationState) => {
            state.isLoading = true;
        });
        builder.addCase(publicationUpdateById.fulfilled, (state: TPublicationState, action: PayloadAction<any>) => {
            state.isLoading = false;
            if (action.payload.status === 200) {
                let clonePublicationData = [...state.publicationsList];
                let updatedMediaDataIndex = clonePublicationData.findIndex(pub => pub.id === action.payload.data.data.id)
                clonePublicationData[updatedMediaDataIndex] = action.payload.data.data;
                state.publicationsList = clonePublicationData
            }
        });
        builder.addCase(publicationUpdateById.rejected, (state: TPublicationState) => {
            state.isLoading = false;
            state.isError = true;
        });
        // delete
        builder.addCase(publicationDeleteById.pending, (state: TPublicationState) => {
            state.isLoading = true;
        });
        builder.addCase(publicationDeleteById.fulfilled, (state: TPublicationState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.fetchAgain = true;
        });
        builder.addCase(publicationDeleteById.rejected, (state: TPublicationState) => {
            state.isLoading = false;
            state.isError = true;
        });
    }
});

export const { resetSinglePublication, updatePublicationFetchAgain } = publicationSlice.actions;

export default publicationSlice.reducer;