import { ActionReducerMapBuilder, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TContactUsProps, TMetaProps } from "../../@types/type";
import { contactUsList } from "../api";

type TContactUsState = {
    contactUsData: TContactUsProps | null;
    isError: boolean | null;
    isLoading: boolean;
    meta: TMetaProps | null
}

const initialState: TContactUsState = {
    contactUsData: null,
    isError: null,
    isLoading: false,
    meta: null
};

const contactUsSlice = createSlice({
    name: 'contact-us',
    initialState,
    reducers: {
    },
    extraReducers: (builder: ActionReducerMapBuilder<any>) => {
        builder.addCase(contactUsList.pending, (state: TContactUsState) => {
            state.isLoading = true;
        });
        builder.addCase(contactUsList.fulfilled, (state: TContactUsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            if (action.payload.status === 200) {
                state.contactUsData = action.payload.data.data
            }
        });
        builder.addCase(contactUsList.rejected, (state: TContactUsState) => {
            state.isLoading = false;
            state.isError = true;
        });
    }
});

export default contactUsSlice.reducer;