import { Navigate, useRoutes } from "react-router-dom";
import MainPage from "./pages/Main/Main";
import AboutUsPage from "./pages/AboutUs/AboutUs";
import ReleasePage from "./pages/Release/Release";
import MediaPage from "./pages/Media/Media";
import ContactUs from "./pages/ContactUs/ContactUs";
import JoinUsPage from "./pages/JoinUs/JoinUs";
import PrivateRoutes from "./utils/PrivateRoutes";
import Page404 from "./pages/Page404/Page404";
import { TRouterProps } from "./@types/type";
import SignInPage from "./Admin/pages/SignIn/SignIn";
import DashboardAppPage from "./Admin/pages/Dashboard/DashboardApp";
import AdminAboutUsPage from "./Admin/pages/AboutUs/AboutUs";
import AdminContactUsPage from "./Admin/pages/ContactUs/ContactUs";
import Publication from "./Admin/pages/Publication/Publication";
import MediaDetails from "./pages/Media/MediaDetails/MediaDetails";
import Media from "./Admin/pages/Media/Media";
import EventsNnewsPage from "./pages/EventsNews/EventsNews";
import PublicationPage from "./pages/Publication/Publication";
import SearchResult from "./pages/SearchResult/SearchResult";
import PublicationDetails from "./pages/Publication/PublicationDetails/PublicationDetails";
import EventNewsCardPage from "./pages/EventAndNewsDetails/SingleEventNews";
import SlideList from "./Admin/pages/Slide/SlideList/slideList";
import PartnerList from "./Admin/pages/Partner/PartnerList/partnerList";
import EventsNewsList from "./Admin/pages/EventsNews/EventsNews";
import Role from "./Admin/pages/Role/Role";
import EmailVerification from "./pages/EmailVerifiation/EmailVerification";
import Newsletter from "./Admin/pages/Newsletter/Newsletter";
import Users from "./Admin/pages/Users/Users";
import Profile from "./pages/Profile/Profile";
import { UserRoleEnums } from "./enums/enum";

export const Router = ({ token, role }: TRouterProps) => {
  const routes = useRoutes([
    { path: "/", element: <MainPage /> },
    //{ path: "/", element: <Navigate to="/join-us" /> },
    { path: "join-us", element: <JoinUsPage /> },
    { path: "verify-email/:id", element: <EmailVerification /> },
    { path: "about-us", element: <AboutUsPage /> },
    { path: "events-news", element: <EventsNnewsPage /> },
    { path: "events-news/:id", element: <EventNewsCardPage /> },
    //{ path: "publication", element: <PublicationPage /> },
    //{ path: "publication/:id", element: <PublicationDetails /> },
    { path: "release", element: <ReleasePage /> },
    { path: "media", element: <MediaPage /> },
    { path: "media/:id", element: <MediaDetails /> },
    { path: "contact-us", element: <ContactUs /> },
    { path: "search-result", element: <SearchResult /> },
    {
      path: "profile",
      element: token && role === UserRoleEnums.MEMBER ? <Profile /> : <Navigate to="/join-us" />,
    },
    { path: "404", element: <Page404 /> },
    {
      path: "sign-in",
      element: token ? <Navigate to="/dashboard" /> : <SignInPage />,
    },
    {
      path: "dashboard",
      element: <PrivateRoutes />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "app", element: <DashboardAppPage /> },
        { path: "about-us", element: <AdminAboutUsPage /> },
        { path: "contact-us", element: <AdminContactUsPage /> },
        { path: "publication", element: <Publication /> },
        { path: "media", element: <Media /> },
        { path: "newsletter", element: <Newsletter /> },
        { path: "slide", element: <SlideList /> },
        { path: "partner", element: <PartnerList /> },
        { path: "events-news", element: <EventsNewsList /> },
        { path: "users", element: <Users /> },
        { path: "role", element: <Role /> },
        { path: "*", element: <Navigate to="/dashboard/app" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);

  return routes;
};