import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import {
  TCompanyDetailsFormProps,
  TContactDetailsFormProps,
  TMemberLoginDetails,
  TUserDetailsFormProps,
} from "../../@types/type";
import {
  updatePhoneNumber,
  confirmEmail,
  confirmVerificationCode,
  registerUser,
  resendVerificationLink,
  verifyUserEmail,
  sendVerificationCode,
} from "../api";

type TJoinUsState = {
  userDetails: TUserDetailsFormProps;
  memberLoginDetails: TMemberLoginDetails;
  isError: boolean;
  isLoading: boolean;
  message: string;
  isEmailConfirmed: boolean;
  isPhoneConfirmed: boolean;
  isLinkTokenExpired: boolean;
  isEmailSuccessfullyVerified: boolean;
  isLinkSent: boolean;
  isCodeSuccessfullyVerified: boolean;
  otpCode: string;
};

const initialState: TJoinUsState = {
  userDetails: {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    confirmEmail: "",
    profilePicture: null,
  },
  memberLoginDetails: {
    email: "",
    password: "",
    roleId: 2,
  },
  isError: false,
  isPhoneConfirmed: false,
  isLoading: false,
  isEmailConfirmed: false,
  isLinkTokenExpired: false,
  isEmailSuccessfullyVerified: false,
  isLinkSent: false,
  isCodeSuccessfullyVerified: false,
  otpCode: "",
  message: "",
};

const joinUsSlice = createSlice({
  name: "join_us",
  initialState,
  reducers: {
    // user details
    setUserDetails: (
      state: TJoinUsState,
      action: PayloadAction<TCompanyDetailsFormProps | any>
    ) => {
      const { key, value } = action.payload;
      state.userDetails = { ...state.userDetails, [key]: value };
    },
    // user login details
    setUserLoginDetails: (
      state: TJoinUsState,
      action: PayloadAction<TCompanyDetailsFormProps | any>
    ) => {
      const { key, value } = action.payload;
      state.memberLoginDetails = { ...state.memberLoginDetails, [key]: value }
    },
    // error
    setError: (state, action) => {
      state.isError = action.payload;
    },
    // clear state
    clearJoinUsState: (state: TJoinUsState) => {
      state.userDetails = {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        confirmEmail: "",
        profilePicture: null,
      };
    },
    clearMemberLoginDetails: (state: TJoinUsState) => {
      state.memberLoginDetails = {
        email: "",
        password: "",
        roleId: 2,
      };
    },
    //otp verification code
    setOTPCode: (
      state: TJoinUsState,
      action: PayloadAction<TContactDetailsFormProps | any>
    ) => {
      state.otpCode = action.payload;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder
      .addCase(registerUser.pending, (state: TJoinUsState) => {
        state.isLoading = true;
      })
      .addCase(
        registerUser.fulfilled,
        (state: TJoinUsState) => {
          state.isLoading = false;
        }
      )
      .addCase(registerUser.rejected, (state: TJoinUsState) => {
        state.isLoading = false;
        state.isError = true;
      });
      builder
      .addCase(sendVerificationCode.pending, (state: TJoinUsState) => {
        state.isLoading = true;
      })
      .addCase(
        sendVerificationCode.fulfilled,
        (state: TJoinUsState) => {
          state.isLoading = false;
        }
      )
      .addCase(sendVerificationCode.rejected, (state: TJoinUsState) => {
        state.isLoading = false;
        state.isError = true;
      });
    builder
      .addCase(confirmEmail.pending, (state: TJoinUsState) => {
        state.isLoading = true;
      })
      .addCase(
        confirmEmail.fulfilled,
        (state: TJoinUsState, action: PayloadAction<any>) => {
          const data = action.payload?.data;

          if (data.isEmailConfirmed) {
            state.isEmailConfirmed = data.isEmailConfirmed;
          } else if (data.isLinkTokenExpired) {
            state.isLinkTokenExpired = data.isLinkTokenExpired;
          } else if (data.isEmailSuccessfullyVerified) {
            state.isEmailSuccessfullyVerified =
              data.isEmailSuccessfullyVerified;
          }

          state.isLoading = false;
        }
      )
      .addCase(confirmEmail.rejected, (state: TJoinUsState) => {
        state.isLoading = false;
        state.isError = true;
      });
    builder.addCase(verifyUserEmail.pending, (state: TJoinUsState) => {
      state.isLoading = true;
    }).addCase(verifyUserEmail.fulfilled, (state: TJoinUsState) => {
      state.isLoading = false;
    }).addCase(verifyUserEmail.rejected, (state: TJoinUsState) => {
      state.isLoading = false;
    })
    builder
      .addCase(confirmVerificationCode.pending, (state: TJoinUsState) => {
        state.isLoading = true;
      })
      .addCase(
        confirmVerificationCode.fulfilled,
        (state: TJoinUsState, action: PayloadAction<any>) => {
          const data = action.payload?.data;

          state.isLoading = false;
          state.isCodeSuccessfullyVerified = data.isCodeVerified;
        }
      )
      .addCase(confirmVerificationCode.rejected, (state: TJoinUsState) => {
        state.isLoading = false;
        state.isError = true;
      });
    builder
      .addCase(resendVerificationLink.pending, (state: TJoinUsState) => {
        state.isLoading = true;
      })
      .addCase(
        resendVerificationLink.fulfilled,
        (state: TJoinUsState, action: PayloadAction<any>) => {
          const data = action.payload?.data;
          if (data.isLinkSent) {
            state.isLinkSent = data.isLinkSent;
            state.isLinkTokenExpired = false;
          }
          state.isLoading = false;
        }
      )
      .addCase(resendVerificationLink.rejected, (state: TJoinUsState) => {
        state.isLoading = false;
        state.isError = true;
      });
    builder
      .addCase(updatePhoneNumber.pending, (state: TJoinUsState) => {
        state.isLoading = true;
      })
      .addCase(
        updatePhoneNumber.fulfilled,
        (state: TJoinUsState, action: PayloadAction<any>) => {
          const { phone } = action?.payload.data.data.data;
          state.userDetails.phone = phone;
          state.isLoading = false;
        }
      )
      .addCase(updatePhoneNumber.rejected, (state: TJoinUsState) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const {
  setUserDetails,
  setUserLoginDetails,
  clearJoinUsState,
  clearMemberLoginDetails,
  setError,
  setOTPCode
} = joinUsSlice.actions;

export default joinUsSlice.reducer;
