import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { useLocation } from "react-router-dom";
import { RootState } from "../app/store";
import { useSelector } from "react-redux";
import { Router } from "../routes";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";

const AppLayout: React.FC = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const { token, role } = useSelector((state: RootState) => state.auth);
  document.documentElement.lang = i18n.language;
  document.body.dir = i18n.dir();
  const hideHeaderFooterRoutes = ["/sign-in", "/dashboard"];
  const shouldHideHeaderFooter =
    hideHeaderFooterRoutes.includes(location.pathname) ||
    hideHeaderFooterRoutes.some((path) => location.pathname.startsWith(path));
  const languageHeaderClass =
    i18n.language === "ar" ? "arabicHeader" : "englishHeader";

  return (
    <div>
      {/* {!shouldHideHeaderFooter && <Header className={languageHeaderClass} />}
      {!shouldHideHeaderFooter &&
        (location.pathname !== "/" ? <Breadcrumb /> : null)} */}
      <div className="body-content">
        <Router token={token} role={role} />
      </div>
      {/* {!shouldHideHeaderFooter && <Footer />} */}
    </div>
  );
};

export default AppLayout;
