import { Slide } from "../../../../@types/slide";
import { List, ListParams } from "../../../../@types/table";
import server from "../../../../app/server";

export const getSLideList = async (
  params: ListParams
): Promise<List<Slide>> => {
  return await server
    .get("slide/list", { params: params })
    .then((res) => res?.data?.data)
    .catch((error) => console.log(error));
};

export const createSlide = async (formData: Slide) => {
  return await server
    .post("slide/create", formData)
    .then((res) => {
      return res?.data?.data;
    })
    .catch((error) => console.log(error));
};

export const editSlide = async (formData: Slide, id: number) => {
  return await server
    .patch(`slide/update/${id}`, formData)
    .then((res) => res.data?.data)
    .catch((error) => console.log(error));
};

export const deleteSlide = async (id: number) => {
  return await server
    .delete(`slide/delete/${id}`)
    .then((res) => res?.data?.data)
    .catch((error) => {
      console.log(error);
    });
};
