import React from "react";
import { TABLE } from "../../../@types/table";
import AdminButton from "../../../components/AdminButton/AdminButton";
import PlusIcon from "../../../components/icons/PlusIcon";
import TableBody from "./tableBody/tableBody";
import TableHeader from "./tableHeader/tableHeader";
import styles from "./tableTheme.module.scss";
import ReactPaginate from "react-paginate";
import ArrowRightIcon from "../../../components/icons/ArrowRightIcon";
import ArrowLeftIcon from "../../../components/icons/ArrowLeftIcon";

const Table: React.FC<TABLE<any>> = ({
  tableHeader,
  tableBody,
  createRecord,
  getEditItem,
  metaData,
  handlePageClick,
}) => {
  const onGetEditItem = (type: string, row: any) => {
    getEditItem(type, row);
  };

  return (
    <>
      <div className={styles.tableContainer}>
        <div className={styles.topActions}>
          <AdminButton onClick={createRecord}>
            <PlusIcon width="22px" height="22px" fill="#FFFFFF" />
            Create
          </AdminButton>
        </div>
        <div className={styles.body}>
          <table>
            <thead>
              <TableHeader headerRows={tableHeader} />
            </thead>
            <tbody>
              <TableBody bodyRows={tableBody} getEditItem={onGetEditItem} />
            </tbody>
          </table>
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <ArrowRightIcon fill="#2065D1" width="30px" height="30px" />
          }
          onPageChange={(page) => handlePageClick(page.selected)}
          pageRangeDisplayed={5}
          pageCount={metaData?.pageCount ?? 1}
          previousLabel={
            <ArrowLeftIcon fill="#2065D1" width="30px" height="30px" />
          }
          renderOnZeroPageCount={null}
          className="pagination"
          previousClassName="prev"
        />
      </div>
    </>
  );
};

export default Table;
