import React, { Fragment, useCallback, useEffect } from "react";
import styles from "./MediaDetails.module.scss";
import { useParams } from "react-router-dom";
import {
  TBreadcrumbItemState,
  TFindByIdApiProps,
  TMediaProps,
} from "../../../@types/type";
import { useTranslation } from "react-i18next";
import ShareIcon from "../../../components/icons/ShareIcon";
import CalendarIcon from "../../../components/icons/CalendarIcon";
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumb } from "../../../app/features/breadcrumbSlice";
import { AppDispatch, RootState } from "../../../app/store";
import { mediaFindById } from "../../../app/api";
import { fDateTime } from "../../../utils/formats";
import { REACT_APP_AMAZON_S3_URL } from "../../../constants/constant";
import { resetSingleMedia } from "../../../app/features/mediaSlice";
import Loading from "../../../components/Loading/Loading";

const SingleMedia = () => {
  const { i18n } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { currentLanguage } = useSelector((state: RootState) => state.language);
  const { singleMedia, isLoading } = useSelector(
    (state: RootState) => state.media
  );
  const { breadcrumbItems } = useSelector(
    (state: RootState) => state.breadcrumb
  );

  const singleMediaData = useCallback(async () => {
    try {
      if (id) {
        const apiProps: TFindByIdApiProps = {
          id,
        };
        await dispatch(mediaFindById(apiProps));
      }
    } catch (error: unknown) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    singleMediaData();
    return () => {
      dispatch(resetSingleMedia());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (singleMedia) {
      const nameValue =
        singleMedia[
          `title_${currentLanguage.value}` as keyof TMediaProps
        ];
      if (
        !breadcrumbItems.some((e: TBreadcrumbItemState) => e.name === nameValue)
      ) {
        if (nameValue && typeof nameValue === "string") {
          dispatch(setBreadcrumb({ name: nameValue }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleMedia, currentLanguage, breadcrumbItems]);

  return (
    <div className={`container ${styles.mediaDetails}`}>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <h3 className={styles.title}>
            {singleMedia &&
              singleMedia[
                `title_${i18n.language}` as keyof TMediaProps
              ]}
          </h3>
          <div className={styles.subTitle}>
            <div>
              <ShareIcon width="16px" height="16px" fill="#333333" />
              <span className={styles.shareBtn}>shareing</span>
            </div>
            <div>
              <CalendarIcon width="16px" height="16px" fill="#333333" />
              <span>
                {singleMedia?.date_time &&
                  fDateTime({
                    date: new Date(singleMedia?.date_time),
                    newFormat: "dd/MM/yyyy",
                  })}
              </span>
            </div>
          </div>
          <div className={styles.thumbnailImage}>
            <img
              src={`${REACT_APP_AMAZON_S3_URL}/${singleMedia?.image}`}
              alt="thumbnail_image"
            />
          </div>
          {singleMedia ? (
            <div
              className={styles.desc}
              dangerouslySetInnerHTML={{
                __html: singleMedia[
                  `body_${i18n.language}` as keyof TMediaProps
                ] as string,
              }}
            />
          ) : null}
        </Fragment>
      )}
    </div>
  );
};

export default SingleMedia;
