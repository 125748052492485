import React, { useCallback, useEffect, useState } from "react";
import styles from "./AboutUs.module.scss";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { TAboutUsApiProps } from "../../../@types/type";
import AdminButton from "../../../components/AdminButton/AdminButton";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { aboutUsList, createAboutUs, updateAboutUs } from "../../../app/api";
import { toast } from "react-toastify";

const AdminAboutUs = () => {
  const dispatch: AppDispatch = useDispatch();
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [IsTextEditorValid, setIsTextEditorValid] = useState<boolean>(false);
  const [editorCkData, setEditorCkData] = useState<TAboutUsApiProps>({
    establishment_en: "",
    establishment_ar: "",
    objectives_en: "",
    objectives_ar: "",
    mission_en: "",
    mission_ar: "",
    vision_en: "",
    vision_ar: "",
    structure_en: "",
    structure_ar: "",
  });

  const handleTextEditorChange = useCallback(
    (id: string, value: string) => {
      if (isFetched) {
        setEditorCkData({ ...editorCkData, [id]: value });
      }
    },
    [editorCkData, isFetched]
  );

  const handleCkCreateClick = async () => {
    await dispatch(createAboutUs(editorCkData)).then((res: any) => {
      if (res.payload.status === 201) {
        toast.success(res.payload.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const hanldeEditClick = async () => {
    await dispatch(updateAboutUs(editorCkData)).then((res: any) => {
      const data = res.payload.data.data;
      if (res.payload.status === 200) {
        setIsEdit(true);
        setEditorCkData(data);
        toast.success(res.payload.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const aboutUs = useCallback(async () => {
    try {
      await dispatch(aboutUsList()).then((res: any) => {
        setIsFetched(true);
        const data = res.payload.data.data;
        if (data) {
          setEditorCkData(data);
          setIsEdit(true);
        }
      });
    } catch (error: unknown) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    aboutUs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aboutUs]);

  useEffect(() => {
    const isFormEmpty = Object.values(editorCkData).some((value) => !value);
    setIsTextEditorValid(isFormEmpty);
  }, [editorCkData]);

  return (
    <div className={`container ${styles.aboutUsSection}`}>
      <h3 className={styles.title}>About Us</h3>
      <div className={styles.textEditorContent}>
        <div className={styles.englishVersion}>
          <div className={styles.ckEditor}>
            <p className={styles.editorTitle}>Establishments (English)</p>
            <CKEditor
              id="establishment_en"
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "insertTable",
                  "|",
                  "|",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              data={editorCkData.establishment_en}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleTextEditorChange("establishment_en", data);
              }}
            />
          </div>
          <div className={styles.ckEditor}>
            <p className={styles.editorTitle}>Objectives (English)</p>
            <CKEditor
              id="objectives_en"
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "insertTable",
                  "|",
                  "|",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              data={editorCkData.objectives_en}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleTextEditorChange("objectives_en", data);
              }}
            />
          </div>
          <div className={styles.ckEditor}>
            <p className={styles.editorTitle}>Vision (English)</p>
            <CKEditor
              id="vision_en"
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "insertTable",
                  "|",
                  "|",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              data={editorCkData.vision_en}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleTextEditorChange("vision_en", data);
              }}
            />
          </div>
          <div className={styles.ckEditor}>
            <p className={styles.editorTitle}>Mission (English)</p>
            <CKEditor
              id="mission_en"
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "insertTable",
                  "|",
                  "|",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              data={editorCkData.mission_en}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleTextEditorChange("mission_en", data);
              }}
            />
          </div>
          <div className={styles.ckEditor}>
            <p className={styles.editorTitle}>Structure (English)</p>
            <CKEditor
              id="structure_en"
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "insertTable",
                  "|",
                  "|",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              data={editorCkData.structure_en}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleTextEditorChange("structure_en", data);
              }}
            />
          </div>
        </div>
        <div className={styles.arabicVersion}>
          <div className={styles.ckEditor}>
            <p className={styles.editorTitle}>Establishments (Arabic)</p>
            <CKEditor
              id="establishment_ar"
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "insertTable",
                  "|",
                  "|",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              data={editorCkData.establishment_ar}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleTextEditorChange("establishment_ar", data);
              }}
            />
          </div>
          <div className={styles.ckEditor}>
            <p className={styles.editorTitle}>Objectives (Arabic)</p>
            <CKEditor
              id="objectives_ar"
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "insertTable",
                  "|",
                  "|",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              data={editorCkData.objectives_ar}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleTextEditorChange("objectives_ar", data);
              }}
            />
          </div>
          <div className={styles.ckEditor}>
            <p className={styles.editorTitle}>Vision (Arabic)</p>
            <CKEditor
              id="vision_ar"
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "insertTable",
                  "|",
                  "|",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              data={editorCkData.vision_ar}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleTextEditorChange("vision_ar", data);
              }}
            />
          </div>
          <div className={styles.ckEditor}>
            <p className={styles.editorTitle}>Mission (Arabic)</p>
            <CKEditor
              id="mission_ar"
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "insertTable",
                  "|",
                  "|",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              data={editorCkData.mission_ar}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleTextEditorChange("mission_ar", data);
              }}
            />
          </div>
          <div className={styles.ckEditor}>
            <p className={styles.editorTitle}>Structure (Arabic)</p>
            <CKEditor
              id="structure_ar"
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "insertTable",
                  "|",
                  "|",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              data={editorCkData.structure_ar}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleTextEditorChange("structure_ar", data);
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.editorBtn}>
        <AdminButton
          disabled={IsTextEditorValid}
          onClick={!isEdit ? handleCkCreateClick : hanldeEditClick}
        >
          {!isEdit ? "Create" : "Update"}
        </AdminButton>
      </div>
    </div>
  );
};

export default AdminAboutUs;
