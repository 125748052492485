export enum MediaType {
    EVENT = "event",
    NEWS = "news"
}

export enum OrderType {
    ASC = "ASC",
    DESC = "DESC",
}

export enum UserRoleEnums {
    ADMIN = "Admin",
    MEMBER = "Member"
}