import { format } from "date-fns";
import { TDateProps } from "../@types/type";
import { t } from "i18next";

export const fDateTime = ({ date, newFormat }: TDateProps) => {
  const fm = newFormat || "dd MMM yyyy";

  return date ? format(new Date(date), fm) : "";
};

export const fHoursTime = ({ date, newFormat }: TDateProps) => {
  const fm = newFormat || "h:mm aa";

  return date ? format(date, fm) : "";
};

export const fDayTime = (day: string) => {
  const max = 31;
  day = day.replace(/[^\d]/g, "");

  if (day.charAt(0) !== "0" || day === "00") {
    let num = parseInt(day);
    if (isNaN(num) || num <= 0 || num > max) num = 1;
    day =
      num > parseInt(max.toString().charAt(0)) && num.toString().length === 1
        ? "0" + num
        : num.toString();
  }

  const limitedDayNumber = day.slice(0, 2);

  return limitedDayNumber;
};

export const fYearTime = (year: string) => {
  const currentYear = new Date().getFullYear();
  return (Number(year) > currentYear ? currentYear : year).toString();
};

export const fConvertToDateString = (date: string | Date) => {
  const dateObject = typeof date === "string" ? new Date(date) : date;
  const formattedDate = format(dateObject, "yyyy-MM-dd");
  return formattedDate;
};

export const emiratesIdFormat = (idNumber: string, code?: string) => {
  const digitsOnly = idNumber.replace(/\D/g, "");
  let formattedValue = "";

  for (let i = 0; i < digitsOnly.length; i++) {
    formattedValue += digitsOnly[i];
    if (code !== "Backspace") {
      if (i === 2 || i === 6 || i === 13) {
        formattedValue += "-";
      }
    } else {
      if (i === 2 || i === 6 || i === 13) {
        if (digitsOnly.length - 1 !== i) {
          formattedValue += "-";
        } else {
          formattedValue += "";
        }
      }
    }
  }

  return formattedValue;
};

export const truncateFormt = (text: string) => {
  if (text.length > 20) {
    return `${text.substring(0, 10)}...${text.substring(text.length - 10)}`;
  }

  return text;
};

export const capitalizeFirstLetter = (title: string) => {
  return title.charAt(0).toUpperCase() + title.slice(1);
};

export const DateFormatToOneString = (
  dateValue: { day: string; year: string },
  selectedMonth: { value: string }
) => {
  const day = dateValue?.day || "";
  const year = dateValue?.year || "";
  const month = selectedMonth?.value || "";

  const formattedDay = day.length === 1 ? `0${day}` : day;
  const date = day && year && month ? `${month}/${formattedDay}/${year}` : "";
  return date;
};

const truncateString = (
  str: string,
  firstCharCount = str.length,
  endCharCount = 0,
  dotCount = 3
) => {
  if (str.length <= firstCharCount + endCharCount) {
    return str;
  }

  const firstPortion = str.slice(0, firstCharCount);
  const endPortion = str.slice(-endCharCount);
  const dots = ".".repeat(dotCount);

  return `${firstPortion}${dots}${endPortion}`;
};

export const attachmentsFilesFormat = (filePath: string) => {
  if (filePath && typeof filePath === "string") {
    const parts = filePath.split(".");
    const nameParts = filePath.split("/");
    const fileNameWithExtension = nameParts[nameParts.length - 1];
    const fileName = fileNameWithExtension.replace(/^\d+-/, "");
    const fileType = parts[parts.length - 1].toLowerCase();

    if (fileName.length > 20) {
      const subFileName = truncateString(fileName, 5, 8, 3);
      return { fileName: subFileName, fileType };
    }

    return { fileName, fileType };
  }
};

export const getTextFormat = (text: string) => {
  const nameParts = text.split("/");
  const textFirstWithExtension = nameParts[0];
  const textNameWithExtension = nameParts[nameParts.length - 1];

  return {
    url: textFirstWithExtension,
    type: textNameWithExtension,
  };
};

export const getDate = (date: string) => {
  const year = String(new Date(date).getFullYear());
  const day = String(new Date(date).getDate());
  const month = new Date(date).getMonth() + 1;
  const monthTranslations: string[] = t("date.months", { returnObjects: true });
  const months = monthTranslations.map((month, index) => {
    const value = (index + 1 < 10 ? "0" : "") + (index + 1).toString();
    return { value, label: month };
  });

  const monthFormat = +month < 10 ? `0${month}` : month;
  const findMonth = months.find((item: any) => item.value === monthFormat);

  return {
    year,
    day: +day < 10 ? `0${day}` : day,
    month: {
      value: findMonth?.value,
      label: findMonth?.label,
    },
  };
};

export const checkDate = (date: Date) => {
  const today = new Date();

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const isYearValid = year >= today.getFullYear();
  const isMonthValid = month >= today.getMonth() + 1;
  const isDayValid = day > today.getDate();

  const isValid = isYearValid && isMonthValid && isDayValid;

  return isValid;
};
