import React, { MouseEventHandler } from "react";
import styles from "./AdminButton.module.scss";

type TButtonProps = {
  variant?: "contained" | "outlined";
  color?: "primary" | "dark" | "light" | "success" | "error";
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
};

const AdminButton: React.FC<
  TButtonProps & React.HTMLAttributes<HTMLButtonElement>
> = ({
  variant = "contained",
  color = "primary",
  children,
  onClick,
  disabled,
  ...rest
}) => {
  return (
    <div className={styles.adminBtn}>
      <button
        className={`${styles[variant]} ${styles[color]}`}
        onClick={onClick}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    </div>
  );
};

export default AdminButton;
