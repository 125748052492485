import server from "../../../../app/server";
import { Partner } from "../../../../@types/partner";
import { List, ListParams } from "../../../../@types/table";

export const GetPartnerList = async (
  params: ListParams
): Promise<List<Partner>> => {
  return await server
    .get("partner/list", { params: params })
    .then((res) => res?.data?.data)
    .catch((error) => console.log(error));
};

export const CreatePartner = async (formData: Partner): Promise<Partner> => {
  return await server
    .post("partner/create", formData)
    .then((res) => res?.data?.data)
    .catch((error) => console.log(error));
};

export const EditPartner = async (
  formData: Partner,
  id: number
): Promise<Partner> => {
  return await server
    .patch(`partner/update/${id}`, formData)
    .then((res) => res?.data?.data)
    .catch((error) => console.log(error));
};

export const DeletePartner = async (id: number): Promise<any> => {
  return await server
    .delete(`partner/delete/${id}`)
    .then((res) => res?.data?.data)
    .catch((error) => console.log(error));
};
