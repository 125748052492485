import { ActionReducerMapBuilder, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { partnerList, slideList } from "../api";
import { TPartnerProps, TSlideProps } from "../../@types/type";

type TSlideState = {
    slideData: TSlideProps[];
    partnerData: TPartnerProps[];
    isError: boolean;
    isLoading: boolean;
}

const initialState: TSlideState = {
    slideData: [],
    partnerData: [],
    isError: false,
    isLoading: false,
};

const slideSlice = createSlice({
    name: 'slide_list',
    initialState,
    reducers: {
    },
    extraReducers: (builder: ActionReducerMapBuilder<any>) => {
        // slide
        builder.addCase(slideList.pending, (state: TSlideState) => {
            state.isLoading = true;
        });
        builder.addCase(slideList.fulfilled, (state: TSlideState, action: PayloadAction<any>) => {
            state.isLoading = false;
            if (action.payload?.status === 200) {
                state.slideData = action.payload.data.data?.data;
                state.isError = false;
            } else {
                state.isError = true;
            }
        });
        builder.addCase(slideList.rejected, (state: TSlideState) => {
            state.isLoading = false;
            state.isError = true;
        });
        // partner
        builder.addCase(partnerList.pending, (state: TSlideState) => {
            state.isLoading = true;
        });
        builder.addCase(partnerList.fulfilled, (state: TSlideState, action: PayloadAction<any>) => {
            state.isLoading = false;
            if (action.payload?.status === 200) {
                state.partnerData = action.payload.data.data?.data;
                state.isError = false;
            } else {
                state.isError = true;
            }
        });
        builder.addCase(partnerList.rejected, (state: TSlideState) => {
            state.isLoading = false;
            state.isError = true;
        });
    }
});

export default slideSlice.reducer;