import React from "react";
import { TIconProps } from "../../../@types/icon";

const EventsNewsIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
    >
      <g clipPath="url(#clip0_300_19)">
        <path
          d="M89.8334 143.667L60.0556 113.889C59.1455 112.826 58.6699 111.459 58.7239 110.061C58.7779 108.663 59.3575 107.336 60.3469 106.347C61.3363 105.357 62.6627 104.778 64.0609 104.724C65.4591 104.67 66.8261 105.145 67.8889 106.056L89.8334 127.778L137.833 79.7778C138.896 78.8676 140.263 78.392 141.661 78.446C143.06 78.5 144.386 79.0797 145.375 80.0691C146.365 81.0585 146.944 82.3848 146.998 83.783C147.052 85.1812 146.577 86.5483 145.667 87.6111L89.8334 143.667Z"
          fill={fill}
        />
        <path
          d="M179.167 33.3334H161.111V44.4445H177.778V166.667H22.2222V44.4445H38.8888V33.3334H20.8333C19.5346 33.3551 18.2529 33.6324 17.0614 34.1495C15.8699 34.6666 14.792 35.4134 13.8892 36.3472C12.9863 37.281 12.2763 38.3835 11.7996 39.5917C11.323 40.7999 11.089 42.0903 11.111 43.3889V167.722C11.089 169.021 11.323 170.311 11.7996 171.519C12.2763 172.728 12.9863 173.83 13.8892 174.764C14.792 175.698 15.8699 176.445 17.0614 176.962C18.2529 177.479 19.5346 177.756 20.8333 177.778H179.167C180.465 177.756 181.747 177.479 182.938 176.962C184.13 176.445 185.208 175.698 186.111 174.764C187.014 173.83 187.724 172.728 188.2 171.519C188.677 170.311 188.911 169.021 188.889 167.722V43.3889C188.911 42.0903 188.677 40.7999 188.2 39.5917C187.724 38.3835 187.014 37.281 186.111 36.3472C185.208 35.4134 184.13 34.6666 182.938 34.1495C181.747 33.6324 180.465 33.3551 179.167 33.3334Z"
          fill={fill}
        />
        <path
          d="M55.5556 55.5555C57.029 55.5555 58.4421 54.9702 59.4839 53.9283C60.5258 52.8865 61.1111 51.4734 61.1111 50V16.6666C61.1111 15.1932 60.5258 13.7801 59.4839 12.7383C58.4421 11.6964 57.029 11.1111 55.5556 11.1111C54.0821 11.1111 52.6691 11.6964 51.6272 12.7383C50.5853 13.7801 50 15.1932 50 16.6666V50C50 51.4734 50.5853 52.8865 51.6272 53.9283C52.6691 54.9702 54.0821 55.5555 55.5556 55.5555Z"
          fill={fill}
        />
        <path
          d="M144.444 55.5555C145.918 55.5555 147.331 54.9702 148.373 53.9283C149.415 52.8865 150 51.4734 150 50V16.6666C150 15.1932 149.415 13.7801 148.373 12.7383C147.331 11.6964 145.918 11.1111 144.444 11.1111C142.971 11.1111 141.558 11.6964 140.516 12.7383C139.474 13.7801 138.889 15.1932 138.889 16.6666V50C138.889 51.4734 139.474 52.8865 140.516 53.9283C141.558 54.9702 142.971 55.5555 144.444 55.5555Z"
          fill={fill}
        />
        <path
          d="M72.2222 33.3334H127.778V44.4445H72.2222V33.3334Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_300_19">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EventsNewsIcon;
