export const validateFormObjetc = (formObj: any): string[] => {
  const validationArr: string[] = [];
  for (let key in formObj) {
    if (formObj.hasOwnProperty(key)) {
      const value = formObj[key];
      if (value === "" || value === null || value === undefined) {
        validationArr.push(key);
      }
    }
  }
  return validationArr;
};
