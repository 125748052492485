import React, { Fragment, useEffect, useRef, useState } from "react";
import styles from "./Accordion.module.scss";
import ChevronIcon from "../icons/ChevronIcon";
import { useTranslation } from "react-i18next";

type TAnswerProps = {
  title: string;
  icon?: React.ReactNode;
  desc: string;
};

type TAccordionProps = {
  question: string;
  answer: string | string[] | number | TAnswerProps[];
  split?: boolean;
  isOpen: boolean;
  onClick: () => void;
};

const Accordion: React.FC<TAccordionProps> = ({
  question,
  answer,
  split,
  isOpen,
  onClick,
}) => {
  const { i18n } = useTranslation();
  const contentHeight = useRef<HTMLDivElement>(null);
  const [splitAnswer, setSplitAnswer] = useState<
    string | string[] | number | TAnswerProps[]
  >("");

  useEffect(() => {
    if (split && typeof splitAnswer === "string") {
      setSplitAnswer(splitAnswer.split(". "));
    }
  }, [split, splitAnswer]);

  useEffect(() => {
    setSplitAnswer(answer);
  }, [i18n, answer]);

  return (
    <div className={styles.accordion}>
      <button
        className={`${styles.questionContainer} ${isOpen ? styles.active : ""}`}
        onClick={onClick}
      >
        <p className={styles.questionContent}>{question}</p>
        <div className={`${styles.arrow} ${isOpen ? styles.rotate : ""}`}>
          <ChevronIcon width="18px" height="18px" fill="" />
        </div>
      </button>

      <div
        ref={contentHeight}
        className={styles.answerContainer}
        style={
          isOpen
            ? { height: contentHeight.current?.scrollHeight + "px" }
            : { height: "0px" }
        }
      >
        <Fragment>
          {split && Array.isArray(splitAnswer) ? (
            <div className={styles.answerContent}>
              <p
                className={styles.splitAnswer}
                dangerouslySetInnerHTML={{
                  __html:
                    splitAnswer[0] && typeof splitAnswer[0] === "string"
                      ? splitAnswer[0] + "."
                      : "",
                }}
              />
              <p
                className={styles.splitAnswer}
                dangerouslySetInnerHTML={{
                  __html:
                    splitAnswer[1] && typeof splitAnswer[1] === "string"
                      ? splitAnswer[1]
                      : "",
                }}
              />
            </div>
          ) : Array.isArray(splitAnswer) ? (
            <div className={styles.answerContent}>
              {splitAnswer.map((item, index) => (
                <div key={index} className={styles.arrayData}>
                  {typeof item === "string" ? (
                    <p
                      className={styles.answerContent}
                      dangerouslySetInnerHTML={{
                        __html: item,
                      }}
                    />
                  ) : (
                    <div className={styles.arrayDataItem}>
                      <div className={styles.icon}>{item.icon}</div>
                      <div className={styles.itemVision}>
                        <p>{item.title}</p>
                        <div
                          className={styles.answerContent}
                          dangerouslySetInnerHTML={{
                            __html: item.desc,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p className={styles.answerContent}>{splitAnswer}</p>
          )}
        </Fragment>
      </div>
    </div>
  );
};

export default Accordion;
