import React from "react";
import "./global.scss";
import AppLayout from "./Layout/AppLayout";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";

const App: React.FC = () => {
  const { i18n } = useTranslation();
  const languageContentClass =
    i18n.language === "ar" ? "arabicContent" : "englishContent";

  return (
    <div className={`App ${languageContentClass}`}>
      <AppLayout />
      <ToastContainer />
    </div>
  );
};

export default App;
