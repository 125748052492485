import React, { MouseEventHandler } from "react";
import styles from "./Button.module.scss";
import { useTranslation } from "react-i18next";

type TButtonProps = {
  variant?: "contained" | "outlined";
  color?:
    | "primary"
    | "dark"
    | "light"
    | "success"
    | "error"
    | "warning"
    | "info";
  size?: "small" | "medium" | "large";
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
};

const Button: React.FC<
  TButtonProps & React.HTMLAttributes<HTMLButtonElement>
> = ({
  variant = "contained",
  color = "primary",
  size = "medium",
  children,
  onClick,
  disabled,
  className,
  ...rest
}) => {
  const { i18n } = useTranslation();
  const defaultClass =
    i18n.language === "ar" ? "arabicContent" : "englishContent";

  return (
    <button
      className={`${styles[variant]} ${styles[color]} ${styles[size]} ${className} ${defaultClass}`}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;