import React from 'react';
import { TIconProps } from '../../../@types/icon';

const Uploadicon = ({ fill, width, height }: TIconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 200 200"
            fill="none"
        >
            <path
                d="M56.25 137.5C-1.8296e-05 143.75 6.24998 74.9999 56.25 81.2499C37.5 12.4999 143.75 12.4999 137.5 62.4999C200 43.7499 200 143.75 143.75 137.5M68.75 112.5L100 87.4999M100 87.4999L131.25 112.5M100 87.4999V181.25"
                stroke={fill}
                strokeWidth="12.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default Uploadicon;
