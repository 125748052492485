import { ChangeEvent, useCallback, useEffect, useState } from "react";
import styles from "./Newsletter.module.scss";
import TableBody from "./TableBody/TableBody";
import Loading from "../../../components/Loading/Loading";
import Modal from "../../../components/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import ModalBody from "./ModalBody/ModalBody";
import {
  createNewsletter,
  newsLetterList,
  updateNewsletterById,
} from "../../../app/api";
import { toast } from "react-toastify";
import { updateNewsletterFetchAgain } from "../../../app/features/newsletterSlice";
import ReactPaginate from "react-paginate";
import ArrowRightIcon from "../../../components/icons/ArrowRightIcon";
import ArrowLeftIcon from "../../../components/icons/ArrowLeftIcon";

type TNewsletterFormDataProps = {
  title: string;
  body: string;
  shipDate: Date | null;
};

const headerColumns = ["ID", "Title", "Ship Date/Time", "Actions"];

const Newsletter = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const dispatch: AppDispatch = useDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [take, setTake] = useState<number>(10);
  const [isActiveId, setIsActiveId] = useState<number | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [shipDate, setShipDate] = useState<Date>(tomorrow);
  const [newsletterForm, setNewsletterForm] = useState({
    title: "",
    body: "",
  });
  const { newslettersList, isLoading, fetchAgain, meta } = useSelector(
    (state: RootState) => state.newsletter
  );

  const { pageCount }: any = meta ?? {};

  const handlePageClick = (newPage: any) => {
    setPage(newPage.selected + 1);
    dispatch(updateNewsletterFetchAgain());
  };

  const handleShowModal = () => {
    setShowModal(true);
    setIsEdit(false);
    setNewsletterForm({
      title: "",
      body: "",
    });
    setShipDate(tomorrow);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setNewsletterForm({
      title: "",
      body: "",
    });
    setShipDate(tomorrow);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setNewsletterForm({ ...newsletterForm, [id]: value });
  };

  const handleCKEditorChange = (id: string, data: string) => {
    setNewsletterForm({ ...newsletterForm, [id]: data });
  };

  const handleCreateClick = async () => {
    const data: TNewsletterFormDataProps = {
      title: newsletterForm.title,
      shipDate,
      body: newsletterForm.body,
    };

    await dispatch(createNewsletter(data)).then((res: any) => {
      if (res.payload.status === 201) {
        setShowModal(false);
        setNewsletterForm({
          title: "",
          body: "",
        });
        toast.success(res.payload.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleEditRowClick = (row: any) => {
    setIsEdit(true);
    setShowModal(true);
    setIsActiveId(row.id);
    setNewsletterForm({
      title: row.title,
      body: row.body,
    });
    setShipDate(new Date(row.shipDate));
  };

  const handleEditClick = async () => {
    const query = {
      id: isActiveId,
      data: {
        ...newsletterForm,
        shipDate,
      },
    };

    await dispatch(updateNewsletterById(query)).then((res: any) => {
      if (res.payload.status === 200) {
        setShowModal(false);
        toast.success(res.payload.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const fetchNewsletterData = useCallback(async () => {
    try {
      await dispatch(newsLetterList({ page, take })).then((res: any) => {
        if (res.payload.status === 200) {
          setIsFetching(true);
        }
      });
    } catch (error: unknown) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (fetchAgain) {
      fetchNewsletterData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAgain]);

  useEffect(() => {
    if (newslettersList.length) {
      setIsFetching(true);
    }
  }, [newslettersList]);

  return (
    <div className={`container ${styles.newsletter}`}>
      <h2>Newsletter</h2>
      <TableBody
        headerColumns={headerColumns}
        handleShowModal={handleShowModal}
        handleEditRowClick={handleEditRowClick}
        rowsData={newslettersList}
      />
      {!isFetching ? (
        <Loading />
      ) : (
        <Modal
          isOpen={showModal}
          btnText={isEdit ? "Edit" : "Create"}
          onClose={handleCloseModal}
          onClick={!isEdit ? handleCreateClick : handleEditClick}
          disabled={isLoading}
          type="admin"
        >
          <ModalBody
            setShipDate={setShipDate}
            shipDate={shipDate}
            tomorrow={tomorrow}
            newsletterForm={newsletterForm}
            handleInputChange={handleInputChange}
            handleCKEditorChange={handleCKEditorChange}
          />
        </Modal>
      )}
      {meta ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <ArrowRightIcon fill="#2065D1" width="30px" height="30px" />
          }
          onPageChange={(page) => handlePageClick(page)}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={
            <ArrowLeftIcon fill="#2065D1" width="30px" height="30px" />
          }
          renderOnZeroPageCount={null}
          className="pagination"
          previousClassName="prev"
        />
      ) : null}
    </div>
  );
};

export default Newsletter;
