import React, { useState } from 'react';
import styles from './Dropdown.module.scss';
import ChevronIcon from '../icons/ChevronIcon';
import { useOutsideClick } from '../../hooks/useOutsideClick';

type Option = {
    value: string;
    label: string;
};

type TDropdownProps = {
    title?: string;
    selected?: boolean
    options: Option[];
    search?: boolean,
    inputPlaceholder?: string;
    defaultValue?: string;
    selectedValue: string;
    onValueChange: (value: Option) => void;
};

const Dropdown = ({
    title,
    options,
    search,
    inputPlaceholder,
    defaultValue,
    selectedValue,
    onValueChange
}: TDropdownProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [searchvalue, setSearchvalue] = useState<string>('')

    const ref = useOutsideClick(() => {
        setIsOpen(false)
    });

    const handleSelect = (option: Option) => {
        onValueChange(option);
        setIsOpen(false);
        setSearchvalue('');
    };

    const hadleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchvalue(e.target.value)
    }

    const filteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(searchvalue.toLowerCase())
    );


    return (
        <div className={styles.dropdownContainer}>
            <div className={styles.dropdownTitle}>{title}</div>
            <div className={styles.dropdownBody} ref={ref}>
                <div
                    className={`${styles.dropdownHeader} ${!isOpen ? styles.dropdownClose : styles.dropdownOpen}`}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <span className={!selectedValue ? styles.default : ''}>{!selectedValue ? defaultValue : selectedValue}</span>
                    <ChevronIcon width='18px' height='18px' fill='' />
                </div>
                {isOpen && (
                    <div className={`${styles.dropdownOptions} ${!isOpen ? styles.dropdownOptionsClose : styles.dropdownOptionsOpen}`}>
                        {search ?
                            <input
                                className={styles.inputField}
                                placeholder={inputPlaceholder}
                                value={searchvalue}
                                onChange={hadleSearchChange}
                            /> : null}
                        {filteredOptions.map((option) => (
                            <div
                                key={option.value}
                                className={`${styles.dropdownOption} ${option.label === selectedValue ? styles.active : ''}`}
                                onClick={() => handleSelect(option)}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Dropdown;
